import _ from 'lodash';
// import axios from 'axios';

export const initialState = {
    initializedState: {},

    open: false,
    ready: false,
    uiBlockerText: null,
    unsaved: false,
    saving: false,
    applying: false,
    currentSocialId: null,
    currentRow: null,
    currentRowActions: null,
    currentUserDetails: null,
    disablePrevBtn: false,
    disableNextBtn: false,
    activeTab: 0,
    networksActiveTab: 0, // DEPRECATED
    instagramActiveTab: 0, // DEPRECATED
    tiktokActiveTab: 0, // DEPRECATED
    isRecursiveFetching: false,
    recursiveFetchTimeoutID: null,
    loadingRecentInstagramPosts: false,
    loadingRecentTiktokPosts: false,
    // axiosCancelTokenSource: axios.CancelToken.source(),
};

export const reducer = (state, { type, payload = null }) => {
    switch(type) {
        case 'HYDRATE':
            return {
                ...state,
                ...payload,
            };

        // case 'SET_OPEN':
        //   return { ...state, open: payload };

        // case 'SET_UNSAVED':
        //   return { ...state, unsaved: payload };

        // case 'SET_SAVING':
        //   return { ...state, saving: payload };

        // case 'SET_APPLYING':
        //   return { ...state, applying: payload };

        default:
            throw new Error();
    }
}
