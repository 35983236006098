import React from 'react';
import ReactModal from 'react-modal';
import UIBlocker from '../UIBlocker';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

ReactModal.defaultStyles.content.border = 'none';
ReactModal.defaultStyles.content.borderRadius = 0;
ReactModal.defaultStyles.content.padding = 0;
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';

ReactModal.setAppElement('body');

const defaultButtonProps = {
  style: 'black',
  text: '',
  onClick: (e) => {},
  disabled: false,
  triggerOnEsc: false,
  triggerOnEnter: false
};

function Modal({ contentLabel = null, buttons, ...props }) {
  contentLabel = props.contentLabel || props.title;

  return (
    <ReactModal
      contentLabel={contentLabel}
      {...props}
      style={customStyles}
    >
      <div className="modal ui-block-container">
        <div className="modal-header">

          <div className="modal-heading">
            <h2 className="raleway-semi-bold raleway-12 modal-heading">
              {props.title}
            </h2>
          </div>

          <div className="buttons">
            <button
              className="exit-btn"
              onClick={e => {
                e.preventDefault();
                props.onRequestClose();
              }}
            >
              x
            </button>
          </div>
        </div>
        <div className="modal-content">
          {props.children}
        </div>
        <div className="modal-bottom">
          <div className="bottom-buttons">
            <div>
              {buttons.map(b => {
                const button = { ...defaultButtonProps, ...b };

                return (
                  <button
                    key={button.text}
                    className={`btn btn-${button.style}`}
                    onClick={e => {
                      e.preventDefault();
                      button.onClick(e);
                    }}
                    disabled={button.disabled}
                  >
                    {button.text}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
        <UIBlocker block={props.blockUI} />
      </div>
    </ReactModal>
  );
}

Modal.defaultProps = {
  title: "",
  blockUI: false,
  buttons: [],
  onRequestClose: () => {}
};

export default Modal;
