import React, { useEffect, useRef, useCallback, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { isDev, getJSONData } from '../../utils';
import { makeImage } from './utils';
import { initialState, reducer } from './reducer';
import fakeJSONData from './fake-json-data';
import { saveMediaItem, saveMediaItemS3, saveImages } from './ajax';
import { FilePond, registerPlugin as registerFilepondPlugin } from 'react-filepond';
import FilepondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
// import Gallery from "react-photo-gallery";
import Gallery from "./Gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import arrayMove from "array-move";
import { SortableContainer } from "react-sortable-hoc";
import UIBlocker from '../UIBlocker';
import Instructions from './Instructions';
// import ImageEditor, { ImageEditorUI } from './ImageEditor';
import * as Doka from '../../vendor/doka/bin/react/esm/lib/doka.esm.min';
import '../../vendor/doka/bin/react/esm/lib/doka.min.css';
import imgIcon from '../../images/img-icon.png';
import { excludeInvalidImages } from './reducer';
// import AWS from 'aws-sdk';
import $ from 'jquery';
import uuid from 'uuid/v4';
import _ from 'lodash';

registerFilepondPlugin(FilepondPluginFileValidateType);
registerFilepondPlugin(FilePondPluginImagePreview);
registerFilepondPlugin(FilePondPluginImageValidateSize);
registerFilepondPlugin(FilePondPluginImageTransform);
registerFilepondPlugin(FilePondPluginImageResize);
registerFilepondPlugin(FilePondPluginImageEdit);

const defaultJsonData = {
  images: [],
  upload_url: null,
  ajax_save: true,
  presign_url: null,
  save_media_url: null,
  save_images_url: null,
  allow_multiple: true,
  min_width: 1,
  min_height: 1,
  max_width: 65535,
  max_height: 65535,
  max_images: null,
  instructions: null,
  input_name: 'images',
  image_edit_enabled: true,
  // TODO Get this to work with Doka
  supported_aspect_ratios: ['16:9', '4:3', '1:1'],
};

// const SortablePhoto = SortableElement(item => <Image {...item} />);
const SortableGallery = SortableContainer(({ items, onClick, compact }) => (
  <Gallery
    images={items}
    onClick={onClick}
    compact={compact}
  />
));
function ImagesUploader(props) {
  const { mountPoint, config, compact, images, onImageAdded, onImageDeleted, ...filepondRest } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const filepond = useRef(null);
  // NOTE: We may have multiple image uploaders on one screen, so
  //       we need a ref for the component instance
  const currentSaveRequest = useRef(null);
  const jsonData = useRef(defaultJsonData);
  const imageEditor = useRef(null);

  useEffect(() => {
    initialize();
    if (jsonData.current.image_edit_enabled) {
      // imageEditor.current = ImageEditor.create();
      imageEditor.current = Doka.create({
        // Doka.js options here ...
        cropAspectRatioOptions: [
          {
              label: 'Square',
              value: 1
          },
          // {
          //     label: 'Portrait',
          //     value: 1.25
          // },
          // {
          //     label: 'Landscape',
          //     value: .75
          // },
          {
              label: 'Free',
              value: null
          },
        ],
        // Facilitate auto-upload after edit
        onconfirm: () => {
          if (!!filepond.current) {
            setTimeout(() => {
              filepond.current.processFiles();
            }, 300);
          }
        },
        // Apprently this already happens automatically if you hit cancel in the editor
        // oncancel: () => {
        //   if (!!filepond.current) {
        //     setTimeout(() => {
        //       // filepond.current.removeFiles();
        //     }, 300);
        //   }
        // },
      });
    }
  }, []);

  useEffect(() => {
    if (state.imagesChangeTimestamp === null || !jsonData.current.ajax_save || !jsonData.current.save_images_url) return;

    saveImages(jsonData.current.save_images_url, state.images, dispatch, currentSaveRequest);
  }, [state.imagesChangeTimestamp]);

  const initialize = () => {
    jsonData.current = { ...defaultJsonData, ...getJSONData(props.mountPoint, isDev() ? fakeJSONData : config) };

    const _images = images || jsonData.current.images || [];

    dispatch({
      type: 'HYDRATE',
      payload: {
        ready: true,
        images: _images.map(image => makeImage(image)),
      }
    });
  }

  const openLightbox = useCallback((index) => {
    dispatch({
      type: 'HYDRATE',
      payload: {
        currentImage: index,
        viewerIsOpen: true
      }
    });
  }, []);

  const closeLightbox = () => {
    dispatch({
      type: 'HYDRATE',
      payload: {
        currentImage: 0,
        viewerIsOpen: false
      }
    });
  };

  const saveAndAddImage = async (file) => {
    const meta = file.getMetadata();
    // const savedImage = await saveMediaItem(jsonData.current.save_media_url, file.serverId, dispatch);
    const savedImage = await saveMediaItemS3(jsonData.current.s3_image_exchange_url, meta.image_media_id, meta.filename, dispatch);

    if (savedImage) {
      onImageAdded(savedImage);
    }

    filepond.current.removeFile(file);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const reorderedImages = arrayMove(state.images, oldIndex, newIndex)
      .map((image, i) => ({
        ...image,
        image: {
          ...image.image,
          sort_order: i
        }
      }));

    dispatch({
      type: 'REORDER_IMAGES',
      payload: {
        images: reorderedImages
      }
    });
  };

  const getSelected = () => (
    state.images.filter(image => {
      return image.image.selected;
    })
  );

  const allSelected = () => (
    getSelected().length === state.images.length
  );

  const anySelected = () => (
    getSelected().length > 0
  );

  const deleteSelected = () => {
    if (!window.confirm("Are you sure you want to delete these images?")) return;

    dispatch({
      type: 'REMOVE_SELECTED'
    });
  };

  const handleImageClick = ({ image, isDelete, isView }, { index }) => {
    if (isDelete) {
      onImageDeleted(image);

      dispatch({
        type: 'REMOVE_IMAGES',
        payload: {
          imageMediaIds: [image.image_media_id]
        }
      });
    } else if (isView) {
      openLightbox(index);
    } else {
      dispatch({
        type: 'TOGGLE_SELECTED',
        payload: {
          imageMediaId: image.image_media_id
        }
      });
    }
  };

  const toggleSelectAll = () => {
    dispatch({
      type: 'TOGGLE_ALL_SELECTED'
    });
  };

  const maxImagesReached = () => {
    return !!jsonData.current.max_images && state.images.length >= jsonData.current.max_images;
  };

  const filepondMaxFiles = () => {
    if (compact) return 1;

    return !!jsonData.current.max_images ?
      (jsonData.current.max_images - state.images.length) :
      null;
  };

  const filepondIdleLabel = () => {
    if (compact) {
      return `<img src="${imgIcon}" alt="Drag & Drop your files or Browse" />`;
    }

    return 'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>';
  };

  if (!state.ready) return null;

  const component = (
    <div className={`ImagesUploader ${compact ? 'compact':''} ${!!state.images.length ? 'has-images':''} ${!!state.files.length ? 'has-files':''}`}>
      {!compact && state.images.length > 0 && (
        <div className="buttons">
          <button
            className="btn btn-white"
            onClick={e => {
              e.preventDefault();
              toggleSelectAll(e);
            }}
          >
            {allSelected() ? 'Deselect All' : 'Select All'}
          </button>
          {anySelected() && (
            <button
              className="btn btn-green"
              onClick={e => {
                e.preventDefault();
                deleteSelected(e);
              }}
            >
              Delete
            </button>
          )}
        </div>
      )}

      <div className="images" key="images">
        {!!state.images.length && (
          <>
            <SortableGallery
              items={state.images}
              onSortEnd={onSortEnd}
              distance={3}
              onClick={handleImageClick}
              axis={"xy"}
              compact={compact}
            />
            <ModalGateway>
              {state.viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={state.currentImage}
                    views={state.images.map(x => ({
                      ...x,
                      src: x.image.sizes.original.url,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </>
        )}
      </div>

      <div className="inputs" key="inputs">
        {excludeInvalidImages(state.images).map((image, i) => {
          return [
            <input
              key={`${image.image.image_media_id}-sort_order`}
              type="hidden"
              name={`${jsonData.current.input_name}[${i}][sort_order]`}
              value={image.image.sort_order || 0}
            />,
            <input
              key={`${image.image.image_media_id}-image_media_id`}
              type="hidden"
              name={`${jsonData.current.input_name}[${i}][image_media_id]`}
              value={image.image.image_media_id}
            />,
            <input
              key={`${image.image.image_media_id}-caption`}
              type="hidden"
              name={`${jsonData.current.input_name}[${i}][caption]`}
              value={image.image.caption || ''}
            />
          ];
        })}
      </div>

      {/* HACK: The laravel-filepond lib we're using has an incorrect,
                non-configurable file input name in controller:
                vendor/sopamo/laravel-filepond/src/Http/Controllers/FilepondController.php

                It expects an array input named "file", filepond default
                is "filepond" non-array input
      */}
      <div className="uploader ui-blocker-container">
        <FilePond
          key="uploader"
          acceptedFileTypes={['image/jpeg','image/png']}
          allowMultiple={!compact}
          imageValidateSizeMinWidth={jsonData.current.min_width}
          imageValidateSizeMaxWidth={jsonData.current.max_width}
          imageValidateSizeMinHeight={jsonData.current.min_height}
          imageValidateSizeMaxHeight={jsonData.current.max_height}
          allowImageResize
          imageResizeTargetWidth={1080}
          imageResizeTargetHeight={1080}
          imageResizeUpscale={false}
          imageResizeMode='contain'
          imageTransformVariants={{
            // 'resized_': transforms => {
            //   console.log('transforms', transforms)
            //   transforms.resize.size.width = 1920;
            //   return transforms;
            // },
          }}
          allowImageEdit={jsonData.current.image_edit_enabled}
          imageEditInstantEdit={compact}
          imageEditAllowEdit={jsonData.current.image_edit_enabled}
          imageEditEditor={imageEditor.current}
          imageCropAspectRatio={1}
          instantUpload={!jsonData.current.image_edit_enabled}
          server={{
            // process: jsonData.current.upload_url,
            process: async (fieldName, file, metadata, load, error, progress, abort) => {
              const imageMediaId = metadata.image_media_id;
              // NOTE: The extension can vary from jpeg to jpg between
              //       onAddFile and server when image transformation plugins are being used
              const filename = metadata.filename;

              try {
                const presignedUrl = await $.ajax({
                    url: jsonData.current.presign_url,
                    type: 'POST',
                    data: {
                      filename: filename,
                      content_type: `${file.type}`,
                      image_media_id: imageMediaId,
                    }
                });

                const uploadPromise = $.ajax({
                  url: presignedUrl.data.url,
                  type: 'PUT',
                  data: file,
                  processData: false,
                  contentType: false,
                  headers: {'Content-Type': 'multipart/form-data'},
                  xhr: () => {
                      // get the native XmlHttpRequest object
                      var xhr = $.ajaxSettings.xhr() ;
                      // set the onprogress event handler
                      xhr.upload.onprogress = (e) => {
                          progress(e.lengthComputable, e.loaded, e.total);
                      };
                      // set the onload event handler
                      xhr.upload.onload = (e) => {
                        load(file);
                      }

                      return xhr;
                    }
                });

                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        uploadPromise.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                };
              } catch(e) {
                console.error('Error uploading media file', e);
                // TODO Report to Sentry
              }
            },
            // withCredentials: false,
          }}
          name="file[]"
          allowImagePreview={!compact}
          imagePreviewMaxHeight={75}
          disabled={maxImagesReached()}
          maxFiles={filepondMaxFiles()}
          labelIdle={filepondIdleLabel()}
          onaddfile={(error, file) => {
            const imageMediaId = uuid();
            const filename = `${imageMediaId}.${file.fileExtension}`;

            file.setMetadata('image_media_id', imageMediaId);
            file.setMetadata('filename', filename);
          }}
          onremovefile={(error, file) => {
            //
          }}
          onupdatefiles={(files) => {
            // dispatch({
            //   type: 'SET_FILES',
            //   payload: [...files]
            // });
          }}
          onprocessfileprogress={(file, progress) => {
            // dispatch({
            //   type: 'HYDRATE',
            //   payload: {
            //     singleFileProgress: progress
            //   }
            // });
          }}
          onprocessfile={(error, file) => {
            if (!error) {
              saveAndAddImage(file);
            }
          }}
          ref={ref => filepond.current = ref}
          {...filepondRest}
        />
        {
          // compact && (
          //   <CompactFilesList
          //     singleFileProgress={state.singleFileProgress}
          //     files={state.files}
          //   />
          // )
        }
        {/*jsonData.current.image_edit_enabled && (
          <ImageEditorUI
            imageEditor={imageEditor.current}
            supportedAspects={jsonData.current.supported_aspect_ratios}
          />
        )*/}
        <UIBlocker block={maxImagesReached()} />
      </div>
      {!compact && (
        <Instructions
          jsonData={jsonData.current}
          defaultJsonData={defaultJsonData}
        />
      )}
    </div>
  );

  if (!mountPoint) {
    return component;
  }

  return ReactDOM.createPortal(
    component,
    mountPoint
  );
}

ImagesUploader.defaultProps = {
  mountPoint: null,
  config: {},
  compact: false,
  images: null,
  onImageAdded: () => {},
  onImageDeleted: () => {}
};

export default ImagesUploader;
