import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

// Icons
import plusLargeSolid from '../../images/plus-large-solid.svg';
import editGreen from '../../images/icon-edit-green.svg';

function AccountNote({ currentNote = null, onSubmit = () => {} }) {
    const [formOpen, setFormOpen] = useState(false);
    const [updatedNote, setUpdatedNote] = useState(currentNote);
    
    if (false) {
        return null;
    }

    const handleChange = (event) => {
        setUpdatedNote(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onSubmit(updatedNote);
        setFormOpen(false);
    };
    
    if (formOpen === false) {
        if (!updatedNote) {
            return (
                <div className="dp-add-account-note-wrapper">
                    <button
                        className="dp-account-note-add-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            setFormOpen(true);
                        }}
                        title="Add Account Note"
                    >
                        <img src={plusLargeSolid} className="dp-plus-img"/>
                    </button>
                    <div className="dp-account-note-label">Add Account Note</div>
                </div>
            );
        } else {
            return (
                <div className="dp-account-note-wrapper">
                    <div className="dp-account-note-wrapper-top">
                        <div className="dp-account-note-label">Account Note</div>
                        <button
                            className="dp-account-note-edit-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                setFormOpen(true);
                            }}
                            title="Edit Account Note"
                        >
                            <img src={editGreen} className="dp-edit-img"/>
                        </button>
                    </div>
                    <hr className="dp-account-note-divider" />
                    <div className="dp-account-note-display">
                        {updatedNote}
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="dp-account-note-form-wrapper">
                <form onSubmit={handleSubmit}>
                    <label for="accountNote" className="dp-account-note-label">Account Note</label>
                    <textarea id="accountNote" name="accountNote" value={updatedNote} onChange={handleChange} />
                    <input className="btn" type="submit" value="Submit" />
                </form>
            </div>
        );
    }
    
}

export default AccountNote;
