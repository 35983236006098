import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

// Icons
import dotsIcon from '../../images/dots.svg';

function ContextMenu({ children = null, mountPoint = null, key = null, alignLeft = false, title='Context Menu' }) {
  const [open, setOpen] = useState(false);
  const [alignStyle, setAlignStyle] = useState({
    left: alignLeft ? 0 : 'unset',
    right: alignLeft ? 'unset' : 0,
    // top: '100%',
    // bottom: 'unset',
  });
  // State for scrolling used to determine positioning of context menu
  // const [scrollTop, setScrollTop] = useState();
  // const [scrolling, setScrolling] = useState();

  // Refs
  const contextMenuRef = useRef();
  
  // TODO TEST THIS ------ OLD WORK IN PROGRESS CODE FOR DYNAMIC POSITIONING THE CONTEXT MENU BASED ON SCREEN LOCATION - DOESNT WORK
  // EFFECTS
  // useEffect(() => {
  //   const onScroll = (e) => {
  //     setScrollTop(e.target.documentElement.scrollTop);
  //     setScrolling(e.target.documentElement.scrollTop > scrollTop);
      
  //     // On scroll calculate if context menu is outside of screen and if so set its style accordingly
  //     // if (contextMenuRef) {
  //     //   const isVisible = isElementXPercentInViewport(contextMenuRef.current, 100);
  //     //   if (!isVisible) {
  //     //     const newAlignStyle = {
  //     //       ...alignStyle,
  //     //       top: alignStyle.top === '100%' ? 'unset' : '100%',
  //     //       bottom: alignStyle.bottom === '100%' ? 'unset' : '100%'
  //     //     }
  //     //     setAlignStyle(newAlignStyle);
  //     //     console.log("is element 100% visible? ", isVisible);
  //     //     // console.log(el.getBoundingClientRect());
  //     //     // console.log(window.innerHeight);
  //     //   }
  //     // }
  //   };
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [scrollTop]);

  // useEffect(() => {
  //   // console.log(scrolling);
  // }, [scrolling]);

  // FUNCTIONS
  const toggleContextMenu = () => {
    if (!open) setOpen(true);
    else setOpen(false);
  };

  // TODO TEST THIS ------ OLD WORK IN PROGRESS CODE FOR DYNAMIC POSITIONING THE CONTEXT MENU BASED ON SCREEN LOCATION - DOESNT WORK
  // const isElementXPercentInViewport = function(el, percentVisible) {
  //   let
  //     rect = el.getBoundingClientRect(),
  //     windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  
  //   return !(
  //     Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
  //     Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
  //   )
  // };

  // (() => {
  //   if (contextMenuRef && open) {
  //     console.log('contextMenuRef exists, open: ', open);
  //     const isZeroPercent = isElementXPercentInViewport(contextMenuRef.current, 0);
  //     const isOnePercent = isElementXPercentInViewport(contextMenuRef.current, 1);
  //     const isVisible = isElementXPercentInViewport(contextMenuRef.current, 100);
  //     console.log('isZeroPercent: ', isZeroPercent);
  //     console.log('isOnePercent: ', isOnePercent);
  //     console.log('isVisible: ', isVisible);
  //     if (!isVisible) {
  //       console.log('position flipped');
  //       const newAlignStyle = {
  //         ...alignStyle,
  //         top: alignStyle.top === 'unset' ? '100%' : 'unset',
  //         bottom: alignStyle.bottom === 'unset' ? '100%' : 'unset'
  //       }
  //       setAlignStyle(newAlignStyle);
  //     }
  //   }
  // })();

  const component = (
    <div className="context-menu-wrapper" key={key}>
      <button
        className="more-btn"
        onClick={(e) => {
          e.preventDefault();
          toggleContextMenu();
        }}
        title={title}
      >
        <img src={dotsIcon} alt='More Options' />
      </button>

      {!!open && (
        <div
          class="context-menu-outside"
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        ></div>
      )}

      <div
        className={open ? "context-menu visible" : "context-menu"}
        style={alignStyle}
        ref={contextMenuRef}
      >
        <div>{children}</div>
      </div>
    </div>
  );

  if (mountPoint === null) {
    return component;
  } else {
    return ReactDOM.createPortal(
      component,
      mountPoint
    );
  }
}

export default ContextMenu;
