import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../modal/Modal.js';
import { isDev, getJSONData } from '../../utils';
import { mockActions } from './mock-actions.js'
import axios from 'axios';
import $ from 'jquery';
import Select, { components } from 'react-select';
import UIBlocker from '../UIBlocker';

const bulkActionsMount = document.getElementById('bulk_actions_mount');

const fakeJSONData = {
    context: 'social_profiles'
};

const defaultJsonData = {
    context: null,
    actions: []
};

const checkboxSelector = 'input[type=checkbox]';
const getCheckedIds = () => (
    $('.data-table').find(checkboxSelector + '[name="bulk_actions_select[]"]:checked')
        .toArray()
        .map(cb => cb.value)
);

const attachBulkCheckboxHandlers = () => {
    let lastChecked = null;
    setTimeout(() => { //timeout added so the handlers attach correctly after page load
        const $dataTableHeader = $('.dataTables_scrollHead .data-table');
        $dataTableHeader.on('click', checkboxSelector, function(e) {
            const checkbox = $(this);
            const all = checkbox.attr('name') === 'bulk_actions_select_all';

            if (all) {
                $dataTable.find(checkboxSelector).prop('checked', this.checked);
                window.setBulkActionIds(getCheckedIds());
                return;
            }
        });
    }, 2000);
    const $dataTable = $('.data-table');
    const checkboxSelector = 'input[type=checkbox]';
   
    $dataTable.on('click', checkboxSelector, function(e) {
        const checkbox = $(this);
        const all = checkbox.attr('name') === 'bulk_actions_select_all';

        if (all) {
            $dataTable.find(checkboxSelector).prop('checked', this.checked);
            window.setBulkActionIds(getCheckedIds());
            return;
        }

        if (!lastChecked) {
            lastChecked = this;
            window.setBulkActionIds(getCheckedIds());
            return;
        }

        if (e.shiftKey && lastChecked) {
            var from = $dataTable.find(checkboxSelector).index(this);
            var to = $dataTable.find(checkboxSelector).index(lastChecked);

            var start = Math.min(from, to);
            var end = Math.max(from, to) + 1;

            $dataTable.find(checkboxSelector).slice(start, end)
                .prop('checked', lastChecked.checked);
        }

        lastChecked = this;

        window.setBulkActionIds(getCheckedIds());
    });
}

function BulkActions(props) {
    const [ready, setReady] = useState(false);
    const [customNotifModalIsOpen, setCustomNotifModalIsOpen] = useState(false);
    const [bulkTaggingModalIsOpen, setBulkTaggingModalIsOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [context, setContext] = useState('');
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [ids, setIds] = useState([]);

    useEffect(() => {
        if (!!bulkActionsMount) {
            initialize();
        }
    }, []);

    const openCustomNotifModal = () => {
        setCustomNotifModalIsOpen(true);
    };

    const closeCustomNotifModal = async (clearState = true) => {
        $(document).ready(function() {
            $('body').one('modalClosed', '#custom_notif_wrapper_modal', async () => {
                // Prevent duplicate of form
                if (window.uninitCustomNotificationForm) {
                    window.uninitCustomNotificationForm();
                }
                if ($('#custom_form').length === 0) {
                    var modalContent = $('#modal_custom_form').removeAttr('id').attr('id', 'custom_form');
                    $('#custom_notif_wrapper').append(modalContent);
                }
            });
            $('#custom_notif_wrapper_modal').trigger('modalClosed');

            // title and body required - get working
            setCustomNotifModalIsOpen(false);
        });

    };

    const openBulkTaggingModal = () => {
        setBulkTaggingModalIsOpen(true);
    };

    const closeBulkTaggingModal = async (clearState = true) => {
        $(document).ready(function() {
            $('body').one('modalClosed', '#bulk_tagging_wrapper_modal', async () => {
                // Prevent duplicate of form
                if (window.uninitBulkTaggingForm) {
                    window.uninitBulkTaggingForm();
                }
                if ($('#tagging_form').length === 0) {
                    var modalContent = $('#modal_tagging_form').removeAttr('id').attr('id', 'tagging_form');
                    $('#bulk_tagging_wrapper').append(modalContent);
                }
            });
            $('#bulk_tagging_wrapper_modal').trigger('modalClosed');

            // title and body required - get working
            setBulkTaggingModalIsOpen(false);
        });

    };

    const initialize = () => {
        const jsonData = { ...defaultJsonData, ...getJSONData('#bulk_actions_mount', isDev() ? fakeJSONData : null) };

        setContext(jsonData.context);

        window.bulkActions = { selectedIds: [] };
        window.getBulkActionIds = () => window.bulkActions.selectedIds;
        window.setBulkActionIds = ids => {
            setIds([...ids]);
            window.bulkActions.selectedIds = [...ids];
        };

        const supportedActions = jsonData.bulk_actions || [];
        const formData = jsonData.form_data || {};

        if (isDev()) {
            // Fake Server Data
            setActions(mockActions);
            setReady(true);
        } else {
            // Load server data
            axios.get(jsonData.ajax_url, {
                params: {
                    bulk_actions: supportedActions,
                    form_data: formData,
                },
                crossDomain: true
            })
                .then(function (response) {
                    // handle success
                    setActions(response.data.available_actions);
                    setReady(true);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }

        attachBulkCheckboxHandlers();
    }

    const confirmSelection = (selectedAction, ids) => {
        if (ids.length) {
            return window.confirm(`Are you sure you want to apply the ${selectedAction._bulkAction.name} action to all of the selected records?`);
        } else {
            return window.confirm(`You do not have any records selected. Are you sure you want to apply the ${selectedAction._bulkAction.name} action to the entire curernt segment?`);
        }
    }

    const apply = (selectedAction, ids) => {
        if (!selectedAction) return;
        if (selectedAction.disabled) {
            setSelectedAction(null);
            return;
        }

        if (!confirmSelection(selectedAction, ids)) return;

        if (isDev()) {
            const bulActionFormData = $('form#bulk_actions').serializeArray();
            console.log('Submitted - Form Data:', bulActionFormData);
        } else {
            setSubmitting(true);
            $('#bulk_actions').submit();
        }
    }

    if (!ready) return null;

    const component = (
        <div className="ui-block-container">
            <Select
                isMulti={false}
                options={actions.map(a => ({
                    label: a.name,
                    value: a.slug,
                    _bulkAction: a,
                }))}
                onChange={selectedOption => {
                    setSelectedAction(selectedOption);

                    // Only for custom notifications
                    if (selectedOption.value === "social_profiles.send_custom_notifications") {
                        // Open Modal
                        openCustomNotifModal();

                        // Clone Mike's hidden form element into your React modal (you can use jQuery if need be)
                        $(document).ready(function(){
                            $('body').one('modalOpened', '#custom_notif_wrapper_modal', function() {
                                // Prevent duplicate of form
                                if($('#modal_custom_form').length === 0) {
                                    var modalContent = $('#custom_form').removeAttr('id').attr('id', 'modal_custom_form');
                                    $(modalContent).find('#tabs').removeAttr('id').attr('id', 'tabs_modal');
                                    $(modalContent).find('#tabs_modal li:first-of-type a').removeAttr('href').attr('href', '#modal_email_new');
                                    $(modalContent).find(' #tabs_modal li:last-of-type a').removeAttr('href').attr('href', '#modal_push_new');
                                    $(modalContent).find(' #email-new').removeAttr('id').attr('id', 'modal_email_new');
                                    $(modalContent).find(' #push-new').removeAttr('id').attr('id', 'modal_push_new');
                                    $('#custom_notif_wrapper_modal').append(modalContent);
                                    if(window.initCustomNotificationForm) {
                                        window.initCustomNotificationForm();
                                    }

                                }

                            });

                            $('#custom_notif_wrapper_modal').trigger('modalOpened');
                            // title and body required - get working
                        });
                    }

                    // Only for Bulk Tagging
                    if (selectedOption.value === "tags.assign") {
                        // Open Modal
                        openBulkTaggingModal();

                        // Clone Mike's hidden form element into your React modal (you can use jQuery if need be)
                        // TODO Finish changing out custom_notif stuff for bulk tagging stuff
                        $(document).ready(function(){
                            $('body').one('modalOpened', '#bulk_tagging_wrapper_modal', function() {
                                // Prevent duplicate of form
                                if($('#modal_tagging_form').length === 0) {
                                    var modalContent = $('#tagging_form').removeAttr('id').attr('id', 'modal_tagging_form');
                                    $(modalContent).find('#tabs').removeAttr('id').attr('id', 'tabs_modal');
                                    $(modalContent).find('#tabs_modal li:first-of-type a').removeAttr('href').attr('href', '#modal_email_new');
                                    $(modalContent).find(' #tabs_modal li:last-of-type a').removeAttr('href').attr('href', '#modal_push_new');
                                    $(modalContent).find(' #email-new').removeAttr('id').attr('id', 'modal_email_new');
                                    $(modalContent).find(' #push-new').removeAttr('id').attr('id', 'modal_push_new');
                                    $('#bulk_tagging_wrapper_modal').append(modalContent);
                                    if(window.initBulkTaggingForm) {
                                        window.initBulkTaggingForm();
                                    }

                                }

                            });

                            $('#bulk_tagging_wrapper_modal').trigger('modalOpened');
                            // title and body required - get working
                        });
                    }
                }}
                name="action"
                value={selectedAction}
                className='raleway-semi-bold raleway-12 react-select-container'
                components={{
                    Option: ({ innerProps, data, isDisabled, ...restProps }) => {
                        const customInnerProps = { ...innerProps };
                        if (data._bulkAction.disabled && data._bulkAction.disabled_reason) {
                            const disabledMsg = `Disabled: ${data._bulkAction.disabled_reason}`;
                            customInnerProps.onClick = () => {
                                alert(disabledMsg);
                            };
                            customInnerProps.title = disabledMsg;
                        }

                        return (
                            <components.Option
                                innerProps={customInnerProps}
                                data={data}
                                isDisabled={data._bulkAction.disabled} {...restProps}
                            />
                        );
                    }
                }}
            />
            <button
                onClick={e => {
                    e.preventDefault();
                    apply(selectedAction, ids)
                }}
                className="btn"
            >
                Apply
            </button>
            <Modal
                isOpen={customNotifModalIsOpen}
                onRequestClose={() => {
                    closeCustomNotifModal();
                }}
                title="Custom Notification"
                blockUI={submitting}
                buttons={[
                    {
                        style: 'white',
                        text: 'Cancel',
                        onClick: () => {
                            // - On cancel button hit, close modal
                            closeCustomNotifModal();
                        }
                    },
                    {
                        style: 'black',
                        text: 'Submit',
                        onClick: async () => {
                            // - On Send button hit, $(the form ID).serializeArray() to get form data, copy it into the Bulk actions form using jQuery as hidden elements
                            const start = async () => {
                                if (window.uninitCustomNotificationForm) {
                                    window.uninitCustomNotificationForm();
                                } // unitialize here because of editor not saving value for the serialize array
                                var serializedForm = $('#modal_custom_form').serializeArray();
                                var serializedFormFiltered = $.grep(serializedForm, function(value) {
                                    return (value.name !== "_method" && value.name !== "_token");
                                });
                                var invalidField = false;
                                for(var i = 0; i < serializedFormFiltered.length; i++) {
                                    if(!serializedFormFiltered[i].value){
                                        var results = serializedFormFiltered[i].name.split('__');
                                        if(!results[4]) { //This is for the images because the caption is always empty and the value for sort_order is always zero
                                            alert('The ' + results[2] + ' ' + results[3] + ' cannot be empty!');
                                            invalidField = true;
                                        }
                                    }
                                }

                                if(invalidField) {
                                    closeCustomNotifModal();
                                    return;
                                }

                                //grab bulk actions form '#bulk_actions'
                                var bulkActionsForm = $('#bulk_actions');
                                if (bulkActionsForm.length) {
                                    // Prevent duplicate of fields
                                    await $('.bulk-actions-dynamic-form-data').remove();

                                    //loop thru serializedForm and create hidden inputs like below, but not in react
                                    await $.map( serializedFormFiltered, function( field, i ) {
                                        //replace with different apostrophe type char to fix problem where it cut off values
                                        bulkActionsForm.append("<input type='hidden' name='"+field.name+"' value='"+field.value.replace(/'/g, "&#8217")+"' class='bulk-actions-dynamic-form-data' />");
                                    });
                                }

                                closeCustomNotifModal();
                                apply(selectedAction, ids); // should warn that asks if "sure you wanna do this"
                            }
                            $(document).ready(start); // TODO: Might be performance issue - or memleak
                        }
                    }
                ]}
            >
                <div id="custom_notif_wrapper_modal"></div>
            </Modal>

            <Modal
                isOpen={bulkTaggingModalIsOpen}
                onRequestClose={() => {
                    closeBulkTaggingModal();
                }}
                title="Tagging"
                blockUI={submitting}
                buttons={[
                    {
                        style: 'white',
                        text: 'Cancel',
                        onClick: () => {
                            // - On cancel button hit, close modal
                            closeBulkTaggingModal();
                        }
                    },
                    {
                        style: 'black',
                        text: 'Submit',
                        onClick: async () => {
                            // - On Send button hit, $(the form ID).serializeArray() to get form data, copy it into the Bulk actions form using jQuery as hidden elements
                            const start = async () => {
                                if (window.uninitBulkTaggingForm) {
                                    window.uninitBulkTaggingForm();
                                } // unitialize here because of editor not saving value for the serialize array
                                var serializedForm = $('#modal_tagging_form').serializeArray();
                                var serializedFormFiltered = $.grep(serializedForm, function(value) {
                                    return (value.name !== "_method" && value.name !== "_token");
                                });
                                var invalidField = false;
                                for(var i = 0; i < serializedFormFiltered.length; i++) {
                                    if(!serializedFormFiltered[i].value){
                                        var results = serializedFormFiltered[i].name.split('__');
                                        if(!results[4]) { //This is for the images because the caption is always empty and the value for sort_order is always zero
                                            alert('The ' + results[2] + ' ' + results[3] + ' cannot be empty!');
                                            invalidField = true;
                                        }
                                    }
                                }

                                if(invalidField) {
                                    closeBulkTaggingModal();
                                    return;
                                }

                                //grab bulk actions form '#bulk_actions'
                                var bulkActionsForm = $('#bulk_actions');
                                if (bulkActionsForm.length) {
                                    // Prevent duplicate of fields
                                    await $('.bulk-actions-dynamic-form-data').remove();

                                    //loop thru serializedForm and create hidden inputs like below, but not in react
                                    await $.map( serializedFormFiltered, function( field, i ) {
                                        //replace with different apostrophe type char to fix problem where it cut off values
                                        bulkActionsForm.append("<input type='hidden' name='"+field.name+"' value='"+field.value.replace(/'/g, "&#8217")+"' class='bulk-actions-dynamic-form-data' />");
                                    });
                                }

                                closeBulkTaggingModal();
                                apply(selectedAction, ids); // should warn that asks if "sure you wanna do this"
                            }
                            $(document).ready(start); // TODO: Might be performance issue - or memleak
                        }
                    }
                ]}
            >
                <div id="bulk_tagging_wrapper_modal"></div>
            </Modal>
            
            <input key="context" type="hidden" name="context" value={context} />
            {ids.map((id, i) => (
                <input key={id} type="hidden" name={`ids[${i}]`} value={id} />
            ))}
            <UIBlocker block={submitting} />
        </div>
    );

    if (!bulkActionsMount) {
        return null;
    }

    return ReactDOM.createPortal(
        component,
        bulkActionsMount,
    );
}

export default BulkActions;
