import $ from 'jquery';
import React, { Link, useReducer, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ContextMenu from "../context-menu/ContextMenu";
import InstagramPostsGrid from "../Instagram-posts-grid/InstagramPostsGrid";
import _ from 'lodash';
import moment from 'moment-timezone';
import axios from 'axios';
import { reducer, initialState } from './reducer';
import { isDev, numberFormatter } from '../../utils';
import UIBlocker from '../UIBlocker';
import { handleAction } from '../../row-actions/init';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import GridLoader from "react-spinners/GridLoader";
import AccountNote from "./AccountNote";

// Icons
import xIcon from '../../images/icon-x-dark.svg';
import editIcon from '../../images/icon-edit.svg';
import profileIcon from '../../images/icon-profile.svg';
import prevIcon from '../../images/icon-prev.svg';
import nextIcon from '../../images/icon-next.svg';
import placeholderImg from '../../images/placeholder.png';
import { handleRowActionClick } from "../../Lib/rowActionHelpers";

const detailPaneMount = document.getElementById('detail_pane_mount');

let jsonData = {
    base_url: 'https://app.test',
    context: 'global',
};

function DetailPane() {
    // State
    const [state, dispatch] = useReducer(reducer, initialState);
    const unsavedRef = useRef(state.unsaved);
    const readyRef = useRef(state.ready);
    const uiBlockerTextRef = useRef(state.uiBlockerText);
    const currentSocialIdRef = useRef(state.currentSocialId);
    const currentRowRef = useRef(state.currentRow);
    const currentRowActionsRef = useRef(state.currentRowActions);
    const currentUserDetailsRef = useRef(state.currentUserDetails);
    const disablePrevBtnRef = useRef(state.disablePrevBtn);
    const disableNextBtnRef = useRef(state.disableNextBtn);
    const activeTabRef = useRef(state.activeTab);
    const networksActiveTabRef = useRef(state.networksActiveTab); // DEPRECATED
    const instagramActiveTabRef = useRef(state.instagramActiveTab); // DEPRECATED
    const tiktokActiveTabRef = useRef(state.tiktokActiveTab); // DEPRECATED
    const isRecursiveFetchingRef = useRef(state.isRecursiveFetching);
    const recursiveFetchTimeoutIDRef = useRef(state.recursiveFetchTimeoutID);
    const loadingRecentInstagramPostsRef = useRef(state.loadingRecentInstagramPosts);
    const loadingRecentTiktokPostsRef = useRef(state.loadingRecentTiktokPosts);
    // const axiosCancelTokenSourceRef = useRef(state.axiosCancelTokenSource);

    useEffect(() => {
        unsavedRef.current = state.unsaved;
    }, [state.unsaved]);

    useEffect(() => {
        readyRef.current = state.ready;
    }, [state.ready]);
    
    useEffect(() => {
        uiBlockerTextRef.current = state.uiBlockerText;
    }, [state.uiBlockerText]);

    useEffect(() => {
        currentSocialIdRef.current = state.currentSocialId;
    }, [state.currentSocialId]);

    useEffect(() => {
        currentRowRef.current = state.currentRow;
    }, [state.currentRow]);

    useEffect(() => {
        currentRowActionsRef.current = state.currentRowActions;
    }, [state.currentRowActions]);

    useEffect(() => {
        currentUserDetailsRef.current = state.currentUserDetails;
    }, [state.currentUserDetails]);

    useEffect(() => {
        disablePrevBtnRef.current = state.disablePrevBtn;
    }, [state.disablePrevBtn]);

    useEffect(() => {
        disableNextBtnRef.current = state.disableNextBtn;
    }, [state.disableNextBtn]);

    useEffect(() => {
        activeTabRef.current = state.activeTab;
    }, [state.activeTab]);
    
    useEffect(() => { // DEPRECATED
        networksActiveTabRef.current = state.networksActiveTab;
    }, [state.networksActiveTab]);
    
    useEffect(() => { // DEPRECATED
        instagramActiveTabRef.current = state.instagramActiveTab;
    }, [state.instagramActiveTab]);
    
    useEffect(() => { // DEPRECATED
        tiktokActiveTabRef.current = state.tiktokActiveTab;
    }, [state.tiktokActiveTab]);

    useEffect(() => {
        isRecursiveFetchingRef.current = state.isRecursiveFetching;
    }, [state.isRecursiveFetching]);

    useEffect(() => {
        recursiveFetchTimeoutIDRef.current = state.recursiveFetchTimeoutID;
    }, [state.recursiveFetchTimeoutID]);

    useEffect(() => {
        loadingRecentInstagramPostsRef.current = state.loadingRecentInstagramPosts;
    }, [state.loadingRecentInstagramPosts]);
    
    useEffect(() => {
        loadingRecentTiktokPostsRef.current = state.loadingRecentTiktokPosts;
    }, [state.loadingRecentTiktokPosts]);
    
    // useEffect(() => {
    //     axiosCancelTokenSourceRef.current = state.axiosCancelTokenSource;
    // }, [state.axiosCancelTokenSource]);
    
    //
    // API
    //

    useEffect(() => {
        window.openDetailPane = () => {
            dispatch({
                type: 'HYDRATE',
                payload: {
                    open: true,
                    ready: false,
                    uiBlockerText: "Loading User Data...",
                }
            });
        }

        window.closeDetailPane = () => {
            handleActiveRow();

            dispatch({
                type: 'HYDRATE',
                payload: {
                    open: false,
                    ready: false,
                    uiBlockerText: null,
                    currentSocialId: null,
                    currentRow: null,
                    currentRowActions: null,
                    currentUserDetails: null,
                    disablePrevBtn: false,
                    disableNextBtn: false,
                    initializedState: {},
                    activeTab: 0,
                    networksActiveTab: 0, // DEPRECATED
                    instagramActiveTab: 0, // DEPRECATED
                    tiktokActiveTab: 0, // DEPRECATED
                }
            });
        }

        // Button - Open Detail Pane
        $(document).ready(function(){
            const $dataTable = $('.data-table');
            $dataTable.on('click', '.open-detail-pane', async function(e) {
                e.preventDefault();

                // Open the Detail Pane (Must be first)
                window.openDetailPane();

                // Get Parent Row
                let $parentRow = $(this).closest('tr');

                // Get Data From Row And Initialize User Details Pane
                populateDetailsFromRow($parentRow);
            });
        });

        if (isDev()) {
            // window.openDetailPane();
        }
    }, []);
    
    const cancelRecursiveFetch = () => {
        // This was an attempt at cancelling axios requests when UI blocking was turned off for recent post scraping
        //     The issue was that it cancelled all future requests as well since DetailPane never remounts and resets
        //     the axios cancel token. Leaving this here in case we ever decide to revisit this functionality. You'll
        //     have to uncomment all references to axiosCancelTokenSource in state (and in the reducer file), and 
        //     uncomment cancelToken in all axios requests on this component.
        //     ~ Clem (01/07/2021)
        // axiosCancelTokenSourceRef.current.cancel('User Switched - Axios Request Cancelled');
        
        isRecursiveFetchingRef.current = false;
        clearTimeout(recursiveFetchTimeoutIDRef.current);
    }

    const handleActiveRow = ($row = null) => {
        // Remove class from all rows
        $('table.dataTable tbody tr.active-detail').removeClass('active-detail');

        // Add to new row if it exists
        if ($row !== null) {
            $row.addClass('active-detail');
        }
    };

    const loadPreviousUser = () => {
        $(document).ready(function(){
            // Get New Row
            const $currentRow = state.currentRow;
            const $newRow = $currentRow.prev();

            if ($newRow.length) {
                // Block UI
                dispatch({
                    type: 'HYDRATE',
                    payload: {
                        ready: false,
                        uiBlockerText: "Loading Previous User's Data...",
                        // 
                        currentSocialId: null,
                        currentRowActions: null,
                        currentUserDetails: null,
                        activeTab: 0,
                        networksActiveTab: 0, // DEPRECATED
                        instagramActiveTab: 0, // DEPRECATED
                        tiktokActiveTab: 0, // DEPRECATED
                    }
                });

                // Get Data From Row And Initialize User Details Pane
                populateDetailsFromRow($newRow);
            } else {
                alert("Error - Top of data table");
            }
        });
    };

    const loadNextUser = () => {
        $(document).ready(function(){
            // Get New Row
            const $currentRow = state.currentRow;
            const $newRow = $currentRow.next();

            if ($newRow.length) {
                // Block UI
                dispatch({
                    type: 'HYDRATE',
                    payload: {
                        ready: false,
                        uiBlockerText: "Loading Next User's Data...",
                        // 
                        currentSocialId: null,
                        currentRowActions: null,
                        currentUserDetails: null,
                        activeTab: 0,
                        networksActiveTab: 0, // DEPRECATED
                        instagramActiveTab: 0, // DEPRECATED
                        tiktokActiveTab: 0, // DEPRECATED
                    }
                });

                // Get Data From Row And Initialize User Details Pane
                populateDetailsFromRow($newRow);
            } else {
                alert("Error - Bottom of data table");
            }
        });
    };

    //
    // Functions
    //

    const populateDetailsFromRow = async ($row) => {
        
        // Set Active Class to Row
        handleActiveRow($row);

        // Get Details
        const currentSocialProfileId = getSocialProfileIdFromRow($row); // Social Profile Id
        await initialize(currentSocialProfileId, 0, 0); // User Details (user, social profile, primary theme)
        const $currentRowActions = getRowActionsFromRow($row); // Row Action Buttons

        const disablePrevBtn = !$row.prev().length ? true : false; // Calculate whether or not to show Prev Button
        const disableNextBtn = !$row.next().length ? true : false; // Calculate whether or not to show Next Button

        // Update State
        dispatch({
            type: 'HYDRATE',
            payload: {
                ready: true,
                uiBlockerText: null,
                currentSocialId: currentSocialProfileId,
                currentRow: $row,
                currentRowActions: $currentRowActions,
                disablePrevBtn,
                disableNextBtn,
            }
        });
    };

    const getSocialProfileIdFromRow = ($row) => {
        // Get Social Profile Id from row and store in state
        let currentSocialProfileId = $row.find('.bulk-select input').val();

        return currentSocialProfileId;
    };

    const getRowActionsFromRow = ($row) => {
        // Get Row Action Buttons
        let $rowActions = $row.find('.original-row-actions .row-action-buttons');
        let $currentRowActions = $rowActions.clone(false); // Clone row actions
        let socialId = $row.find('.bulk-select input').val();

        // Clone the Row Action Buttons into the Detail Pane
        let $detailPaneActions = $('.detail-pane .row-action-buttons-wrapper');
        $detailPaneActions.html($currentRowActions);

        // Handle click event for newly cloned buttons
        $detailPaneActions.on('click', 'a.data-table-row-action', async function(e) {
            e.preventDefault();

            const submitAction = async ($object) => {
                $object.addClass('processing');
                $object.addClass('active');

                // Block UI
                dispatch({
                    type: 'HYDRATE',
                    payload: {
                        ready: false,
                        uiBlockerText: "Saving...",
                    }
                });

                await handleAction($object.attr('href'), $object.data(), $row);

                // Clone Row Action Buttons again to get updated visuals
                $currentRowActions = $rowActions.clone(false);
                $detailPaneActions.html($currentRowActions);

                // Un-Block UI
                dispatch({
                    type: 'HYDRATE',
                    payload: {
                        ready: true,
                        uiBlockerText:  null,
                    }
                });

                $object.removeClass('processing');
                
                // Add separator between each action's button set after update
                $('<hr class="row-action-btn-separator"></hr>').insertAfter('.detail-pane .dp-buttons .row-action-buttons a.split-btn-right');
            };

            handleRowActionClick($(this), socialId, submitAction);
        });

        // Add separator between each action's button set
        $('<hr class="row-action-btn-separator"></hr>').insertAfter('.detail-pane .dp-buttons .row-action-buttons a.split-btn-right');

        return $detailPaneActions;
    };

    const getJSONData = () => {
        const defaultData = { ...jsonData };

        if (isDev()) {
            // defaultData.base_url = 'http://app.test';
            defaultData.base_url = window.location.protocol + '//' + window.location.host;
            defaultData.context = 'social_profiles.master_list';
        } else {
            defaultData.base_url = window.location.protocol + '//' + window.location.host;
        }

        return {
            ...defaultData,
        };
    };

    const getImageBaseUrl = async () => {
        // Load JSON data
        jsonData = getJSONData();
        const { base_url } = jsonData;

        return await axios.get(`${base_url}/ajax/detail-pane/get-base-url`);
    };

    const initialize = async (socialProfileId = null, maxAttempts = 0, currentAttempts = 0) => {
        let shouldRetry = false; // Flag for retrying if post media is not downloaded yet
        
        // Only Fetch if Social Profile Id is not null
        if (socialProfileId !== null) {
            if ((isRecursiveFetchingRef.current === false) && (currentAttempts > 0)) {
                // Recursive Fetching Has Been Cancelled - dont do it
                return;
            } else {
                // Load JSON data
                jsonData = getJSONData();
                const { context, base_url } = jsonData;
    
                // Load server data
                await axios.get(`${base_url}/ajax/data-table/user-social-get`, {
                    params: {
                        social_profile_id: socialProfileId
                    },
                    crossDomain: true,
                    timeout: isDev() ? 0 : 30000,
                    // cancelToken: axiosCancelTokenSourceRef.current.token
                })
                    .then(async function (response) {
                        const currentUserDetails = response?.data?.userDetails || null;
    
                        await getImageBaseUrl().then((responsetwo) => {
                            const imageBaseUrl = responsetwo.data;
                            
                            // Separate INSTAGRAM TOP POSTS from social posts
                            let topInstagramPostData = _.filter(
                                currentUserDetails?.social_posts,
                                { 'source': 'AUDIENCE', 'social_media_network': 'INSTAGRAM' }
                                /*['source', 'AUDIENCE']*/
                            ).slice(0, 9);
                            
                            // Separate INSTAGRAM RECENT POSTS from social posts
                            let recentInstagramPostData = _.orderBy(
                                _.filter(
                                    currentUserDetails?.social_posts,
                                    { 'source': 'RECENT_POSTS', 'social_media_network': 'INSTAGRAM' }
                                    /*['source', 'RECENT_POSTS']*/
                                ),
                                [(socialPost) => {
                                    return socialPost.post.created;
                                }],
                                ['desc']
                            );

                            // Separate TIKTOK TOP POSTS from social posts
                            let topTiktokPostData = _.filter(
                                currentUserDetails?.social_posts,
                                { 'source': 'AUDIENCE', 'social_media_network': 'TIKTOK' }
                                /*['source', 'AUDIENCE']*/
                            ).slice(0, 9);

                            // Separate TIKTOK RECENT POSTS from social posts
                            let recentTiktokPostData = _.orderBy(
                                _.filter(
                                    currentUserDetails?.social_posts,
                                    { 'source': 'RECENT_POSTS', 'social_media_network': 'TIKTOK' }
                                    /*['source', 'RECENT_POSTS']*/
                                ),
                                [(socialPost) => {
                                    return socialPost.post.created;
                                }],
                                ['desc']
                            );
    
                            // Helper for calculating extra data from social posts -> called in dispatch on success.
                            const processedPostData = ( postData = null) => {
                                if (newData === null) return null;
    
                                let newData = postData;
    
                                _.forEach(newData, (socialPost, index) => {
                                    const post = socialPost.post;
                                    
                                    let engagement = null;
                                    let carouselUrls = null;

                                    if (socialPost.social_media_network === "TIKTOK") {
                                        // Make sure all values are numbers before running calcs
                                        if (Number.isInteger(post?.stat?.comments) && Number.isInteger(post?.stat?.likes) && (Number.isInteger(post?.stat?.views) || Number.isInteger(post?.stat?.play_count))) {
                                            if (Number.isInteger(post?.stat?.views)) {
                                                engagement = parseFloat((post?.stat?.comments + post?.stat?.likes) / post?.stat?.views * 100).toFixed(1) || null;
                                            } else if (Number.isInteger(post?.stat?.play_count)) {
                                                engagement = parseFloat((post?.stat?.comments + post?.stat?.likes) / post?.stat?.play_count * 100).toFixed(1) || null;
                                            }
                                        }
                                    } else if (currentUserDetails?.follower_count) {
                                        // Make sure all values are numbers before running calcs
                                        if (Number.isInteger(post?.stat?.comments) && Number.isInteger(post?.stat?.likes) && Number.isInteger(currentUserDetails?.follower_count)) {
                                            engagement = parseFloat((post?.stat?.comments + post?.stat?.likes) / currentUserDetails?.follower_count * 100).toFixed(1) || null;
                                        }
                                    }

                                    if (engagement !== null && engagement > 100) engagement = parseFloat(100);

                                    if ((!!post?.carousel_media_count) && (post?.carousel_media_count > 1) && (!_.isEmpty(post?.carousel_media))) {
                                        carouselUrls = _.map(post.carousel_media, (media) => {
                                            if ((!!socialPost?.media) && socialPost?.media[media?.display_url]) {
                                                return (imageBaseUrl + socialPost?.media[media.display_url]);
                                            } else {
                                                return placeholderImg;
                                            }
                                        });
                                    }
                                    
                                    if (socialPost?.product_type?.toUpperCase() === 'PHOTO') {
                                        if ((!socialPost?.media) || socialPost?.media.length < 1) {
                                            shouldRetry = true;
                                        }
                                    } else if (socialPost?.product_type?.toUpperCase() === 'CAROUSEL') {
                                        if ((!socialPost?.media) || socialPost?.media.length < socialPost?.post?.carousel_media.length) {
                                            shouldRetry = true;
                                        }
                                    }

                                    newData[index] = {
                                        ...socialPost,
                                        post: {
                                            ...post,
                                            engagement: engagement,
                                            imageUrl: (
                                                ((!!socialPost?.media) && socialPost?.media[post['image']]) ?
                                                    (imageBaseUrl + socialPost?.media[post['image']])
                                                    :
                                                    ((!!socialPost?.media) && socialPost?.media[post['thumbnail']]) ?
                                                        (imageBaseUrl + socialPost?.media[post['thumbnail']])
                                                        :
                                                        placeholderImg
                                            ),
                                            carouselUrls: carouselUrls,
                                        }
                                    }
                                });
    
                                return newData;
                            };
                            
                            const overallInstagramEngagement = (
                                (!!currentUserDetails?.avg_likes && !!currentUserDetails?.avg_comments && !!currentUserDetails?.follower_count) ?
                                    parseFloat((currentUserDetails?.avg_likes + currentUserDetails?.avg_comments) / currentUserDetails?.follower_count * 100)
                                    : null
                            );
                            
                            const overallTiktokEngagement = (
                                (!!currentUserDetails?.tiktok_avg_likes && !!currentUserDetails?.tiktok_avg_comments && !!currentUserDetails?.tiktok_avg_views) ?
                                    parseFloat((currentUserDetails?.tiktok_avg_likes + currentUserDetails?.tiktok_avg_comments) / currentUserDetails?.tiktok_avg_views * 100)
                                    : null
                            );
                            const audienceData = (!!currentUserDetails?.instagram_audience_data) ? JSON.parse(currentUserDetails?.instagram_audience_data) : null;
    
                            const engagementBoundaries = (() => {
                                if (!!audienceData) {
                                    let engagementRateHistogram = audienceData?.extra?.engagement_rate_histogram;
                                    let engagementRateMedian = null;
    
                                    engagementRateHistogram.forEach((elem) => {
                                        if (elem?.median === true) {
                                            engagementRateMedian = elem;
                                        }
                                    });
    
                                    return engagementRateMedian;
                                } else {
                                    return null;
                                }
                            })();
    
                            // handle success
                            dispatch({
                                type: 'HYDRATE',
                                payload: {
                                    currentUserDetails: {
                                        ...currentUserDetails,
                                        topInstagramPosts: processedPostData(topInstagramPostData),
                                        recentInstagramPosts: processedPostData(recentInstagramPostData),
                                        topTiktokPosts: processedPostData(topTiktokPostData),
                                        recentTiktokPosts: processedPostData(recentTiktokPostData),
                                        overallInstagramEngagement: overallInstagramEngagement,
                                        overallTiktokEngagement: overallTiktokEngagement,
                                        engagementBoundaries: engagementBoundaries,
                                    },
                                    initializedState: {
                                        ...jsonData,
                                    },
                                }
                            });
                        })
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            console.log(error.message);
                        } else {
                            console.error(error);
                        }
                    });
                
                if (shouldRetry && (currentAttempts < maxAttempts)) {
                    // Re-run initialize function recursively
                    let timeoutId = setTimeout(() => {
                        initialize(socialProfileId, maxAttempts, (currentAttempts + 1));
                    }, 5000);
                    
                    recursiveFetchTimeoutIDRef.current = timeoutId;
                    isRecursiveFetchingRef.current = true;
                } else {
                    isRecursiveFetchingRef.current = false;
                }
            }
        }
    };

    const scrapeRecentInstagramPosts = async (socialProfileId = null, getMorePosts = 0) => {
        if (socialProfileId !== null) {
            // Load JSON data
            jsonData = getJSONData();
            const { context, base_url } = jsonData;

            // Block UI
            dispatch({
                type: 'HYDRATE',
                payload: {
                    loadingRecentInstagramPosts: true,
                    ready: false,
                    // uiBlockerText: "Loading Recent Posts...",
                }
            });

            await axios.get(`${base_url}/trigger-recent-posts`, {
                params: {
                    social_profile_id: socialProfileId,
                    get_more_posts: getMorePosts,
                    network: 'INSTAGRAM',
                    // cancelToken: axiosCancelTokenSourceRef.current.token
                },
                crossDomain: true,
                timeout: isDev() ? 0 : 120000
            })
                .then(async function (response) {
                    if (!!response) {
                        await initialize(socialProfileId, 24, 0);
                    }
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        console.log(error.message);
                    } else {
                        console.error(error);
                    }
                });

            // Un-Block UI
            dispatch({
                type: 'HYDRATE',
                payload: {
                    loadingRecentInstagramPosts: false,
                    ready: true,
                    // uiBlockerText:  null,
                }
            });
        }
    };

    const scrapeRecentTiktokPosts = async (socialProfileId = null, getMorePosts = 0) => {
        if (socialProfileId !== null) {
            // Load JSON data
            jsonData = getJSONData();
            const { context, base_url } = jsonData;

            // Block UI
            dispatch({
                type: 'HYDRATE',
                payload: {
                    loadingRecentTiktokPosts: true,
                    ready: false,
                    // uiBlockerText: "Loading Recent Posts...",
                }
            });

            await axios.get(`${base_url}/trigger-recent-posts`, {
                params: {
                    social_profile_id: socialProfileId,
                    get_more_posts: getMorePosts,
                    network: 'TIKTOK',
                    // cancelToken: axiosCancelTokenSourceRef.current.token
                },
                crossDomain: true,
                timeout: isDev() ? 0 : 120000
            })
                .then(async function (response) {
                    if (!!response) {
                        await initialize(socialProfileId, 24, 0);
                    }
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        console.log(error.message);
                    } else {
                        console.error(error);
                    }
                });

            // Un-Block UI
            dispatch({
                type: 'HYDRATE',
                payload: {
                    loadingRecentTiktokPosts: false,
                    ready: true,
                    // uiBlockerText:  null,
                }
            });
        }
    };
    
    if (!state.open) return null;

    /* Calculated Data - START */
    const dataName = (() => {
        const hasFName = !!state?.currentUserDetails?.user?.first_name;
        const hasLName = !!state?.currentUserDetails?.user?.last_name;

        if (!hasFName && !hasLName) {
            return 'Name N/A';
        } else {
            return (
                (state?.currentUserDetails?.user?.first_name || '')
                + ' '
                + (state?.currentUserDetails?.user?.last_name || '')
            );
        }
    })();

    const dataInstagramHandle = (() => {
        const hasHandle = !!state?.currentUserDetails?.social_handle;

        if (!hasHandle) {
            return 'Social Handle N/A';
        } else {
            return ('@' + state?.currentUserDetails?.social_handle);
        }
    })();

    const dataTiktokUsername = (() => {
        const hasUsername = !!state?.currentUserDetails?.tiktok_username;

        if (!hasUsername) {
            return 'Tiktok Username N/A';
        } else {
            return ('@' + state?.currentUserDetails?.tiktok_username);
        }
    })();

    const dataInstagramLink = (() => {
        const hasHandle = !!state?.currentUserDetails?.social_handle;

        if (!hasHandle) {
            return "https://www.instagram.com/";
        } else {
            const handle = state.currentUserDetails.social_handle.replace('@', '');
            return ("https://www.instagram.com/" + handle + "/");
        }
    })();

    const dataTiktokLink = (() => {
        const hasUsername = !!state?.currentUserDetails?.tiktok_username;

        if (!hasUsername) {
            return "https://www.tiktok.com/";
        } else {
            const username = state.currentUserDetails.tiktok_username.replace('@', '');
            return ("https://www.tiktok.com/@" + username + "/");
        }
    })();

    const dataTags = (() => {
        const hasTaggable = !!state?.currentUserDetails?.taggable;

        if (!!state?.currentUserDetails?.taggable.length) {
            return (
                <div className="tag-view-wrapper">
                    {_.map(state?.currentUserDetails?.taggable, (tag) => {
                        return (<span><span className="tag-pill">{tag.tag}</span>{' '}</span>);
                    })}
                </div>
            );
        } else {
            return null;
        }
    })();

    const dataInstagramFollowerCount = (() => {
        const hasFollowerCount = !!state?.currentUserDetails?.follower_count;
        const followerCount = state?.currentUserDetails?.follower_count;

        if (!hasFollowerCount) {
            return 'N/A';
        } else {
            if (followerCount > 999) {
                // Puts K for thousands, M for millions, etc.
                return numberFormatter(followerCount, 1);
            } else {
                return followerCount;
            }
        }
    })();

    const dataTiktokFollowerCount = (() => {
        const hasFollowerCount = !!state?.currentUserDetails?.tiktok_follower_count;
        const followerCount = state?.currentUserDetails?.tiktok_follower_count;

        if (!hasFollowerCount) {
            return 'N/A';
        } else {
            if (followerCount > 999) {
                // Puts K for thousands, M for millions, etc.
                return numberFormatter(followerCount, 1);
            } else {
                return followerCount;
            }
        }
    })();
    
    const dataInterestedNetworks = (() => {
        const hasInterestedNetworks = !_.isEmpty(state?.currentUserDetails?.interested_networks);
        const interestedNetworks = state?.currentUserDetails?.interested_networks;
        
        if (!hasInterestedNetworks) {
            return 'N/A';
        } else {
            if (_.includes(interestedNetworks,"INSTAGRAM") && _.includes(interestedNetworks,"TIKTOK")) {
                return (<><i className="fa-brands fa-instagram"></i> | <i className="fa-brands fa-tiktok"></i></>)
            } else if (_.includes(interestedNetworks,"INSTAGRAM")) {
                return (<i className="fa-brands fa-instagram"></i>);
            } else if (_.includes(interestedNetworks,"TIKTOK")) {
                return (<i className="fa-brands fa-tiktok"></i>);
            } else {
                return 'N/A';
            }
        }
    })();

    const instagramPostGridMessage = (() => {
        if (!state.ready || state?.currentUserDetails === null) return null;

        const hasInterestedNetworks = !_.isEmpty(state?.currentUserDetails?.interested_networks);
        const interestedNetworks = state?.currentUserDetails?.interested_networks;
        const creatorType = state?.currentUserDetails?.creator_type;

        if (!state?.currentUserDetails?.social_handle) {
            return (<span className="dp-post-grid-alert" title={"This user does not have an Instagram handle on their account."}>!</span>);
        } else {
            if (!_.includes(creatorType,"INSTAGRAM_INFLUENCER")) {
                return (<span className="dp-post-grid-alert" title={"This user's creator type does not include \"Instagram Influencer\". In order for this user to see Instagram post collabs, an Apex Admin will need to update their creator type to unclude \"Instagram Influencer\"."}>!</span>);
            }
            if (!_.includes(interestedNetworks,"INSTAGRAM")) {
                return (<span className="dp-post-grid-alert" title={"This user does not have Instagram listed under their interested networks."}>!</span>);
            }
        }
    })();

    const tiktokPostGridMessage = (() => {
        if (!state.ready || state?.currentUserDetails === null) return null;

        const hasInterestedNetworks = !_.isEmpty(state?.currentUserDetails?.interested_networks);
        const interestedNetworks = state?.currentUserDetails?.interested_networks;
        const creatorType = state?.currentUserDetails?.creator_type;

        if (!state?.currentUserDetails?.tiktok_username) {
            return (<span className="dp-post-grid-alert" title={"This user does not have an Tiktok username on their account."}>!</span>);
        } else {
            if ((!_.includes(interestedNetworks,"TIKTOK")) && (!_.includes(creatorType,"TIKTOK_INFLUENCER"))) {
                return (<span className="dp-post-grid-alert" title={"This user's creator type does not include \"Tiktok Influencer\" and they do not have Tiktok listed under their interested networks. There will be no posts here, and they will not be able to view or join Tiktok post collabs."}>!</span>);
            } else if (!_.includes(interestedNetworks,"TIKTOK")) {
                return (<span className="dp-post-grid-alert" title={"This user does not have Tiktok listed under their interested networks."}>!</span>);
            } else if (!_.includes(creatorType,"TIKTOK_INFLUENCER")) {
                return (<span className="dp-post-grid-alert" title={"This user's creator type does not include \"Tiktok Influencer\". In order for this user to see Tiktok post collabs, an Apex Admin will need to update their creator type to unclude \"Tiktok Influencer\"."}>!</span>);
            }
        }
    })();

    const userAlertBannerMessage = (() => {
        if (state?.currentUserDetails === null) return null;

        const {
            instagram_access_token,
            instagram_access_token_expires_at,
            social_posts,
            completed_at
        } = state?.currentUserDetails;

        const instagramIsLinked = (
            instagram_access_token !== null // has access token
            && instagram_access_token_expires_at !== null // has expiration date
            && moment(instagram_access_token_expires_at).isAfter(moment()) // expiration date is in the future
        );

        const instagramLinkIsExpired = (
            instagram_access_token_expires_at !== null // has expiration date
            && moment(instagram_access_token_expires_at).isBefore(moment()) // expiration date has passed
        );

        // Commented this part out because we no longer require insta linking
        /* if (!instagramIsLinked) {
            if (instagramLinkIsExpired) {
                return 'Instagram Account Link Has Expired';
            } else {
                return 'Instagram Account Not Linked';
            }
        } else */ if (_.isEmpty(social_posts) || social_posts === null) {
            return 'No Audience Data Available';
        } else if (completed_at === null) {
            return 'User Account Pending Completion';
        } else {
            return null;
        }
    })();

    const influencerTierClassInstagram = (() => {
        const hasFollowerCount = !!state?.currentUserDetails?.follower_count;
        const followerCount = state?.currentUserDetails?.follower_count;

        if (!hasFollowerCount) {
            return '';
        } else {
            if (false /*followerCount >= 100000*/) {
                return 'influencer-tier-4'; // Macro Influencer
            } else if (followerCount >= 3000) {
                return 'influencer-tier-3'; // Micro Influencer
            } else if (followerCount >= 1000){
                return 'influencer-tier-2'; // Nano Influencer
            } else {
                return 'influencer-tier-1'; // Sub-Nano Influencer
            }
        }
    })();

    const influencerTierNameInstagram = (() => {
        const hasFollowerCount = !!state?.currentUserDetails?.follower_count;
        const followerCount = state?.currentUserDetails?.follower_count;

        if (!hasFollowerCount) {
            return null;
        } else {
            if (false /*followerCount >= 100000*/) {
                return 'Macro'; // Macro Influencer
            } else if (followerCount >= 3000) {
                return 'Micro'; // Micro Influencer
            } else if (followerCount >= 1000){
                return 'Nano'; // Nano Influencer
            } else {
                return 'Sub-Nano'; // Sub-Nano Influencer
            }
        }
    })();

    const influencerTierClassTiktok = (() => {
        const hasFollowerCount = !!state?.currentUserDetails?.tiktok_follower_count;
        const followerCount = state?.currentUserDetails?.tiktok_follower_count;

        if (!hasFollowerCount) {
            return '';
        } else {
            if (false /*followerCount >= 100000*/) {
                return 'influencer-tier-4'; // Macro Influencer
            } else if (followerCount >= 3000) {
                return 'influencer-tier-3'; // Micro Influencer
            } else if (followerCount >= 1000){
                return 'influencer-tier-2'; // Nano Influencer
            } else {
                return 'influencer-tier-1'; // Sub-Nano Influencer
            }
        }
    })();

    const influencerTierNameTiktok = (() => {
        const hasFollowerCount = !!state?.currentUserDetails?.tiktok_follower_count;
        const followerCount = state?.currentUserDetails?.tiktok_follower_count;

        if (!hasFollowerCount) {
            return null;
        } else {
            if (false /*followerCount >= 100000*/) {
                return 'Macro'; // Macro Influencer
            } else if (followerCount >= 3000) {
                return 'Micro'; // Micro Influencer
            } else if (followerCount >= 1000){
                return 'Nano'; // Nano Influencer
            } else {
                return 'Sub-Nano'; // Sub-Nano Influencer
            }
        }
    })();

    /* Calculated Data - STOP */

    const component = (
        <div className="detail-pane-wrapper">
            <div
                className="close-detail-pane"
                onClick={(e) => {
                    e.preventDefault();
                    cancelRecursiveFetch();
                    window.closeDetailPane();
                }}
                title="Close Detail Pane"
            ></div>
            <div className="detail-pane">
                <div className="dp-top-header">
                    <div className="dp-top-header-left">
                        <button
                            className="exit-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                cancelRecursiveFetch();
                                window.closeDetailPane();
                            }}
                            title="Close Detail Pane"
                        ><img src={xIcon} alt='Close' /></button>

                        <button
                            className="prev-btn btn"
                            style={{ display: state.disablePrevBtn ? 'none' : 'block'}}
                            onClick={(e) => {
                                e.preventDefault();
                                cancelRecursiveFetch();
                                loadPreviousUser();
                            }}
                            title="Load Previous User"
                        ><img src={prevIcon} alt='Previous' />Prev</button>

                        <button
                            className="next-btn btn"
                            style={{ display: state.disableNextBtn ? 'none' : 'block'}}
                            onClick={(e) => {
                                e.preventDefault();
                                cancelRecursiveFetch();
                                loadNextUser();
                            }}
                            title="Load Next User"
                        >Next<img src={nextIcon} alt='Next' /></button>
                    </div>
                    <div className="dp-top-header-right">
                        <a
                            href={'/social-profiles/' + state.currentSocialId}
                            target="_blank"
                            className="profile-btn"
                            title="View User Profile"
                        ><img src={profileIcon} alt='Profile' /></a>

                        <a
                            href={'/social-profiles/' + state.currentSocialId + "/edit"}
                            target="_blank"
                            className="profile-btn"
                            title="Edit User"
                        ><img src={editIcon} alt='Edit' /></a>

                        <ContextMenu title="User Actions">
                            <div className="row-action-buttons-wrapper"></div>
                        </ContextMenu>
                    </div>
                </div>

                {userAlertBannerMessage !== null && (
                    <div className="dp-alert-banner">{userAlertBannerMessage}</div>
                )}
                
                <div className="dp-main-header">
                    <div className="dp-main-header-left">
                        <div className="dp-main-header-name">{dataName}</div>
                        {(!!state?.currentUserDetails?.social_handle) && (
                            <a href={dataInstagramLink} target="_blank" className="dp-main-header-handle" title="View User's Instagram">
                                {dataInstagramHandle + ' '}
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                        )}
                        
                        {(!!state?.currentUserDetails?.social_handle) && (!!state?.currentUserDetails?.tiktok_username) && (
                            <span> | </span>
                        )}
                            
                        {(!!state?.currentUserDetails?.tiktok_username) && (
                            <a href={dataTiktokLink} target="_blank" className="dp-main-header-handle" title="View User's Tiktok">
                                {dataTiktokUsername + ' '}
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                        )}
                        <div>
                            {(influencerTierNameInstagram !== null) && (
                                <div className={"influencer-tier-pill dp-influencer-tier-pill " + influencerTierClassInstagram}><i className="fa-brands fa-instagram"></i> {influencerTierNameInstagram}</div>
                            )}
                            {(influencerTierNameTiktok !== null) && (
                                <div className={"influencer-tier-pill dp-influencer-tier-pill " + influencerTierClassTiktok}><i className="fa-brands fa-tiktok"></i> {influencerTierNameTiktok}</div>
                            )}
                        </div>
                    </div>
                    <div className="dp-main-header-right">
                        <div className="dp-main-header-right-data">
                            {(!!state?.currentUserDetails?.social_handle) && (<div className="dp-main-header-right-value" title={state?.currentUserDetails?.follower_count || ''}><i className="fa-brands fa-instagram"></i> {dataInstagramFollowerCount}</div>)}
                            {(!!state?.currentUserDetails?.tiktok_username) && (<div className="dp-main-header-right-value" title={state?.currentUserDetails?.tiktok_follower_count || ''}><i className="fa-brands fa-tiktok"></i> {dataTiktokFollowerCount}</div>)}
                            <div className="dp-main-header-right-label">Followers</div>
                        </div>
                        <div className="dp-main-header-right-data">
                            {(!!state?.currentUserDetails?.social_handle) && (<div className="dp-main-header-right-value" title={(!!state?.currentUserDetails?.overallInstagramEngagement) ? (String(state?.currentUserDetails?.overallInstagramEngagement?.toFixed(5)) + '%') : 'N/A'}>
                                <i className="fa-brands fa-instagram"></i> {(!!state?.currentUserDetails?.overallInstagramEngagement) ?
                                    (String(state?.currentUserDetails?.overallInstagramEngagement.toFixed(1)) + '%')
                                    : 'N/A'
                                }
                            </div>)}
                            {(!!state?.currentUserDetails?.tiktok_username) && (<div className="dp-main-header-right-value" title={(!!state?.currentUserDetails?.overallTiktokEngagement) ? (String(state?.currentUserDetails?.overallTiktokEngagement?.toFixed(5)) + '%') : 'N/A'}>
                                <i className="fa-brands fa-tiktok"></i> {(!!state?.currentUserDetails?.overallTiktokEngagement) ?
                                    (String(state?.currentUserDetails?.overallTiktokEngagement.toFixed(1)) + '%')
                                    : 'N/A'
                                }
                            </div>)}
                            <div className="dp-main-header-right-label">OVR. ENG.</div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="dp-user-info">
                    
                    {dataTags}
                    
                    <div className="dp-user-info-single">
                        <div className="dp-user-info-label">Email Address</div>

                        {(!!state?.currentUserDetails?.user?.email) && (
                            <a
                                href={'mailto:' + state?.currentUserDetails?.user?.email}
                                className="dp-user-info-data"
                            >{state?.currentUserDetails?.user?.email}</a>
                        )}

                        {(!state?.currentUserDetails?.user?.email) && (
                            <div className="dp-user-info-data">{'N/A'}</div>
                        )}
                    </div>

                    <div className="dp-user-info-single">
                        <div className="dp-user-info-label">Mobile Phone</div>
                        <div className="dp-user-info-data">{state?.currentUserDetails?.user?.phone || 'N/A'}</div>
                    </div>

                    <div className="dp-user-info-single">
                        <div className="dp-user-info-label">Birthdate</div>
                        <div className="dp-user-info-data">{state?.currentUserDetails?.user?.birth_date || 'N/A'}</div>
                    </div>

                    <div className="dp-user-info-single">
                        <div className="dp-user-info-label">Primary Blog Theme</div>
                        <div className="dp-user-info-data">{state?.currentUserDetails?.primary_social_profile_theme?.name || 'N/A'}</div>
                    </div>

                    <div className="dp-user-info-single">
                        <div className="dp-user-info-label">Networks Interested In</div>
                        <div className="dp-user-info-data">{dataInterestedNetworks}</div>
                    </div>
                </div>

                <div className="dp-buttons">
                    <div className="row-action-buttons-wrapper"></div>
                </div>

                {(!!state.ready) && (!!state?.currentUserDetails?.user) && (
                    <div className="dp-note">
                        <AccountNote
                            currentNote={state?.currentUserDetails?.user?.account_note}
                            onSubmit={async (note) => {
                                // Load JSON data
                                jsonData = getJSONData();
                                const { base_url } = jsonData;
                                const socialProfile = state.currentSocialId;

                                if (!!socialProfile) {
                                    return await axios.post(`${base_url}/ajax/notes/socialProfile/${socialProfile}`, {
                                        input: note,
                                        crossDomain: true,
                                        timeout: isDev() ? 0 : 30000,
                                    });
                                }
                            }}
                        />
                    </div>
                )}

                <Tabs className="dp-post-tabs" selectedIndex={state.activeTab}>
                    {/* OUTER TABS FOR NETWORKS */}
                    <TabList>
                        {(!!state?.currentUserDetails?.social_handle) && (
                            <Tab
                                onClick={async (e) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'HYDRATE',
                                        payload: {
                                            activeTab: 0,
                                        }
                                    });
                                }}
                            ><i className="fa-brands fa-instagram"></i> Recent {instagramPostGridMessage !== null && (instagramPostGridMessage)}</Tab>
                        )}

                        {(!!state?.currentUserDetails?.social_handle) && (
                            <Tab
                                onClick={async (e) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'HYDRATE',
                                        payload: {
                                            activeTab: 1,
                                        }
                                    });
                                }}
                            ><i className="fa-brands fa-instagram"></i> Top {instagramPostGridMessage !== null && (instagramPostGridMessage)}</Tab>
                        )}


                        {(!!state?.currentUserDetails?.tiktok_username) && (
                            <Tab
                                onClick={async (e) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'HYDRATE',
                                        payload: {
                                            activeTab: (!!state?.currentUserDetails?.social_handle) ? 2 : 0,
                                        }
                                    });
                                }}
                            ><i className="fa-brands fa-tiktok"></i> Recent {tiktokPostGridMessage !== null && (tiktokPostGridMessage)}</Tab>
                        )}
                        
                        {(!!state?.currentUserDetails?.tiktok_username) && (
                            <Tab
                                onClick={async (e) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: 'HYDRATE',
                                        payload: {
                                            activeTab: (!!state?.currentUserDetails?.social_handle) ? 3 : 1,
                                        }
                                    });
                                }}
                            ><i className="fa-brands fa-tiktok"></i> Top {tiktokPostGridMessage !== null && (tiktokPostGridMessage)}</Tab>
                        )}
                    </TabList>

                    {/* INSTAGRAM RECENT POSTS */}
                    {(!!state?.currentUserDetails?.social_handle) && (
                        <TabPanel>
                            <div className="dp-post-grid">
                                <InstagramPostsGrid
                                    postsData={state?.currentUserDetails?.recentInstagramPosts}
                                    userData={{
                                        dataName: dataName,
                                        dataSocialLink: dataInstagramLink,
                                        dataSocialHandle: dataInstagramHandle,
                                        dataFollowerCount: dataInstagramFollowerCount,
                                        overallEngagement: state?.currentUserDetails?.overallInstagramEngagement,
                                        engagementBoundaries: state?.currentUserDetails?.engagementBoundaries,
                                    }}
                                />
                            </div>
    
                            {(_.isEmpty(state?.currentUserDetails?.recentInstagramPosts)) && (
                                <div className="dp-post-grid-message">There are no recent Instagram posts for this user at this time.</div>
                            )}
    
                            <GridLoader color="#48baa8" loading={state.loadingRecentInstagramPosts} css="display: block; margin: 0 auto;" size={25} />
    
                            {(_.isEmpty(state?.currentUserDetails?.recentInstagramPosts)) && (!state.loadingRecentInstagramPosts) && (
                                <button
                                    className="scrape-recent-posts-btn btn"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await scrapeRecentInstagramPosts(state.currentSocialId, 0);
                                    }}
                                    title="Get Recent Posts"
                                >Get Recent Posts</button>
                            )}

                            {/*{(!_.isEmpty(state?.currentUserDetails?.recentInstagramPosts)) && (!state.loadingRecentInstagramPosts) && (
                                <button
                                    className="scrape-recent-posts-btn btn"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await scrapeRecentInstagramPosts(state.currentSocialId, 1);
                                    }}
                                    title="Get More Posts"
                                >Get More Posts</button>
                            )}*/}
                        </TabPanel>
                    )}
                    
                    
                    {/* INSTAGRAM TOP POSTS */}
                    {(!!state?.currentUserDetails?.social_handle) && (
                        <TabPanel>
                            <div className="dp-post-grid">
                                <InstagramPostsGrid
                                    postsData={state?.currentUserDetails?.topInstagramPosts}
                                    userData={{
                                        dataName: dataName,
                                        dataSocialLink: dataInstagramLink,
                                        dataSocialHandle: dataInstagramHandle,
                                        dataFollowerCount: dataInstagramFollowerCount,
                                        overallEngagement: state?.currentUserDetails?.overallInstagramEngagement,
                                        engagementBoundaries: state?.currentUserDetails?.engagementBoundaries,
                                    }}
                                />
                            </div>
    
                            {(_.isEmpty(state?.currentUserDetails?.topInstagramPosts)) && (
                                <div className="dp-post-grid-message">There are no top Instagram posts for this user at this time.</div>
                            )}
                        </TabPanel>
                    )}
                        
                    {/* TIKTOK RECENT POSTS */}
                    {(!!state?.currentUserDetails?.tiktok_username) && (
                        <TabPanel>
                            <div className="dp-post-grid">
                                <InstagramPostsGrid
                                    postsData={state?.currentUserDetails?.recentTiktokPosts}
                                    userData={{
                                        dataName: dataName,
                                        dataSocialLink: dataTiktokLink,
                                        dataSocialHandle: dataTiktokUsername,
                                        dataFollowerCount: dataTiktokFollowerCount,
                                        overallEngagement: state?.currentUserDetails?.overallTiktokEngagement,
                                        engagementBoundaries: state?.currentUserDetails?.engagementBoundaries,
                                    }}
                                />
                            </div>
                            
                            {(_.isEmpty(state?.currentUserDetails?.recentTiktokPosts)) && (
                                <div className="dp-post-grid-message">There are no recent Tiktok posts for this user at this time.</div>
                            )}
    
                            <GridLoader color="#48baa8" loading={state.loadingRecentTiktokPosts} css="display: block; margin: 0 auto;" size={25} />
    
                            {(_.isEmpty(state?.currentUserDetails?.recentTiktokPosts)) && (!state.loadingRecentTiktokPosts) && (
                                <button
                                    className="scrape-recent-posts-btn btn"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await scrapeRecentTiktokPosts(state.currentSocialId, 0);
                                    }}
                                    title="Get Recent Posts"
                                >Get Recent Posts</button>
                            )}

                            { /* {(!_.isEmpty(state?.currentUserDetails?.loadingRecentTiktokPosts)) && (!state.loadingRecentTiktokPosts) && (
                                <button
                                    className="scrape-recent-posts-btn btn"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await scrapeRecentTiktokPosts(state.currentSocialId, 1);
                                    }}
                                    title="Get More Posts"
                                >Get More Posts</button>
                            )}*/}
                        </TabPanel>
                    )}
                    
                    {/* TIKTOK TOP POSTS */}
                    {(!!state?.currentUserDetails?.tiktok_username) && (
                        <TabPanel>
                            <div className="dp-post-grid">
                                <InstagramPostsGrid
                                    postsData={state?.currentUserDetails?.topTiktokPosts}
                                    userData={{
                                        dataName: dataName,
                                        dataSocialLink: dataTiktokLink,
                                        dataSocialHandle: dataTiktokUsername,
                                        dataFollowerCount: dataTiktokFollowerCount,
                                        overallEngagement: state?.currentUserDetails?.overallTiktokEngagement,
                                        engagementBoundaries: state?.currentUserDetails?.engagementBoundaries,
                                    }}
                                />
                            </div>
                            
                            {(_.isEmpty(state?.currentUserDetails?.topTiktokPosts)) && (
                                <div className="dp-post-grid-message">There are no top Tiktok posts for this user at this time.</div>
                            )}
                        </TabPanel>
                    )}
                </Tabs>

                <UIBlocker block={!state.ready} text={state.uiBlockerText} />
            </div>
        </div>
    );

    if (!detailPaneMount) {
        return null;
    }

    return ReactDOM.createPortal(
        component,
        detailPaneMount
    );
}

export default DetailPane;
