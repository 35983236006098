import React, { useState, useCallback } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components as defaultComponents } from 'react-select';
import _ from 'lodash';
import trashCanIcon from '../../images/trash.svg';
import { actions as a, selectors as s } from './reducer';
import { makeOptionValue } from './utils';
import RenameOptionValueModal from './RenameOptionValueModal';

const MultiValueLabel = props => {
  return (
    <defaultComponents.MultiValueLabel
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: () => {
          !!props.selectProps.onValueClick && props.selectProps.onValueClick(props.data);
        }
      }}
    />
  );
};

const components = {
  DropdownIndicator: null,
  MultiValueLabel
};

const createSelectOption = (optionValue) => ({
  label: optionValue.name,
  value: optionValue.clientId
});

const selectValueToStateOptionValue = (selectValue) => makeOptionValue({
  clientId: selectValue.value,
  name: selectValue.label
});

const isDuplicateValue = (inputValue, values) => {
  return values.filter(v => v.name.toLowerCase() === inputValue.toLowerCase()).length > 0;
};

function OptionRow({ state, option }) {
  const { clientId, name, values } = option;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (values, actionMeta) => {
    values = _.toArray(values);
    if (actionMeta.action === 'remove-value') {
      const numAffectedVariants = s.variantsWithOptionValue(state, clientId, actionMeta.removedValue.value).length;

      if (
        numAffectedVariants > 0 &&
        !window.confirm(`${numAffectedVariants} Variants use this option value. If you delete this option, those Variants will be removed. Are you sure?`)
      ) {
        return;
      }

      a.setOptionValues(clientId, values.map(v => selectValueToStateOptionValue(v)), actionMeta.removedValue.value);
    }
    else {
      // a.setOptionValues(clientId, values.map(v => {
      //   const optionValueClientId = v.value;
      //
      //   return optionValueClientId;
      // }));
    }
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    if (isDuplicateValue(inputValue, values)) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        a.setOptionValues(clientId, [...values, makeOptionValue({ name: inputValue })]);
        event.preventDefault();
        break;
      default:
    }
  };

  const handleOptionRemove = useCallback((e) => {
    e.preventDefault();
    const numAffectedVariants = state.variants.length;

    if (
      numAffectedVariants > 0 &&
      window.confirm(`This option will be removed from all Variants. Are you sure?`)
    ) {
      a.removeOption(clientId);
    }
  }, [state.variants, name]);

  return (
    <div className="product-option-row">

      <div className="option-name">
        <input
          type="text"
          name=""
          value={name || ''}
          onChange={e => a.setOptionName(clientId, e.target.value)}
          autoFocus
          className="text-input"
          placeholder="Type an option name..."
        />
      </div>

      <div className="option-values">
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={v => setInputValue(v)}
          onKeyDown={handleKeyDown}
          onValueClick={({ value: optionValueClientId }) => {
            a.hydrate({
              renameOptionValueModalOpen: true,
              renameOptionValue: _.find(values, { clientId: optionValueClientId })
            });
          }}
          placeholder="Type something and press enter..."
          value={values.map(ov => createSelectOption(ov))}
          className='raleway-semi-bold raleway-12 react-select-container'
        />
      </div>

      <div className="option-delete">
        <button
          className="btn btn-icon delete-option"
          onClick={e => {
            e.preventDefault();
            handleOptionRemove(e);
          }}
        >
          <img src={trashCanIcon} alt="Delete" />
        </button>
      </div>

      <RenameOptionValueModal
        option={option}
        optionValue={state.renameOptionValue}
        isOpen={state.renameOptionValueModalOpen}
        onCancel={() => {
          a.hydrate({
            renameOptionValueModalOpen: false,
            renameOptionValue: null
          });
        }}
        onConfirm={name => {
          a.renameOptionValue(option.clientId, state.renameOptionValue.clientId, name);
          a.hydrate({
            renameOptionValueModalOpen: false,
            renameOptionValue: null
          });
        }}
      />

    </div>
  );
}

export default OptionRow;
