export default {
  ajax_url: 'http://app.test/ajax/exports',
  example_download_url: 'http://app.test/exports/example',
  context: 'social_profiles.all',
  data_source: {
    type: 'ui_segment',
    context: 'social_profiles.all'
  },
  available_exports: [
    {
      name: 'People Map (In Review)',
      type: 'people_map_in_review',
      example_download_url: null,
      columns: [
        {
          slug: 'id',
          name: 'ID',
          sensitive: false,
          selected: true,
          required: true
        },
        {
          slug: 'first_name',
          name: 'First Name',
          sensitive: false,
          selected: true,
          required: false
        },
        {
          slug: 'last_name',
          name: 'Last Name',
          sensitive: false,
          selected: true,
          required: false
        },
        {
          slug: 'social_handle',
          name: 'Handle',
          sensitive: false,
          selected: true,
          required: false
        },
        {
          slug: 'email',
          name: 'Email',
          sensitive: true,
          selected: false,
          required: false
        }
      ],
      option_fields: [
        {
          slug: 'first_row_headings',
          name: 'First Row Headings',
          type: 'boolean',
          default_value: true
        },
        {
          slug: 'only_export_empty_review_statuses',
          name: 'Only Export Empty Review Statuses',
          type: 'boolean',
          default_value: true
        }
      ]
    }
  ],
};
