import React, { useState, useEffect, useCallback } from 'react';
import { actions as a } from './reducer';
import _ from 'lodash';
import Select from 'react-select';
import Checkbox from '../checkbox/Checkbox.js';

const createSelectOption = (option, optionValue) => {
  if (!optionValue) return null;

  return {
    label: optionValue.name,
    value: optionValue.clientId,
  }
};

function VariantsSelectBar({ state }) {
  const [selections, setSelections] = useState({});

  useEffect(() => {
    if (!state.optionSelectionActive && _.keys(selections).length > 0) {
      setSelections({});
    }
  }, [state.selectedVariants]);

  const onBulkDelete = useCallback((e) => {
    e.preventDefault();

    if (!window.confirm(`Are you sure you want to delete the ${state.selectedVariants.length} selected variants?`)) {
      return;
    }

    a.deleteSelectedVariants();
  }, [state.selectedVariants]);

  const handleSelectionChange = useCallback((optionClientId, optionValueClientId) => {
    const newSelections = { ...selections };

    if (!optionValueClientId) {
      delete newSelections[optionClientId];
    } else {
      newSelections[optionClientId] = optionValueClientId;
    }

    setSelections(newSelections);

    a.selectAllWithOptionValues(newSelections);
  }, [selections]);

  if (state.variants.length === 0) return null;

  return (
    <div className="bulk-select-bar">

      <span className="select-all">
        <Checkbox
          checked={state.selectedVariants.length === state.variants.length}
          onChange={() => {
            a.toggleSelectAllVariants();
          }}
        />
      </span>

      <span className="raleway-semi-bold raleway-12 select-label select-label">
        Select
      </span>

      <div className="options">
        {state.options.map(o => {
          return (
            <Select
              key={o.clientId}
              className="raleway-semi-bold raleway-12 react-select-container"
              options={o.values.map(ov => createSelectOption(o, ov))}
              onChange={selectedOptionValue => handleSelectionChange(o.clientId, !!selectedOptionValue ? selectedOptionValue.value : null)}
              value={createSelectOption(o, _.find(o.values, { clientId: selections[o.clientId] }))}
              placeholder={`By ${o.name}...`}
              isClearable
            />
          );
        })}
      </div>

      <div className="buttons">
        {state.selectedVariants.length > 0 && (
          <div className="inner">
            <button
              className="btn btn-green bulk-set-inventory"
              onClick={(e) => {
                e.preventDefault();
                a.hydrate({
                  bulkSetInventoryModalOpen: true
                });
              }}
            >
              Set Inventory
            </button>

            <button
              className="btn btn-green bulk-set-price"
              onClick={e => {
                e.preventDefault();
                a.hydrate({
                  bulkSetPriceModalOpen: true
                });
              }}
            >
              Set Price
            </button>

            <button
              className="btn btn-black delete-selected-variants"
              onClick={(e) => {
                e.preventDefault();
                onBulkDelete(e);
              }}
            >
              Delete
            </button>

            <button
              className="btn btn-white clear-selected-variants"
              onClick={e => {
                e.preventDefault();
                a.toggleSelectAllVariants(false);
              }}
            >
              Clear
            </button>
          </div>
        )}
      </div>

    </div>
  );
}

export default VariantsSelectBar;
