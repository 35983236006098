import _ from 'lodash';
import $ from 'jquery';
import { isDev, getJSONData as _getJSONData, setJSONData as _setJSONData } from '../../utils';
import uuid from 'uuid/v4';

export const defaultJsonData = {
  base_url: 'http://app.test',
  context: 'global',
  loadedSegmentationId: null,
  operator: 'AND',
  activeFilters: [],
  segmentation_filters: [],
  named_segmentation_context: null,
};

export const operatorsMap = {
  equal_to: { name: 'Equal to' },
  not_equal_to: { name: 'Not Equal to' },
  str_contains: { name: 'Contains' },
  str_not_contains: { name: 'Does Not Contain' },
  in: { name: 'One Of' },
  not_in: { name: 'Not One Of' },
  greater_than: { name: 'Greater Than' },
  less_than: { name: 'Less Than '},
  greater_than_or_equal_to: { name: 'Greater Than or Equal To' },
  less_than_or_equal_to: { name: 'Less Than or Equal To' },
  boolean_in: { name: 'One Of' },
  boolean_not_in: { name: 'Not One Of' },
  boolean_datetime_in: { name: 'One Of' },
  boolean_datetime_not_in: { name: 'Not One Of' },
  datetime_equal_to: { name: 'Equal to' },
  datetime_not_equal_to: { name: 'Not Equal to' },
  datetime_greater_than: { name: 'Greater Than' },
  datetime_less_than: { name: 'Less Than '},
  datetime_greater_than_or_equal_to: { name: 'Greater Than or Equal To' },
  datetime_less_than_or_equal_to: { name: 'Less Than or Equal To' },
  years_passed_greater_than: { name: 'Greater Than' },
  years_passed_less_than: { name: 'Less Than '},
  years_passed_greater_than_or_equal_to: { name: 'Greater Than or Equal To' },
  years_passed_less_than_or_equal_to: { name: 'Less Than or Equal To' },
  months_passed_greater_than: { name: 'Greater Than' },
  months_passed_less_than: { name: 'Less Than '},
  months_passed_greater_than_or_equal_to: { name: 'Greater Than or Equal To' },
  months_passed_less_than_or_equal_to: { name: 'Less Than or Equal To' },
  has_any: { name: 'Has Any of' },
  has_all: { name: 'Has All of' },
  does_not: { name: 'Does Not Have'},
};

export const makeFilter = (filterType, availableFilters, values = {}) => {
  const filterProto = _.find(availableFilters, { filter: filterType });

  if (!filterProto) return null;

  const filterObj = {
    client_id: uuid(),
    operators: [],
    ...filterProto,
    ..._.pick(values, ['client_id', 'filter', 'sortOrder', 'operator', 'value']),
    optionsDataLoaded: false,
    optionsData: [],
    operator: values.operator && filterProto.operators.includes(values.operator) ?
      values.operator :
      _.get(filterProto.operators, '0')
  };

  return filterObj;
}

export const optionsToSelectOptions = (options) => options.map(option => ({
    label: option.name,
    value: option._id
}));

export const selectedToOptionValue = (selectedOptions) => {
  if (_.isArray(selectedOptions)) {
    // Multi-Select
    return selectedOptions.map(selectedOption => selectedOption.value);
  } else {
    // Single Select
    return _.get(selectedOptions, 'value');
  }
}

export const filterValueToSelectValue = (filterOptions, value, selectType) => {
  if (value === null) {
    return selectType === 'select_multiple' ? [] : '';
  }

  const selectOptions = optionsToSelectOptions(filterOptions);

  if (_.isArray(value)) {
    // Multi-Select
    return selectOptions.filter(selectOption => value.indexOf(selectOption.value) !== -1);
  } else {
    // Single Select
    return _.find(selectOptions, { value });
  }
}

export const getJSONData = () => {
  const defaultData = { ...defaultJsonData };

  if (isDev()) {
    defaultData.base_url = 'http://app.test';
    defaultData.context = 'social_profiles.master_list';
    defaultData.segmentation_filters = ['social_profiles.*'];
    defaultData.named_segmentation_context = null;
  }

  return {
    ...defaultData,
    ..._getJSONData('input[name="segmentation_filter"]'),
  };
}

export const setJSONData = (value) => {
  _setJSONData('input[name="segmentation_filter"]', value);
}

export const submitForm = () => {
  if (isDev()) {
    console.log('Submitted!', document.querySelector('input[name="segmentation_filter"]').value)
  } else {
    const $form = $('input[name="segmentation_filter"]').parents('form');
    $form.submit();
  }
}
