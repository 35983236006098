import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOuterClick } from 'react-outer-click';
import { isDev, getJSONData } from '../../utils';
import axios from 'axios';
import $ from 'jquery';
import fakeJSONData from './fake-json-data';
import jobsRunning from '../../images/jobs-running.png';
import jobsComplete from '../../images/jobs-complete.png';
import Job from './Job';
import moment from 'moment-timezone';
import _ from 'lodash';

moment.tz.setDefault('UTC');

const jobsIndicatorMount = document.getElementById('jobs_indicator_mount');

const defaultOpenDropdown = false;

const defaultJsonData = {
  recent_imports: [],
  recent_exports: [],
};

let jsonData;
let pollTimer = null;
let abortAutoRefresh = false;

function JobsIndicator({ lastExportId }) {
  const indicatorEl = useRef(null);
  const dropdownEl = useRef(null);
  const [recentImports, setRecentImports] = useState([]);
  const [recentExports, setRecentExports] = useState([]);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(defaultOpenDropdown && isDev());
  const [polling, setPolling] = useState(false);

  useEffect(() => {
    if (!!jobsIndicatorMount) {
      initialize();
      setTimeout(() => {
        pollJobs();
      }, 5000);
    }
  }, []);

  useOuterClick([indicatorEl, dropdownEl], () => {
    setDropdownIsOpen(false)
  });

  const utils = {
    isRunning: (status) => {
      const runningStatuses = ['DRAFT', 'VALIDATING', 'PROCESSING'];
      return runningStatuses.indexOf(status) !== -1;
    },
    isComplete: (status) => {
      const completeStatuses = ['COMPLETE', 'CANCELLED'];
      return completeStatuses.indexOf(status) !== -1;
    },
    isCancelled: (status) => {
      const cancelledStatuses = ['CANCELLED'];
      return cancelledStatuses.indexOf(status) !== -1;
    },
    isFailed: (status) => {
      const failedStatuses = ['FAILED'];
      return failedStatuses.indexOf(status) !== -1;
    }
  };

  const jobs = (recentImports, recentExports) => {
    const importJobs = recentImports.map(i => ({ ...i, type: 'IMPORT' }));
    const exportJobs = recentExports.map(e => ({ ...e, type: 'EXPORT' }));

    return importJobs.concat(exportJobs);
  };

  const fetchRecentJobs = async () => {
    if (isDev()) {
      await (new Promise((resolve) => { setTimeout(resolve, 1500); }));
      setRecentImports(fakeJSONData.recent_imports);
      setRecentExports(fakeJSONData.recent_exports);

      return fakeJSONData;
    }

    const resp = await axios({
      method: 'GET',
      // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
      url: jsonData.ajax_url,
      crossDomain: true,
      params: {},
      timeout: isDev() ? 0 : 30000
    })
    .then(response => {
      // handle success

      setRecentImports(response.data.recent_imports);
      setRecentExports(response.data.recent_exports);

      return response.data;
    })
    .catch(error => {
      // handle error
      console.log(error);
    });

    return resp ? resp : { recent_imports: [], recent_exports: [] };
  };

  const handleAutoRefresh = (jobs) => {
    if (abortAutoRefresh) return false;

    const autoRefreshThreshold = 10; // s
    const finishedRecently = jobs.filter(job => {
      const isRecent = moment(job.updated_at).add(autoRefreshThreshold, 'seconds').isSameOrAfter(moment());
      return !utils.isRunning(job.status) && isRecent;
    });

    const isOnRelevantScreen = finishedRecently.filter(job => {
      const onScreenImporters = _.filter(_.get(window, 'imports.available_imports', []), { type: job.import_type });
      return onScreenImporters.length > 0;
    }).length > 0;

    if (isOnRelevantScreen) {
      const willRefresh = window.confirm('Your import is finished. Do you want to refresh the page to see the results?');
      if (willRefresh) {
        abortAutoRefresh = false;
        window.location.reload();
      } else {
        abortAutoRefresh = true;
      }

      return willRefresh;
    }

    return false;
  };

  const pollJobs = async () => {
    if (polling) return;

    clearTimeout(pollTimer);

    setPolling(true);

    const resp = await fetchRecentJobs();

    setPolling(false);

    const willAutoRefresh = handleAutoRefresh(jobs(resp.recent_imports, resp.recent_exports));

    if (willAutoRefresh) return;

    const running = jobs(resp.recent_imports, resp.recent_exports).filter(job => {
      return utils.isRunning(job.status);
    });
    const anyRunning = running.length > 0;

    const anyMissingDownloadButtons = running.filter(job => {
      return (job.type === 'EXPORT' && utils.isComplete(job.status) && !utils.isCancelled(job.status) && !job.download_url);
    }).length > 0;

    // Keep polling if there are running jobs or ones where download button still hasn't been generated
    if (anyRunning || anyMissingDownloadButtons) {
      pollTimer = setTimeout(() => {
        pollJobs();
      }, 2500);
    }
  };

  const initialize = () => {
    jsonData = { ...defaultJsonData, ...getJSONData('#jobs_json_data', isDev() ? fakeJSONData : null) };

    $('body').on('import::started', function() {
      pollJobs();
    });

    $('body').on('export::started', function() {
      pollJobs();
    });
  }

  const makeIndicator = (recentImports, recentExports) => {
    const statusCounts = jobs(recentImports, recentExports)
      .reduce((statusCounts, item) => {
        if (utils.isRunning(item.status)) {
          statusCounts.running++;
        } else if (utils.isComplete(item.status)) {
          statusCounts.complete++;
        } else if (utils.isFailed(item.status)) {
          statusCounts.failed++;
        }

        return statusCounts;
      }, { running: 0, complete: 0, failed: 0 });

    const statusClass = !!statusCounts.running ? 'running' :
      !!statusCounts.failed ? 'failed' :
      'complete';

    return (
      <div
        className={`indicator noselect ${statusClass}`}
        onClick={() => {
          setDropdownIsOpen(!dropdownIsOpen)
        }}
        ref={indicatorEl}
        key="indicator"
      >
        <img
          src={statusCounts.running === 0 && statusCounts.failed === 0 ? jobsComplete : jobsRunning}
          alt="jobs-indicator"
        />
        {statusCounts.running > 0 && (
          <span className="montserrat-medium montserrat-12 jobs-count-badge">
            {statusCounts.running}
          </span>
        )}
        {statusCounts.running === 0 && statusCounts.failed > 0 && (
          <span className="montserrat-medium montserrat-12 jobs-failed-badge">
            !
          </span>
        )}
      </div>
    );
  };

  const recentJobs = jobs(recentImports, recentExports);
  const recentImportJobs = recentJobs.filter(j => j.type === 'IMPORT');
  const recentExportJobs = recentJobs.filter(j => j.type === 'EXPORT');

  const component = (
    <div
      className="JobsIndicator"
    >
      {makeIndicator(recentImports, recentExports)}
      {dropdownIsOpen && (
        <div
          className="dropdown"
          ref={dropdownEl}
        >
          <button
            className="raleway-semi-bold raleway-12 refresh"
            onClick={(e) => {
              e.preventDefault();
              pollJobs();
            }}
            disabled={polling}
          >
            Refresh
          </button>
          {recentImportJobs.length > 0 && (
            <div className="imports" key="imports">
              <h3 className="raleway-semi-bold raleway-12">Imports</h3>
              <table>
                <tbody>
                  {recentImportJobs.map(job => (
                    <Job job={job} utils={utils} key={job.name} />
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {recentImportJobs.length > 0 && recentExportJobs.length > 0 && (
            <div className="separator" key="separator" />
          )}
          {recentExportJobs.length > 0 && (
            <div className="exports" key="exports">
              <h3 className="raleway-semi-bold raleway-12">Exports</h3>
              <table>
                <tbody>
                  {recentExportJobs.map(job => (
                    <Job job={job} utils={utils} key={job.name} />
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {recentJobs.length === 0 && (
            <span className="montserrat-medium montserrat-12 no-jobs-message" key="no-jobs">
              There are no recent imports/exports
            </span>
          )}
        </div>
      )}
    </div>
  );

  if (!jobsIndicatorMount) {
    return null;
  }

  return ReactDOM.createPortal(
    component,
    jobsIndicatorMount,
  );
}

JobsIndicator.defaultProps = {
  lastExportId: null
};

export default JobsIndicator;
