import React, { useReducer, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import axios from 'axios';
import { reducer, initialState } from './reducer';
import { isDev } from '../../utils';
import { getJSONData, setJSONData, submitForm } from './utils';
import AddFilter from './AddFilter';
import FilterRow from './FilterRow';
import FiltersOperator from './FiltersOperator';
import LoadSegmentation from './LoadSegmentation';
import UIBlocker from '../UIBlocker';

const segmentationFilterMount = document.getElementById('segmentation_filter_mount');

let jsonData = {};

function SegmentationFilter() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const unsavedRef = useRef(state.unsaved);
  const activeFiltersRef = useRef(state.activeFilters);
  const operatorRef = useRef(state.operator);

  // Disabled this because it seemed unnecessary to initialize it if your
  // not necessarily going to ope nthe segment filter.
  // useEffect(() => {
  //   if (!!segmentationFilterMount) {
  //     initialize();
  //   }
  // }, []);

  //
  // In-Memory Refs for immediate state updates
  //
  // NOTE: These don't work as expected sometimes
  //
  //
  useEffect(() => {
    unsavedRef.current = state.unsaved;
  }, [state.unsaved]);

  useEffect(() => {
    activeFiltersRef.current = state.activeFilters;
  }, [state.activeFilters]);

  useEffect(() => {
    operatorRef.current = state.operator;
  }, [state.operator]);

  // Load options data
  useEffect(() => {
    if (!state.loadedSegmentationId) {
      fetchUnloadedOptions();
    }
  }, [state.ready]);

  useEffect(() => {
    fetchUnloadedOptions();
  }, [state.loadedSegmentationId]);

  //
  // API
  //
  useEffect(() => {
    window.openSegmentationFilter = () => {

      initialize();
      dispatch({
        type: 'SET_OPEN',
        payload: true
      });
    }

    window.closeSegmentationFilter = () => {
      dispatch({
        type: 'HYDRATE',
        payload: {
          ready: false,
          open: false
        }
      });
    }

    const openButton = document.querySelector('#open_segmentation_filter');
    if (openButton) {
      openButton.addEventListener('click', function(e) {
        e.preventDefault();
        window.openSegmentationFilter();
      })
    }

    if (isDev()) {
      // window.openSegmentationFilter();
    }
  }, []);


  //
  // Functions
  //

  const initialize = () => {
    // Load JSON data
    jsonData = getJSONData();

    dispatch({
      type: 'HYDRATE',
      payload: {
        ..._.omit(jsonData, ['activeFilters', 'loadedSegmentationId']),
      }
    });

    const { context, segmentation_filters, named_segmentation_context, base_url } = jsonData;

    // Load server data
    axios.get(`${base_url}/ajax/data-table/filters-data`, {
      params: {
        context,
        segmentation_filters,
        named_segmentation_context,
      },
      crossDomain: true,
      timeout: isDev() ? 0 : 30000
    })
      .then(function (response) {
        const availableFilterNames = response.data.available_filters.map(f => f.filter);
        const activeFilters = _.chain(jsonData.activeFilters)
          .filter(filter => availableFilterNames.includes(filter.filter))
          .sortBy(['sortOrder'])
          .value();

        // handle success
        dispatch({
          type: 'HYDRATE',
          payload: {
            ready: true,
            loadedSegmentationId: jsonData.loadedSegmentationId,
            namedSegmentations: response.data.named_segmentations,
            availableFilters: response.data.available_filters,
            activeFilters,

            // Initial state before user changed anything
            initializedState: {
              ...jsonData,
              namedSegmentations: response.data.named_segmentations,
              availableFilters: response.data.available_filters
            }
          }
        });
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }

  const updateFilter = (client_id, values = {}) => {
    dispatch({
      type: 'UPDATE_FILTER',
      payload: {
        client_id,
        values
      }
    });
  }

  const fetchUnloadedOptions = () => {
    if (!state.ready) return;
    _.each(state.activeFilters, filter => {
      fetchOptionsData(filter.client_id, filter.filter);
    });
  };

  const fetchOptionsData = async (client_id, filterType, updateState = true) => {
    const fetchOptions = _.get(_.find(state.availableFilters, { filter: filterType }), 'fetch_options');
    const existingFilter = _.find(state.activeFilters, { client_id });

    if ((!fetchOptions) || (existingFilter && existingFilter.optionsDataLoaded)) {
      return;
    }

    const { base_url } = jsonData;

    const dataSource = `${base_url}/ajax/data-table/filter-options/${filterType}`;

    const options = await axios.get(dataSource, {
      crossDomain: true,
      timeout: isDev() ? 0 : 30000
    })
      .then(function (response) {
        // handle success
        if (updateState) {
          updateFilter(client_id, {
            optionsData: response.data.options,
            optionsDataLoaded: true
          });
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });

    return options;
  }

  const persistNamedSegmentation = async (_id = null, segmentationData) => {
    dispatch({
      type: 'SET_SAVING',
      payload: true
    });

    const { base_url } = jsonData;

    // Ajax call to persist to server
    const urlId = !!_id ? `/${_id}` : '';
    const namedSegmentation = await axios({
      method: !!_id ? 'PATCH' : 'POST',
      // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
      url: `${base_url}/ajax/data-table/named-segmentations${urlId}`,
      crossDomain: true,
      data: {
        named_segmentation: { ...segmentationData, context: state.context }
      },
      timeout: isDev() ? 0 : 30000
    })
      .then(response => {
        // handle success
        return response.data.named_segmentation
      })
      .catch(error => {
        // handle error
        console.error(error);
      })
      .finally(() => {
        dispatch({
          type: 'SET_SAVING',
          payload: false
        });
      });

      return !!namedSegmentation ? namedSegmentation._id : null;
  }

  const saveExisting = async (segmentationId, newName) => {
    if (!newName) {
      alert("Segmentation Name is required.");
      return;
    }

    const _id = await persistNamedSegmentation(segmentationId, {
      name: newName,
      operator: operatorRef.current,
      filters: activeFiltersRef.current.map(filter => ({
        client_id: filter.client_id,
        filter: filter.filter,
        operator: filter.operator,
        value: filter.value
      }))
    });

    if (!!_id) {
      dispatch({
        type: 'UPDATE_NAMED_SEGMENTATION',
        payload: {
          segmentationId,
          newName
        }
      });
    } else {
      alert('Error saving Named Segmenation');
    }
  }

  const saveAsNew = async (newName) => {
    if (!newName) {
      alert("Segmentation Name is required.");
      return;
    }

    if (_.find(state.namedSegmentations, { name: newName })) {
      alert("Please provide a unique Segmentation Name");
      return;
    }

    const _id = await persistNamedSegmentation(null, {
      name: newName,
      operator: operatorRef.current,
      filters: activeFiltersRef.current.map(filter => ({
        client_id: filter.client_id,
        filter: filter.filter,
        operator: filter.operator,
        value: filter.value
      }))
    });

    if (!!_id) {
      dispatch({
        type: 'ADD_NAMED_SEGMENTATION',
        payload: {
          _id,
          name: newName
        }
      });
    } else {
      alert('Error saving Named Segmenation');
    }
  }

  const deleteNamedSegmentation = async (segmentationId) => {
    const segmentationName = _.find(state.namedSegmentations, { _id: segmentationId }).name;
    if (!window.confirm(`Are you sure you want to delete the named segmentation: ${segmentationName}`)) {
      return;
    }

    dispatch({
      type: 'SET_SAVING',
      payload: true
    });

    const { base_url } = jsonData;

    // Ajax call to persist to server
    const success = await axios({
      method: 'DELETE',
      // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
      url: `${base_url}/ajax/data-table/named-segmentations/${segmentationId}`,
      crossDomain: true,
      timeout: isDev() ? 0 : 30000
    })
      .then(response => {
        // handle success

        dispatch({
          type: 'REMOVE_NAMED_SEGMENTATION',
          payload: segmentationId
        });

        dispatch({
          type: 'SET_SEGMENTATION_NAME',
          payload: ''
        });

        return true;
      })
      .catch(error => {
        // handle error
        console.error(error);

        return false;
      })
      .finally(() => {
        dispatch({
          type: 'SET_SAVING',
          payload: false
        });
      });

      return success;
  }

  const close = () => {
    window.closeSegmentationFilter();
  }

  const cancel = () => {
    if (unsavedRef.current === true) {
      if (!window.confirm("You have unsaved filters. Are you sure you want to cancel?")) {
        return;
      }
    }

    // Apply changes if the user deleted a named segmenation
    // that was loaded upon initialization
    if (
      !!state.initializedState.loadedSegmentationId &&
      !state.loadedSegmentationId &&
      !_.find(state.namedSegmentations, { _id: state.initializedState.loadedSegmentationId })
    ) {
      apply(null, state.initializedState.operator, [], false);
    }

    clear(false);
    close();
  }

  const apply = (loadedSegmentationId, operator, activeFilters, unsaved) => {
    dispatch({
      type: 'SET_APPLYING',
      payload: true,
    });

    const finalState = {
      context: state.context,
      loadedSegmentationId,
      operator,
      activeFilters: activeFilters.map(filter => ({
        ..._.pick(filter, ['client_id', 'filter', 'sortOrder', 'operator', 'value']),
      }))
    };

    if (unsaved && loadedSegmentationId) {
      finalState.loadedSegmentationId = null;
    } else if (loadedSegmentationId && !unsaved) {
      finalState.activeFilters = [];
    }

    setJSONData(finalState);
    dispatch({
      type: 'HYDRATE',
      payload: {
        ...finalState,
        unsaved: false,
      }
    });
    // close();
    submitForm();
  }

  const clear = (prompt = true) => {
    if (prompt && unsavedRef.current === true) {
      if (!window.confirm("You have unsaved filters. Are you sure you want to clear your fliters?")) {
        return false;
      }
    }

    dispatch({
      type: 'CLEAR_FILTERS'
    });

    return true;
  }

  if (!state.open) return null;

  const activeFilters = _.sortBy(state.activeFilters, ['sortOrder']);

  const component = (
    <div className="SegmentationFilter">
      <div className="inner-wrap">
        <div className="header">
          <div className="heading">
            <h2 className="raleway-semi-bold raleway-12">New Segment</h2>
          </div>
          <div className="buttons">
            <button
              className="exit-btn"
              onClick={(e) => {
                e.preventDefault();
                cancel();
              }}
            >x</button>
          </div>
        </div>
        <div className="segment-name">
          <label className="raleway-semi-bold raleway-12">Segment Name: </label>
          <input
            type="text"
            value={state.segmentationName}
            onChange={e => {
              dispatch({
                type: 'SET_SEGMENTATION_NAME',
                payload: e.target.value
              });
            }}
            className="montserrat-bold montserrat-12"
          />
          {!!state.loadedSegmentationId && (
            <button
              className="btn delete-btn"
              onClick={e => {
                e.preventDefault();
                deleteNamedSegmentation(state.loadedSegmentationId);
              }}
            >Delete</button>
          )}
        </div>
        <div className="panes">
          <div className="left">
            <div className="left-top">
              <h3 className="raleway-semi-bold raleway-12">
                Add Filter
              </h3>
              <AddFilter
                state={state}
                dispatch={dispatch}
                fetchOptionsData={fetchOptionsData}
              />
              <div className="buttons last">
                <button
                  onClick={e => {
                    e.preventDefault();
                    clear(e);
                  }}
                  className="btn btn-clear"
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="left-vertical-divider" />
            <div className="left-bottom">
              <h3 className="raleway-semi-bold raleway-12">
                Load Segment
              </h3>
              <LoadSegmentation
                state={state}
                dispatch={dispatch}
                unsaved={unsavedRef.current}
              />
              {!!state.loadedSegmentationId && (
                <div className="buttons">
                  <button
                    onClick={e => {
                      e.preventDefault();
                      saveExisting(state.loadedSegmentationId, state.segmentationName);
                    }}
                    className="btn btn-save"
                  >
                    Save
                  </button>
                </div>
              )}
              <div className="buttons last">
                <button
                  onClick={e => {
                    e.preventDefault();
                    saveAsNew(state.segmentationName);
                  }}
                  className="btn btn-save-as-new"
                >
                  Save as New
                </button>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="filters">
              {state.activeFilters.length === 0 && (
                <div
                  className="raleway-semi-bold raleway-12 no-filters-message"
                >
                  Add Some Filters!
                </div>
              )}
              <table>
                <tbody>
                  {state.ready && activeFilters.map(filter => (
                    <FilterRow
                      key={filter.client_id}
                      state={state}
                      dispatch={dispatch}
                      filter={filter}
                    />
                  ))}
                </tbody>
              </table>
              <FiltersOperator
                state={state}
                dispatch={dispatch}
              />
            </div>
          </div>
        </div>
        <div className="bottom">
            <div
              className="raleway-semi-bold raleway-12 message"
            >
              {state.unsaved && (
                <span>
                  * You have unsaved filters
                </span>
              )}
            </div>
            <div className="bottom-buttons">
              <div>
                <button
                  onClick={e => {
                    e.preventDefault();
                    cancel();
                  }}
                  className="btn btn-white"
                >
                  Cancel
                </button>
                <button
                  onClick={e => {
                    e.preventDefault();
                    apply(
                      state.loadedSegmentationId,
                      state.operator,
                      state.activeFilters,
                      state.unsaved
                    );
                  }}
                  className="btn"
                >
                  Apply
                </button>
              </div>
            </div>
        </div>
      </div>
      <UIBlocker block={!state.ready || state.saving || state.applying} />
    </div>
  );

  if (!segmentationFilterMount) {
    return null;
  }

  return ReactDOM.createPortal(
    component,
    segmentationFilterMount,
  );
}

export default SegmentationFilter;
