import React, { useReducer, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { initialState, reducer, setDispatcher, actions as a } from './reducer';
import { isDev, getJSONData } from '../../utils';
import { makeOption, makeVariant } from './utils';
import fakeJSONData from './fake-json-data';
import arrayMove from 'array-move';
import OptionRow from './OptionRow';
import VariantRow from './VariantRow';
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Checklist from '../exports/Checklist';
import BulkSetInventoryModal from './BulkSetInventoryModal';
import BulkSetPriceModal from './BulkSetPriceModal';
import OptionsInputs from './OptionsInputs';
import VariantsInputs from './VariantsInputs';
import VariantsSelectBar from './VariantsSelectBar';

const productVariantsMount = document.getElementById('product_variants_mount');

const SortableOptionRow = SortableElement(item => <OptionRow {...item} />);
const SortableOptions = SortableContainer(({ state, options }) => (
  <div className="sortable-options">
    {options.map((o, i) => (
      <SortableOptionRow
        state={state}
        option={o}
        index={i}
        key={o.clientId}
      />
    ))}
  </div>
));

const SortableVariantRow = SortableElement(item => <VariantRow {...item} />);
const SortableVariants = SortableContainer(({ options, variants, imagesUploaderConfig }) => (
  <tbody className="sortable-variants">
    {variants.map((v, i) => (
      <SortableVariantRow
        options={options}
        variant={v}
        imagesUploaderConfig={imagesUploaderConfig}
        index={i}
        key={v.clientId}
      />
    ))}
  </tbody>
));

const defaultJsonData = {
  images_uploader: {},
  value: {
    options: [],
    variants: [],
  }
};

function ProductVariants() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const jsonData = useRef(defaultJsonData);

  useEffect(() => {
    setDispatcher(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!!productVariantsMount) {
      initialize();
    }
  }, []);

  const initialize = () => {
    jsonData.current = { ...defaultJsonData, ...getJSONData('#product_variants_mount', isDev() ? fakeJSONData : null) };

    const { value: { options: serverOptions, variants: serverVariants } } = jsonData.current;

    const options = serverOptions.map(o => makeOption(o));
    const variants = serverVariants.map(v => {
      let price = _.get(v, 'price', null);

      if (_.isNumber(price)) {
        price = price === 0 ? '0.00' : _.toString((price / 100).toFixed(2));
      }

      return {
        ...v,
        price,
      }
    }).map(v => makeVariant(v, options));

    a.hydrate({
      ready: true,
      options,
      variants
    });
  }

  const onOptionsSortEnd = useCallback(({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) return;

    a.hydrate({
      options: arrayMove(state.options, oldIndex, newIndex),
    });

    // Clear sort
    a.setVariantSort();
  }, [state.options]);

  const onVariantsSortEnd = useCallback(({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) return;

    a.hydrate({
      variants: arrayMove(state.variants, oldIndex, newIndex),
    });
  }, [state.variants]);



  const flagIssues = useCallback((variants) => {
    const optionsOccurances = _.reduce(variants, (optionsOccurances, v, index) => {
      const comboStr = _.reduce(v.options, (comboStr, oV, oK) => { return `${comboStr}:${oK}:${oV}` }, '');
      (optionsOccurances[comboStr] || (optionsOccurances[comboStr] = [])).push(index);
      return optionsOccurances;
    }, {});
    const duplicates = _.flatten(_.values(optionsOccurances).filter(o => o.length > 1));

    const incompleteVariants = variants.map((v, index) => {
      const isIncomplete = state.options.filter(o => !!v.options[o.clientId]).length !== state.options.length;

      return [index, isIncomplete];
    })
    .filter(v => !!v[1])
    .map(v => v[0]);

    const skuOccurances = _.reduce(variants, (skuOccurances, v, index) => {
      const sku = !!v.sku ? v.sku.toLowerCase() : null;

      if (!!sku) {
        (skuOccurances[sku] || (skuOccurances[sku] = [])).push(index);
      }

      return skuOccurances;
    }, {});
    const duplicateSkus = _.flatten(_.values(skuOccurances).filter(o => o.length > 1));

    return variants.map((v, index) => ({
      ...v,
      isDuplicate: duplicates.indexOf(index) !== -1,
      isDuplicateSku: duplicateSkus.indexOf(index) !== -1,
      isIncomplete: incompleteVariants.indexOf(index) !== -1
    }));
  }, [state.options, state.variants]);

  const component =  (
    <div className="ProductVariants">

      <ul className="tabs">
        <li
          className={`tab raleway-semi-bold raleway-12 ${state.activeTab === 'product-variants' ? 'active':''}`}
          onClick={() => a.changeTab('product-variants')}
        >
          <span>
            Product Variants
          </span>
        </li>
        <li
          className={`tab raleway-semi-bold raleway-12 ${state.activeTab === 'product-options' ? 'active':''}`}
          onClick={() => a.changeTab('product-options')}
        >
          <span>
            Product Options
          </span>
        </li>
      </ul>
      <div className="tabs-hrule" />

      {state.activeTab === 'product-options' && (
        <div className="product-options">

          <SortableOptions
            state={state}
            options={state.options}
            onSortEnd={onOptionsSortEnd}
            distance={3}
            lockAxis="y"
            lockToContainerEdges
          />

          <div className="bottom">
            <button
              className="btn-reset raleway-semi-bold raleway-12 add-option"
              onClick={e => {
                e.preventDefault();
                a.addOption();
              }}
              title="Add Option"
            >
              <span className="btn btn-icon">
                <span>+</span>
              </span>
              Add Option
            </button>
          </div>
        </div>
      )}

      {state.activeTab === 'product-variants' && (
        <>
          <VariantsSelectBar state={state} />

          <div className="product-variants">
            <div className="bulk-select">
              <Checklist
                items={state.variants.map(v => ({ id: v.clientId }))}
                selectedItems={state.selectedVariants}
                onSelect={selectedItems => {
                  a.setSelectedVariants(selectedItems);
                }}
              />
            </div>
            <div className="variants-table">
              <table>
                <thead>
                  <tr>
                    <th
                      className="raleway-semi-bold raleway-12 image"
                      key="image"
                    >
                      Img
                    </th>
                    {state.options.map(o => {
                      const directionClass = state.variantSortOption === o.clientId ?
                        `direction-${state.variantSortDirection}` :
                        '';

                      return (
                        <th
                          className={`raleway-semi-bold raleway-12 sortable ${directionClass} option-name-${_.kebabCase(o.name.toLowerCase())}`}
                          key={o.name}
                          onClick={() => {
                            a.setVariantSort(o.clientId);
                          }}
                        >
                          {o.name}
                        </th>
                      );
                    })}
                    <th
                      className="raleway-semi-bold raleway-12 inventory"
                      key="inventory"
                    >
                      Inventory
                    </th>
                    <th
                      className="raleway-semi-bold raleway-12 price"
                      key="price"
                    >
                      Price
                    </th>
                    <th
                      className="raleway-semi-bold raleway-12 sku"
                      key="sku"
                    >
                      SKU
                    </th>
                    <th className="delete" />
                  </tr>
                </thead>
                <SortableVariants
                  imagesUploaderConfig={jsonData.current.images_uploader}
                  options={state.options}
                  variants={flagIssues(state.variants)}
                  onSortEnd={onVariantsSortEnd}
                  distance={3}
                  lockAxis="y"
                  lockToContainerEdges
                />
              </table>
            </div>
          </div>

          <div className="product-variants-bottom">
            <button
              className="btn-reset raleway-semi-bold raleway-12 add-variant"
              onClick={e => {
                e.preventDefault();
                a.addVariant();
              }}
              title="Add Variant"
              disabled={state.options.length === 0}
            >
              <span className="btn btn-icon">
                <span>+</span>
              </span>
              Add Variant
            </button>
          </div>
        </>
      )}

      <OptionsInputs state={state} />
      <VariantsInputs state={state} />

      <BulkSetInventoryModal
        isOpen={state.bulkSetInventoryModalOpen}
        onCancel={() => {
          a.hydrate({
            bulkSetInventoryModalOpen: false
          });
        }}
        onConfirm={inventory => {
          a.bulkSetVariantInventory(inventory);
          a.hydrate({
            bulkSetInventoryModalOpen: false
          });
        }}
      />

      <BulkSetPriceModal
        isOpen={state.bulkSetPriceModalOpen}
        onCancel={() => {
          a.hydrate({
            bulkSetPriceModalOpen: false
          });
        }}
        onConfirm={price => {
          a.bulkSetVariantPrice(price);
          a.hydrate({
            bulkSetPriceModalOpen: false
          });
        }}
      />
    </div>
  );

  if (!productVariantsMount) {
    return null;
  }

  return ReactDOM.createPortal(
    component,
    productVariantsMount,
  );
}

export default ProductVariants;
