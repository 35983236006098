import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

// Icons
import externalLinkIconLight from '../../images/icon-external-link-light.svg';
import hourglassIcon from '../../images/icon-hourglass-light.svg';
import expandIcon from '../../images/icon-expand.svg';
import imageIcon from '../../images/icon-image-light.svg';
import reelIcon from '../../images/icon-reel-light.svg';
import storyIcon from '../../images/icon-story-light.svg';
import videoIcon from '../../images/icon-video-light.svg';
import placeholderImg from '../../images/placeholder.png';
import BounceLoader from "react-spinners/BounceLoader";
import GridLoader from "react-spinners/GridLoader";

function InstagramPostsGridCell({ postData = null, engagementBoundaries = null, onPressCell = () => {} }) {
    if (!postData) {
        return null;
    }

    const engagementDataPending = (moment.duration(moment().diff(moment(postData.created))).asHours() < 72);

    const engagementDisplay = (
        (engagementDataPending || (!postData?.engagement)) ?
            (<img src={hourglassIcon} className="hourglass-btn" title='Engagement Data Pending' />)
            : (<div className="ipg-single-engagement">{postData?.engagement}%</div>)
    );



    const engagementBgColor = (() => {
        let min = 1.0;
        let max = 3.0;

        if (engagementBoundaries && engagementBoundaries?.min) {
            min = engagementBoundaries?.min * 100;
        }

        if (engagementBoundaries && engagementBoundaries?.max) {
            max = engagementBoundaries?.max * 100;
        }

        let color = (
            (engagementDataPending || (!postData.engagement)) ?
                '#939AA4' : (postData.engagement > max) ?
                    '#48BAA8' : (postData.engagement > min) ?
                        '#000000' : '#EA2A2A'
        );

        return color;
    })();

    const postTypeImage = (() => {switch(postData.type.toUpperCase()) {
        case "PHOTO":
            return (<img src={imageIcon} className="ipg-post-type-img photo-type" title="Image" />);
        case "VIDEO":
            return (<img src={videoIcon} className="ipg-post-type-img video-type" title="Video" />);
        case "REEL":
            return (<img src={reelIcon} className="ipg-post-type-img reel-type" title="Reel" />);
        case "STORY":
            return (<img src={storyIcon} className="ipg-post-type-img story-type" title="Story" />);
        default:
            return (<img src={imageIcon} className="ipg-post-type-img photo-type" title="Image" />);
    }})();

    return (
        <div className="ipg-single">
            <div className="ipg-single-top">
                <button
                    className="ipg-post-img-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        onPressCell();
                    }}
                    title="Expand Post"
                >
                    <div className="ipg-post-hover-indicator"><img src={expandIcon} className="ipg-expand-img" /></div>
                    {postTypeImage}
                    <img src={postData.imageUrl} className="ipg-post-img" title="Post Image" />
                    {( postData.imageUrl === placeholderImg) && (
                        <div className="ipg-post-download-indicator">
                            <BounceLoader color="#48baa8" loading={(postData.imageUrl === placeholderImg)} size={60} />
                        </div>
                    )}
                </button>
            </div>
            <div className="ipg-single-bottom" style={{background: engagementBgColor}}>
                {engagementDisplay}
                <a
                    href={postData.link}
                    target="_blank"
                    className="external-btn"
                ><img src={externalLinkIconLight} title='Open Post In Instagram' /></a>
            </div>
        </div>
    );
}

export default InstagramPostsGridCell;
