import React, { useState } from 'react';
import Select from 'react-select';
import _ from 'lodash';

function LoadSegmentation({ state, dispatch, unsaved }) {
  const [selectedSegment, setSelectedSegment] = useState(null);

  const loadSegmentation = (selectedSegment, promptUnsaved = true) => {
    if (!selectedSegment) return;

    const segmentationId = selectedSegment.value;

    if (promptUnsaved && unsaved === true) {
      if (!window.confirm("You have unsaved filters. Are you sure you want to load an existing segmentation?")) {
        return;
      }
    }

    dispatch({
      type: 'SET_LOADED_SEGMENTATION',
      payload: {
        segmentationId
      }
    });
  }

  return (
    <div className="load-segment">
      <Select
        isMulti={false}
        options={_.sortBy(state.namedSegmentations, ['name']).map(ns => ({ label: ns.name, value: ns._id }))}
        onChange={selectedOption => {
          setSelectedSegment(selectedOption);
        }}
        value={selectedSegment}
        className='raleway-semi-bold raleway-12 react-select-container'
        isSearchable
      />
      <div className="buttons">
        <button
          onClick={e => {
            e.preventDefault();
            loadSegmentation(selectedSegment);
          }}
          className="btn btn-green load-segment-btn"
        >
          Load Segment
        </button>
      </div>
    </div>
  );
}

export default LoadSegmentation;
