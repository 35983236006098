import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { isDev, getJSONData } from '../../utils';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import fakeJSONData from './fake-json-data';
import downloadIcon from '../../images/download.svg';
import Select from 'react-select';
import Modal from '../modal/Modal.js';
import Checklist from './Checklist';

const exportsMount = document.getElementById('exports_mount');

const defaultJsonData = {
  ajax_url: null,
  example_download_url: null,
  context: null,
  data_source: {
    type: null,
    context: null
  },
  available_exports: [],
};

let jsonData;

function Exports(props) {
  const [ready, setReady] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [availableExports, setAvailableExports] = useState([]);
  const [selectedExport, setSelectedExport] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [exportName, setExportName] = useState('');

  useEffect(() => {
    if (!!exportsMount) {
      initialize();
    }
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
    setReady(true);
  };

  const closeModal = (clearState = true) => {
    setReady(false);
    setSelectedOptions([]);
    setSelectedColumns([]);
    setSelectedExport([]);
    setModalIsOpen(false);
  };

  const initialize = () => {
    jsonData = { ...defaultJsonData, ...getJSONData('#exports_mount', fakeJSONData) };

    setAvailableExports(jsonData.available_exports);

    window.exports = jsonData;

    $('#open_exports_modal').click(e => {
      e.preventDefault();
      openModal();
    });

    setReady(true);

    if (isDev()) {
      // openModal();
    }
  }

  const getExportObj = (type) => (
    _.find(availableExports, { type })
  );

  const makeExampleExportName = (type) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDay();

    return `${type.replace(/_/g, '-')}-${year}-${month}-${day}`;
  };

  const makeExportOptions = (exportName, selectedColumns, selectedOptions, exportObj) => {
    const selectedOptionValues = selectedOptions.reduce((options, option) => {
      options[option] = true;

      return options;
    }, {});

    const deselectedOptionValues = _.difference(_.map(exportObj.option_fields, of => of.slug), _.keys(selectedOptionValues))
      .reduce((options, option) => {
        options[option] = false;

        return options;
      }, {});

    const options = {
      ...selectedOptionValues,
      ...deselectedOptionValues,
    };

    return {
      name: exportName,
      selected_columns: selectedColumns,
      ...options
    };
  };

  const startExport = async (selectedExport, exportName, selectedColumns, selectedOptions) => {
    const exportObj = getExportObj(selectedExport.value);
    const columns = exportObj.columns.filter(c => selectedColumns.indexOf(c.slug) !== -1);

    let abort = false;
    if (columns.filter(c => c.sensitive).length) {
      abort = !window.confirm(`WARNING: You are exporting columns with sensitive information in them. Are you sure you want to continue?`);
    } else {
      abort = !window.confirm(`Are you sure you want to run this export?`);
    }

    if (abort) return;

    setSubmitting(true);

    const exportId = await axios({
      method: 'POST',
      // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
      url: jsonData.ajax_url,
      crossDomain: true,
      data: {
        type: exportObj.type,
        export_options: makeExportOptions(exportName, selectedColumns, selectedOptions, exportObj),
        form_data: jsonData.form_data || {},
        data_source: {
          ...jsonData.data_source,
          config: {
            ...jsonData.data_source.config,
            selected_ids: window.bulkActions.selectedIds
          }
        }
      },
      timeout: isDev() ? 0 : 30000
    })
    .then(response => {
      // handle success

      props.onCreate(response.data.export_id);

      return response.data.export_id;
    })
    .catch(error => {
      // handle error
      console.log(error);
    });

    // Trigger event for Imports/Exports progress indicator to react to
    $('body').trigger('export::started');

    if (exportId) {
      closeModal();
    } else {
      window.alert("There was an error creating your export.");
    }

    setSubmitting(false);
  }

  const selectExportType = (selectedOption) => {
    setSelectedExport(selectedOption);

    const exportObj = getExportObj(selectedOption.value);

    const defaultSelectedColumns = exportObj.columns
      .filter(c => c.selected)
      .map(c => c.slug);

    setSelectedColumns(defaultSelectedColumns);

    // TODO Refactor this to support non-bollean field types
    const defaultSelectedOptions = exportObj.option_fields
      .filter(c => c.default_value === true)
      .map(c => c.slug);

    setSelectedOptions(defaultSelectedOptions);
  };

  const makeColumns = () => {
    const insensitive = selectedExportObj.columns
      .filter(c => !c.sensitive);

    const sensitive = selectedExportObj.columns
      .filter(c => c.sensitive);

    const items = [
      ...insensitive,
      ...sensitive
    ];

    return items.map((item, i) => {
      let sensitiveWarning = null;
      if (item.sensitive) {
        sensitiveWarning = (
          <span className="montserrat-bold montserrat-12 sensitive">
            * Sensitive
          </span>
        );
      }

      let separator = null;
      if (sensitive.length && i === insensitive.length-1) {
        separator = <div key="sensitive-separator" className="separator" />;
      }

      return {
        id: item.slug,
        label: item.name,
        afterLabel: sensitiveWarning,
        after: separator,
        disabled: item.required === true
      };
    });
  };

  const selectedExportObj = selectedExport ?
    getExportObj(selectedExport.value):
    null;

  if (!ready) return null;

  const component = (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        closeModal();
      }}
      title="Exports"
      blockUI={submitting}
      buttons={[
        {
          style: 'white',
          text: 'Cancel',
          onClick: () => {
            closeModal();
          }
        },
        {
          style: 'black',
          text: 'Export',
          onClick: () => {
            startExport(selectedExport, exportName, selectedColumns, selectedOptions);
          }
        }
      ]}
    >
      <div className="Exports">
        <div className="available-exports">
          <h3 className="raleway-semi-bold raleway-12">
            Choose an Export Type
          </h3>
          <Select
            isMulti={false}
            options={availableExports.map(e => ({ label: e.name, value: e.type }))}
            onChange={selectedOption => {
              selectExportType(selectedOption);
            }}
            name="type"
            value={selectedExport}
            className='raleway-semi-bold raleway-12 react-select-container'
          />
        </div>

        {!!selectedExportObj && (
          <div className="export-name styled-form">
            <h3 className="raleway-semi-bold raleway-12">
              Name <span className="subtext">(optional)</span>
            </h3>
            <input
              className="montserrat-medium montserrat-12"
              type="text"
              value={exportName}
              placeholder={`ie. ${makeExampleExportName(selectedExportObj.type)}`}
              onChange={e => setExportName(e.target.value)}
            />
          </div>
        )}

        {!!selectedExportObj && (
          <div className="export-options styled-form">
            <div className="inner">
              <div className="columns">
                <h3 className="raleway-semi-bold raleway-12">
                  Columns
                </h3>
                <Checklist
                  items={makeColumns()}
                  selectedItems={selectedColumns}
                  onSelect={selected => setSelectedColumns(selected)}
                />
              </div>
              <div className="options">
                <h3 className="raleway-semi-bold raleway-12">
                  Options
                </h3>
                <Checklist
                  items={selectedExportObj.option_fields.map(o => ({
                    id: o.slug,
                    label: o.name
                  }))}
                  selectedItems={selectedOptions}
                  onSelect={selected => setSelectedOptions(selected)}
                />
              </div>
            </div>
            <div className="separator" />
            <div className="example-download">
              <form method="POST" action={jsonData.example_download_url}>
                <input
                  type="hidden"
                  name="type"
                  value={getExportObj(selectedExport.value).type}
                />
                <input
                  type="hidden"
                  name="export_options"
                  value={JSON.stringify(makeExportOptions(exportName, selectedColumns, selectedOptions, selectedExportObj))}
                />

                <button
                  type="submit"
                  title="Download Example File"
                  className="raleway-semi-bold raleway-12"
                >
                  <img src={downloadIcon} alt="Download Example File" />
                  Download Example File
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );

  if (!exportsMount) {
    return null;
  }

  return ReactDOM.createPortal(
    component,
    exportsMount
  );
}

Exports.defaultProps = {
  onCreate: () => {}
};

export default Exports;
