import React, { useState } from 'react';
import _ from 'lodash';
import InstagramPostsGridCell from './InstagramPostsGridCell';
import InstagramPostCard from './InstagramPostCard';


function InstagramPostsGrid({ postsData = null, userData = null }) {
  const [cardOpen, setCardOpen] = useState(false);
  const [currentCardPostIndex, setCurrentCardIndexPost] = useState();

  if (!postsData || !userData) {
    return null;
  }

  const grid = _.map(postsData, (socialPost, index) => {
      const post = {
          ...socialPost?.post,
          link: socialPost?.post_url,
          type: socialPost?.product_type,
      };
    
    return (
      <InstagramPostsGridCell
        postData={post}
        engagementBoundaries={userData?.engagementBoundaries}
        onPressCell={() => {
          setCurrentCardIndexPost(index);
          setCardOpen(true);
        }}
      />
    );
  });

  return (
    <div className="ipg-wrapper">
      {grid}

      {(!!cardOpen) && (currentCardPostIndex !== null) && (
        <InstagramPostCard
          userData={userData}
          postData={{
              ...postsData[currentCardPostIndex]?.post, 
              link: postsData[currentCardPostIndex]?.post_url,
              type: postsData[currentCardPostIndex]?.product_type,
          }}
          onPrev={() => {
            setCurrentCardIndexPost((currentCardPostIndex === 0) ? (postsData.length - 1) : (currentCardPostIndex - 1));
          }}
          onNext={() => {
            setCurrentCardIndexPost((currentCardPostIndex === postsData.length - 1) ? 0 : (currentCardPostIndex + 1));
          }}
          onClose={() => {
            setCardOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default InstagramPostsGrid;
