import React, { useState } from 'react';
import _ from 'lodash';
import uuid from 'uuid/v4';
import Select from 'react-select';

function AddFilter({ state, dispatch, fetchOptionsData }) {
  const [selectedFilter, setSelectedFilter] = useState(null);

  const addFilter = (selectedFilter, values = {}, warnUnsaved = true, fetch = true) => {
    if (!selectedFilter) return;

    const filterType = selectedFilter.value;
    const client_id = uuid();

    dispatch({
      type: 'ADD_FILTER',
      payload: {
        filterType,
        values: { ...values, client_id }
      }
    });

    if (fetch) {
      fetchOptionsData(client_id, filterType);
    }

    if (warnUnsaved) {
      dispatch({
        type: 'SET_UNSAVED',
        payload: true
      });
    }

    return client_id;
  }

  return (
    <div className="add-filter">
      <Select
        isMulti={false}
        options={_.sortBy(state.availableFilters, ['name']).map(f => ({ label: f.name, value: f.filter }))}
        onChange={selectedOption => {
          setSelectedFilter(selectedOption);
        }}
        value={selectedFilter}
        className='raleway-semi-bold raleway-12 react-select-container'
        isSearchable
      />
      <div className="buttons">
        <button
          onClick={e => {
            e.preventDefault();
            addFilter(selectedFilter);
          }}
          className="btn btn-green add-filter-btn"
        >
          Add New Filter
        </button>
      </div>
    </div>
  );
}

export default AddFilter;
