import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';
import { getActionHandlers } from './actions';
import { isDev } from '../utils';
import {handleRowActionClick} from "../Lib/rowActionHelpers";

const makeCancelToken = () => axios.CancelToken.source();

const inFlight = {};
const setInFlight = (action, recordId, cancelToken) => {
    cancelInFlight(action, recordId);

    if (!inFlight[action]) {
        inFlight[action] = [];
    }

    inFlight[action][recordId] = cancelToken;
};
const isInFlight = (action, recordId) => {
    return !!inFlight[action] && !!inFlight[action][recordId];
};
const cancelInFlight = (action, recordId) => {
    if (isInFlight(action, recordId)) {
        inFlight[action][recordId].cancel('Interrupted by user.');
        delete inFlight[action][recordId];
        if (_.keys(inFlight[action]).length === 0) {
            delete inFlight[action];
        }
    }
};
const anyInFlight = () => (!!_.keys(inFlight).length);

export const handleAction = async (href, data, $row) => { // (10/21/2021) added 'export' so I could use inside Detail Pane component ~ Clem
    const { record_id, action, ...rest } = data;

    if (data.confirm) {
        if (!window.confirm(data.confirm)) return;
    }

    if (data.double_confirm) {
        if (!window.confirm(data.double_confirm)) return;
    }

    const handlers = getActionHandlers(action);

    if (isInFlight(action, record_id) && !handlers.allowInterrupt) {
        return;
    }

    if (!handlers.onBeforeProcess(href, data, $row)) {
        return;
    }

    const $table = $row.parents('table');
    $table.addClass('processing');
    $table.addClass('processing__'+ data.action +'__'+ record_id);

    $row.addClass('processing');
    $row.addClass('processing__'+ data.action);
    if (handlers.blockUI) {
        $row.addClass('block-ui');
    }

    handlers.onProcessing(data, $row);

    const cancelToken = makeCancelToken();
    setInFlight(action, record_id, cancelToken);

    let success = false;
    const resp = await axios({
        method: 'POST',
        // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
        url: href,
        crossDomain: true,
        data: {
            id: record_id,
            action,
            ...rest
        },
        cancelToken: cancelToken.token,
        timeout: isDev() ? 0 : 30000
    })
        .then(response => {
            // handle server-provided error message
            if (response.data.error) {
                alert(response.data.error);
                handlers.onError(new Error(response.data.error), data, $row);
            }
            // handle success
            else if (data.success_message) {
                alert(data.success_message);
                success = true;
            }

            return response.data;
        })
        .catch(error => {
            if (axios.isCancel(error)) return;
            // handle error
            if (data.error_message) {
                alert(data.error_message);
            }
            handlers.onError(error, data, $row);
        })
        .finally(() => {
            cancelInFlight(action, record_id);
        });

    // Artifical delay for testing
    // await new Promise((resolve) => { setTimeout(() => resolve(), 3000)});

    if (typeof resp !== 'undefined') {
        handlers.onComplete(resp, data, $row); // Only call this if there are no errors
    }
    cancelInFlight(action, record_id); // Clear inFlight ref

    if (!anyInFlight()) {
        $table.removeClass('processing');
    }

    $table.removeClass('processing__'+ data.action +'__'+ record_id);

    $row.removeClass('processing');
    $row.removeClass('processing__'+ data.action);
    $row.removeClass('block-ui');

    if (success && data.hide_on_success) {
        $row.hide().addClass('hidden-on-success');
    }

    return resp;
};

$(document).ready(function(){
    const $dataTable = $('.data-table');

    const actionsFunction = async function(e) {
        e.preventDefault();

        const $row = $(this).parents('tr');
        let socialId = $row.find('.bulk-select input').val();

        const submitAction = async ($object) => {
            $object.addClass('processing');
            await handleAction($object.attr('href'), $object.data(), $row);
            $object.removeClass('processing');
        };

        handleRowActionClick($(this), socialId, submitAction);
    };

    $dataTable.on('click', '.row-actions-col a.data-table-row-action', actionsFunction);
    $dataTable.on('click', '.review-status-actions-col a.data-table-row-action', actionsFunction);
});
