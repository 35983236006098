import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../modal/Modal';

function RenameOptionValueModal({ option, optionValue, isOpen, onCancel, onConfirm, ...rest }) {
  const [name, setName] = useState(!!optionValue ? optionValue.name : '');

  useEffect(() => {
    if (!!optionValue) {
      setName(optionValue.name);
    }
  }, [optionValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      onConfirm(name);
    }
  }, [name, onConfirm]);

  if (!option || !optionValue) return null;

  return (
    <Modal
      title="Bulk Set Inventory Levels"
      isOpen={isOpen}
      buttons={[
        {
          style: 'white',
          text: 'Cancel',
          onClick: () => {
            onCancel();
          }
        },
        {
          style: 'black',
          text: 'Set',
          onClick: () => {
            onConfirm(name);
          },
        }
      ]}
      onRequestClose={() => {
        onCancel();
      }}
      autoFocus={false}
      {...rest}
    >
      <h3>Rename {option.name}: {optionValue.name}</h3>
      <input
        type="text"
        value={name}
        onChange={e => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        autoFocus={true}
      />
    </Modal>
  );
}

RenameOptionValueModal.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  onConfirm: () => {}
};

export default RenameOptionValueModal;
