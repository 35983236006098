import _ from 'lodash';
import { makeFilter } from './utils';

export const initialState = {
  // Track the inital state of the UI on load so we
  // know to apply changes when deleting named segmentations
  initializedState: {},

  open: false,
  ready: false,
  unsaved: false,
  saving: false,
  applying: false,
  segmentationName: '',
  loadedSegmentationId: null,
  operator: 'AND',
  activeFilters: [],

  namedSegmentations: [],
  availableFilters: []
};

/**
 * Re-initialize activeFilters from JSON data
 */
const reinitializeActiveFilters = (state) => {
  if (!state.availableFilters.length) return state;

  const activeFilters = _.get(state, 'activeFilters', []);

  const newState = {
    ...state,
    activeFilters: activeFilters.map(filter => makeFilter(filter.filter, state.availableFilters, filter))
  };

  return newState;
};

const setLoadedSegmentation = (segmentationId, state) => {
  if (!segmentationId) return state;

  const namedSegmentation = _.find(state.namedSegmentations, { _id: segmentationId });

  if (!namedSegmentation) return state;

  const segmentationFilters = namedSegmentation.filters.map(filter => (
    makeFilter(
      filter.filter,
      state.availableFilters,
      { client_id: filter.client_id, value: filter.value, operator: filter.operator }
    )
  )).filter(filter => !!filter);

  return {
    ...state,
    unsaved: false,
    loadedSegmentationId: segmentationId,
    segmentationName: namedSegmentation.name,
    activeFilters: segmentationFilters
   };
}

export const reducer = (state, { type, payload = null }) => {
  switch(type) {
    case 'HYDRATE':
      let newState = {
        ...state,
        ...payload,
      };

      newState = setLoadedSegmentation(newState.loadedSegmentationId, newState);

      newState = {
        ...newState,
        ...reinitializeActiveFilters(newState),
      };

      return newState;

    case 'SET_OPEN':
      return { ...state, open: payload };

    case 'SET_UNSAVED':
      return { ...state, unsaved: payload };

    case 'SET_SAVING':
      return { ...state, saving: payload };

    case 'SET_APPLYING':
      return { ...state, applying: payload };

    case 'SET_FILTERS_OPERATOR':
      return { ...state, operator: payload };

    case 'ADD_FILTER':
      const newFilter = makeFilter(
        payload.filterType,
        state.availableFilters,
        payload.values
      );

      if (!newFilter) return state;

      newFilter.sortOrder = state.activeFilters.length;

      // Set default operator
      if (!newFilter.operator) {
        newFilter.operator = ['equals', 'in', newFilter.operators[0]]
          .filter(o => newFilter.operators.indexOf(o) !== -1)
          .shift();
      }

      return {
        ...state,
        activeFilters: [
          ...state.activeFilters,
          newFilter
        ]
      };

    case 'UPDATE_FILTER':
      const filter = _.find(state.activeFilters, { client_id: payload.client_id });

      if (!filter) return state;

      const updatedFilter = {
        ...filter,
        ...payload.values
      };
      const otherFilters = _.filter(
        state.activeFilters,
        filter => filter.client_id !== payload.client_id
      );
      // TODO Re-sort filters
      const activeFilters = [
        ...otherFilters,
        updatedFilter
      ];

      return {
        ...state,
        activeFilters
      };

    case 'REMOVE_FILTER':
      return {
        ...state,
        unsaved: true,
        activeFilters: _.reject(
          state.activeFilters,
          { client_id: payload.client_id }
        )
      };

    case 'CLEAR_FILTERS':
      return {
        ...state,
        unsaved: false,
        loadedSegmentationId: null,
        activeFilters: []
      };

    case 'SET_FILTERS':
      return { ...state };

    case 'SET_SEGMENTATION_NAME':
      return { ...state, segmentationName: payload };

    case 'SET_LOADED_SEGMENTATION':
      return setLoadedSegmentation(payload.segmentationId, state);

    case 'UPDATE_NAMED_SEGMENTATION':
      const segmentation = _.find(state.namedSegmentations, { _id: payload.segmentationId });

      return {
        ...state,
        unsaved: false,
        namedSegmentations: [
          ..._.reject(state.namedSegmentations, { _id: payload.segmentationId }),
          { ...segmentation, name: payload.newName, filters: [...state.activeFilters] }
        ]
      };

    case 'ADD_NAMED_SEGMENTATION':
      const newNamedSegmentationFilters = state.activeFilters.map(({ filter, operator, value }) => ({
        filter,
        operator,
        value
      }));

      const newNamedSegmentation = {
        _id: payload._id,
        name: payload.name,
        filters: newNamedSegmentationFilters
      };

      return {
        ...state,
        loadedSegmentationId: payload._id,
        namedSegmentations: [
          ...state.namedSegmentations,
          newNamedSegmentation
        ]
      };

    case 'REMOVE_NAMED_SEGMENTATION':
      return {
        ...state,
        activeFilters: [],
        loadedSegmentationId: null,
        namedSegmentations: _.reject(state.namedSegmentations, { _id: payload })
      }

    default:
      throw new Error();
  }
}
