export default {
  presign_url: 'http://app.test/ajax/imports/get-upload-parameters',
  upload_url: 'http://app.test/filepond/api/process',
  ajax_url: 'http://app.test/ajax/imports',
  example_download_url: 'http://app.test/imports/example',
  context: 'social_profiles.all',
  available_imports: [
    {
      name: 'Test',
      type: 'test',
      option_fields: [
        // {
        //   slug: 'example_option',
        //   name: 'Example Option',
        //   type: 'boolean',
        //   default_value: true
        // }
      ]
    }
  ],
};
