import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { isDev, getJSONData } from '../../utils';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import uuid from 'uuid/v4';
import fakeJSONData from './fake-json-data';
import downloadIcon from '../../images/download.svg';
import Select from 'react-select';
import Modal from '../modal/Modal.js';
import Checklist from '../exports/Checklist';
// https://itnext.io/uploading-files-with-react-and-filepond-f8a798308557
// TODO Use Filepond image preview for image uploads in admin UI (see article)
import { FilePond, registerPlugin as registerFilepondPlugin } from 'react-filepond';
import FilepondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

registerFilepondPlugin(FilepondPluginFileValidateType);

const importsMount = document.getElementById('imports_mount');

const defaultJsonData = {
  upload_url: null,
  ajax_url: null,
  context: null,
  available_imports: [],
};

let jsonData;

function Imports(props) {
  const [ready, setReady] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [availableImports, setAvailableImports] = useState([]);
  const [selectedImport, setSelectedImport] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [importName, setImportName] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const filepond = useRef(null);

  useEffect(() => {
    if (!!importsMount) {
      initialize();
    }
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
    setReady(true);
  };

  const closeModal = (clearState = true) => {
    setReady(false);
    setSelectedOptions([]);
    setSelectedImport([]);
    setModalIsOpen(false);
    setUploadedFile(null);
  };

  const initialize = () => {
    jsonData = { ...defaultJsonData, ...getJSONData('#imports_mount', fakeJSONData) };

    setAvailableImports(jsonData.available_imports);

    window.imports = jsonData;

    $('#open_imports_modal').click(e => {
      e.preventDefault();
      openModal();
    });

    setReady(true);

    if (isDev()) {
      // openModal();
    }
  }

  const getImportObj = (type) => (
    _.find(availableImports, { type })
  );

  const makeExampleImportName = (type) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDay();

    return `${type.replace(/_/g, '-')}-${year}-${month}-${day}`;
  };

  const makeImportOptions = (importName, selectedOptions, importObj) => {
    const selectedOptionValues = selectedOptions.reduce((options, option) => {
      options[option] = true;

      return options;
    }, {});

    const deselectedOptionValues = _.difference(_.map(importObj.option_fields, of => of.slug), _.keys(selectedOptionValues))
      .reduce((options, option) => {
        options[option] = false;

        return options;
      }, {});

    const options = {
      ...selectedOptionValues,
      ...deselectedOptionValues,
    };

    return {
      name: importName,
      ...options
    };
  };

  const startImport = async (selectedImport, importName, selectedOptions) => {
    const importObj = getImportObj(selectedImport.value);

    const abort = !window.confirm(`Are you sure you want to run this import?`);

    if (abort) return;

    setSubmitting(true);

    const importId = await axios({
      method: 'POST',
      // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
      url: jsonData.ajax_url,
      crossDomain: true,
      data: {
        type: importObj.type,
        import_options: makeImportOptions(importName, selectedOptions, importObj),
        form_data: jsonData.form_data || {},
        // filepond_server_id: uploadedFile,
        import_file_uuid: uploadedFile,
      },
      timeout: isDev() ? 0 : 30000
    })
    .then(response => {
      // handle success

      props.onCreate(response.data.import_id);

      return response.data.import_id;
    })
    .catch(error => {
      // handle error
      console.log(error);
    });

    // Trigger event for Imports/Exports progress indicator to react to
    $('body').trigger('import::started');

    if (importId) {
      closeModal();
    } else {
      filepond.current.removeFiles();
      window.alert("There was an error creating your import.");
    }

    setSubmitting(false);
  }

  const selectImportType = (selectedOption) => {
    setSelectedImport(selectedOption);

    const importObj = getImportObj(selectedOption.value);

    // TODO Refactor this to support non-bollean field types
    const defaultSelectedOptions = importObj.option_fields
      .filter(c => c.default_value === true)
      .map(c => c.slug);

    setSelectedOptions(defaultSelectedOptions);
  };

  const selectedImportObj = selectedImport ?
    getImportObj(selectedImport.value):
    null;

  if (!ready) return null;

  const component = (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        closeModal();
      }}
      title="Imports"
      blockUI={submitting}
      buttons={[
        {
          style: 'white',
          text: 'Cancel',
          onClick: () => {
            closeModal();
          }
        },
        {
          style: 'black',
          text: 'Import',
          onClick: () => {
            startImport(selectedImport, importName, selectedOptions);
          },
          disabled: !uploadedFile,
        }
      ]}
    >
      <div className="Imports">
        <div className="available-imports">
          <h3 className="raleway-semi-bold raleway-12">
            Choose an Import Type
          </h3>
          <Select
            isMulti={false}
            options={availableImports.map(e => ({ label: e.name, value: e.type }))}
            onChange={selectedOption => {
              selectImportType(selectedOption);
            }}
            name="type"
            value={selectedImport}
            className='raleway-semi-bold raleway-12 react-select-container'
          />
        </div>

        {!!selectedImportObj && (
          <div className="import-name styled-form">
            <h3 className="raleway-semi-bold raleway-12">
              Name <span className="subtext">(optional)</span>
            </h3>
            <input
              className="montserrat-medium montserrat-12"
              type="text"
              value={importName}
              placeholder={`ie. ${makeExampleImportName(selectedImportObj.type)}`}
              onChange={e => setImportName(e.target.value)}
            />
          </div>
        )}

        {!!selectedImportObj && (
          <div className="import-options styled-form">
            <div className="inner">
              <div className="upload">
                {/* HACK: The laravel-filepond lib we're using has an incorrect,
                          non-configurable file input name in controller:
                          vendor/sopamo/laravel-filepond/src/Http/Controllers/FilepondController.php

                          It expects an array input named "file", filepond default
                          is "filepond" non-array input
                */}
                <FilePond
                  acceptedFileTypes={['text/csv', 'text/plain', 'application/csv'/*, 'application/vnd.ms-excel'*/]}
                  fileValidateTypeDetectType={(source, type) => new Promise((resolve, reject) => {
                    console.group('fileValidateTypeDetectType')
                    console.log('source', source)
                    console.log('type', type)
                    console.log("source.name.endsWith('.csv')", source.name.endsWith('.csv'))
                    console.groupEnd()

                    if (type !== 'text/csv' && source.name.endsWith('.csv')) {
                      resolve('text/csv');
                    }

                    resolve(type);
                  })}
                  server={/*jsonData.upload_url*/{
                    process: async (fieldName, file, metadata, load, error, progress, abort) => {
                      const importFileUuid = metadata.import_file_uuid;
                      const filename = metadata.filename;
                      let contentType = file.type;

                      if (!contentType && filename.endsWith('.csv')) {
                        contentType = 'text/csv';
                      }

                      try {
                        console.group('presignedUrl data')
                        console.log('data', {
                          filename,
                          content_type: contentType,
                          import_file_uuid: importFileUuid,
                        })
                        console.groupEnd()

                        const presignedUrl = await $.ajax({
                            url: jsonData.presign_url,
                            type: 'POST',
                            data: {
                              filename: filename,
                              content_type: contentType,
                              import_file_uuid: importFileUuid,
                            }
                        });

                        const uploadPromise = $.ajax({
                          url: presignedUrl.data.url,
                          type: 'PUT',
                          data: file,
                          processData: false,
                          contentType: false,
                          headers: {'Content-Type': 'multipart/form-data'},
                          xhr: () => {
                              // get the native XmlHttpRequest object
                              var xhr = $.ajaxSettings.xhr() ;
                              // set the onprogress event handler
                              xhr.upload.onprogress = (e) => {
                                  progress(e.lengthComputable, e.loaded, e.total);
                              };
                              // set the onload event handler
                              xhr.upload.onload = (e) => {
                                load(file);
                              }

                              return xhr;
                            }
                        });

                        return {
                            abort: () => {
                                // This function is entered if the user has tapped the cancel button
                                uploadPromise.abort();

                                // Let FilePond know the request has been cancelled
                                abort();
                            }
                        };
                      } catch(e) {
                        console.error('Error uploading import file', e, { ...e });
                        // TODO Report to Sentry
                      }
                    },
                    // withCredentials: false,
                  }}
                  name="file[]"
                  onaddfile={(error, file) => {
                    const importFileUuid = uuid();
                    const filename = `${importFileUuid}.${file.fileExtension}`;

                    file.setMetadata('import_file_uuid', importFileUuid);
                    file.setMetadata('filename', filename);
                  }}
                  onprocessfile={(error, file) => {
                    if (!error) {
                      // Old Filepond method
                      // setUploadedFile(file.serverId);

                      // Direct-to-S3 method
                      setUploadedFile(file.getMetadata().import_file_uuid);
                    }
                  }}
                  onremovefile={(error, file) => {
                    setUploadedFile(null);
                  }}
                  ref={ref => filepond.current = ref}
                />
              </div>
              {selectedImportObj.option_fields.length > 0 && (
                <div className="options">
                  <h3 className="raleway-semi-bold raleway-12">
                    Options
                  </h3>
                  <Checklist
                    items={selectedImportObj.option_fields.map(o => ({
                      id: o.slug,
                      label: o.name
                    }))}
                    selectedItems={selectedOptions}
                    onSelect={selected => setSelectedOptions(selected)}
                  />
                </div>
              )}
            </div>
            <div className="separator" />
            <div className="example-download">
              <form method="POST" action={jsonData.example_download_url}>
                <input
                  type="hidden"
                  name="type"
                  value={getImportObj(selectedImport.value).type}
                />
                <input
                  type="hidden"
                  name="import_options"
                  value={JSON.stringify(makeImportOptions(importName, selectedOptions, selectedImportObj))}
                />

                <button
                  type="submit"
                  title="Download Example File"
                  className="raleway-semi-bold raleway-12"
                >
                  <img src={downloadIcon} alt="Download Example File" />
                  Download Example File
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );

  if (!importsMount) {
    return null;
  }

  return ReactDOM.createPortal(
    component,
    importsMount
  );
}

Imports.defaultProps = {
  onCreate: () => {}
};

export default Imports;
