import $ from 'jquery';
import _ from 'lodash';
import React from 'react';

const isFormInput = ($element) => {
  return $element.length && ['INPUT','SELECT','TEXTAREA'].indexOf($element[0].tagName) !== -1;
}

export const isDev = () => process.env.NODE_ENV === 'development';

// TODO Clean up this messy mixture of ways to get JSON data from HTML elements
export const getJSONData = (selectorOrEl, defaultData = {}) => {
  const element = $(selectorOrEl);
  let dataJsonFound = false;
  let inputJsonFound = false;
  let data = {};

  let dataJsonObj = element.data('json');
  if (_.isObject(dataJsonObj)) {
    data = { ...dataJsonObj };
    dataJsonFound = true;
  }

  const formInputJson = isFormInput(element) ? element.val() : null;

  if (formInputJson && _.isString(formInputJson)) {
    try {
      const inputJsonObj = JSON.parse(formInputJson);

      if (_.isObject(inputJsonObj)) {
        data = { ...data, ...inputJsonObj };
        inputJsonFound = true;
      }
    } catch(e) {
      console.log('Invalid JSON:', formInputJson)
    }
  }

  if (!data.context) {
    data.context =  element.data('context');
  }

  if (!dataJsonFound && !inputJsonFound) {
    return defaultData;
  }

  return data;
}

export const setJSONData = (selector, value) => {
  const element = $(selector);

  if (isFormInput(element)) {
    element.val(JSON.stringify(value));
  } else {
    element.data('json', JSON.stringify(value));
  }
}

export const titleCase = (str) => {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export const numberFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

// export const convertRowActionMenuToContextMenu = ($rowActions, contextMenuId, handleClick) => {
//   if($rowActions && $rowActions.length !== 0) {

//     const convertedRowActionButtons = _.map($rowActions.find('.data-table-row-action'), (action) => {
//       const $action = $(action);
//       const actionTitle = $action.text();
//       const actionData = {
//         ajax: $action.data( "ajax" ),
//         record_id: $action.data( "record_id" ),
//         action: $action.data( "action" ),
//         column: $action.data( "column" ),
//         target: $action.data( "target" ),
//         context: $action.data( "context" ),
//         href: $action.data( "href" ),
//       };
//       const actionClasses = $action.attr("class");

//       console.log('actionData: ', actionData);
//       return (
//         <MenuItem className={actionClasses} data={actionData} onClick={handleClick}>
//           {actionTitle}
//         </MenuItem>
//       );
//     });
  
//     let contextMenu = (
//       <ContextMenu id={contextMenuId}>
//         {convertedRowActionButtons}
//       </ContextMenu>
//     )
//     console.log('contextMenu: ', contextMenu);
//     return (contextMenu)
//   } else {
//     return null;
//   }
// }
