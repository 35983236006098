import React, { useRef, useEffect } from 'react';
import Checkbox from '../checkbox/Checkbox.js';

const defaultItem = {
  id: null,
  label: null,
  disabled: false,
  afterLabel: null,
  after: null
};

function Checklist(props) {
  const { items, onSelect, selectedItems = null } = props;

  const listEl = useRef();
  const isShiftDown = useRef(false);
  const lastSelectedItem = useRef(null);

  useEffect(() => {
    const listElNode = listEl.current;

    document.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('keydown', handleKeyDown, false);
    listElNode.addEventListener('selectstart', handleSelectStart, false);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
      listElNode.removeEventListener('selectstart', handleSelectStart);
    };
  }, []);

  const handleSelectStart = (e) => {
    if (isShiftDown) {
      e.preventDefault();
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Shift" && isShiftDown.current) {
      isShiftDown.current = false;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Shift" && !isShiftDown.current) {
      isShiftDown.current = true;
    }
  };

  const handleSelectItem = (e) => {
    const { value } = e.target;
    const nextValue = getNextValue(value);

    lastSelectedItem.current = value;

    onSelect(nextValue);
  };

  const getNextValue = (value) => {
    const hasBeenSelected = !selectedItems.includes(value);

    if (isShiftDown.current) {
      const newSelectedItems = getNewSelectedItems(value);
      // de-dupe the array using a Set
      const selections = [...new Set([...selectedItems, ...newSelectedItems])];

      if (!hasBeenSelected) {
        return selections.filter(item => !newSelectedItems.includes(item));
      }

      return selections;
    }

    // If it's already in there, remove it, otherwise append it
    return selectedItems.includes(value)
      ? selectedItems.filter(item => item !== value)
      : [...selectedItems, value];
  };

  const getNewSelectedItems = (value) => {
    const currentSelectedIndex = items.findIndex(item => item.id === value);
    const lastSelectedIndex = items.findIndex(
      item => item.id === lastSelectedItem.current
    );

    return items
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .map(item => item.id);
  };

  const renderItems = (value) => {
    return items.map((item, i) => {
      item = { ...defaultItem, ...item };
      const { id, label, disabled, afterLabel = null, after = null } = item;

      return (
        <li key={id} className="field checkbox-row">
          <Checkbox
            onChange={handleSelectItem}
            checked={selectedItems.includes(id)}
            value={id}
            id={`item-${id}`}
            key={id}
            disabled={disabled}
          />
          {!!label && (
            <label
              htmlFor={`item-${id}`}
              className="montserrat-bold montserrat-12"
              key={label}
              title={label}
            >
              {label}
            </label>
          )}
          {afterLabel}
          {after}
        </li>
      )
    });
  };

  return (
    <ul ref={listEl}>
      {renderItems()}
    </ul>
  );
}

export default Checklist;
