import React, { useState, useEffect } from 'react';
import Modal from '../modal/Modal';
import _ from 'lodash';

function BulkSetPriceModal({ isOpen, onCancel, onConfirm, ...rest }) {
  const [price, setPrice] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setPrice('');
    }
  }, [isOpen]);

  const _setPrice = (value) => {
    // TODO Consolidate price format validation logic in a lib
    const valid = value.replace(/[^\d.]/g, '');
    const numDecimals = String(value).split('.').length - 1;

    if (!!value && (numDecimals > 1 || !_.isFinite(parseFloat(valid)))) return;

    setPrice(value);
  };

  return (
    <Modal
      title="Bulk Set Price"
      isOpen={isOpen}
      buttons={[
        {
          style: 'white',
          text: 'Cancel',
          onClick: () => {
            onCancel();
          }
        },
        {
          style: 'black',
          text: 'Set',
          onClick: () => {
            onConfirm(price);
          },
        }
      ]}
      onRequestClose={() => {
        onCancel();
      }}
      autoFocus={false}
      {...rest}
    >
      <h3>Price</h3>
      <input
        type="text"
        value={price}
        onChange={e => _setPrice(e.target.value)}
        autoFocus={true}
      />
    </Modal>
  );
}

BulkSetPriceModal.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  onConfirm: () => {},
};

export default BulkSetPriceModal;
