import { titleCase } from '../utils';
import _ from 'lodash';

const defaultAction = {
  onBeforeProcess: (href, data, $row) => true,
  onProcessing: (data, $row) => {},
  onComplete: (resp, data, $row) => {},
  onError: (error, data, $row) => {},
  blockUI: true,
  allowInterrupt: false
};

/**
 * Collab Review Status
 */
const updateReviewStatus = ($row, newStatus, data) => {
  const $statusButtons = $row.find('.data-table-row-action.review-status');
  $statusButtons.removeClass('active');
  $statusButtons.filter('.'+ newStatus.toLowerCase()).addClass('active');
  // $row.find(`.${data.column}`).text(titleCase(newStatus));
}

const createReviewStatusActions = () => {
  const statuses = ['YES', 'NO', 'MAYBE'];
  const actions = {};
  _.each(statuses, status => {
    _.each(['', 'final_'], prefix => {
      actions[`social_profiles.${prefix}review_status_${status.toLowerCase()}`] = {
        ...defaultAction,
        onComplete: (resp, data, $row) => {
          updateReviewStatus($row, status, data);
        },
      };
    });
  });

  return actions;
};

/**
 * Social Profile Approval Status
 */
const updateApprovalStatus = ($row, newStatus, data) => {
  const $statusButtons = $row.find('.data-table-row-action.approval-status');
  $statusButtons.removeClass('active');
  $statusButtons.filter('.'+ newStatus.toLowerCase()).addClass('active');
  $row.find(`.${data.column}`).text(titleCase(newStatus));
}

const createApprovalStatusActions = () => {
  const statuses = ['APPROVED', 'REJECTED'];
  const actions = {};
  _.each(statuses, status => {
    actions[`social_profiles.approval_status_${status.toLowerCase()}`] = {
      ...defaultAction,
      onComplete: (resp, data, $row) => {
        updateApprovalStatus($row, status, data);
      },
    };
  });

  return actions;
};

/**
 * Social Profile Creator Type
 */
// const updateCreatorType = ($row, newType, data) => {
//   const $typeButtons = $row.find('.data-table-row-action.creator-type');
//   $typeButtons.removeClass('active');
//   $typeButtons.filter('.'+ newType.toLowerCase()).addClass('active');
//   $row.find(`.${data.column}`).text(titleCase(newType));
// }

const updateCreatorType = ($row, newType, data) => {
    const socialHandle = $row.find('.social_handle a').text();
    const tiktokUsername = $row.find('.tiktok_username a').text();
    console.log("socialHandle: ", socialHandle);
    console.log("tiktokUsername: ", tiktokUsername);
    
    // Get all type buttons
    const $typeButtons = $row.find('.data-table-row-action.creator-type');
    // Find the selected button and toggle the 'active' status
    const $selectedType = $typeButtons.filter('.'+ newType.toLowerCase());
    if($selectedType.hasClass('active')) {
        $selectedType.removeClass('active');
    } else {
        $selectedType.addClass('active');
        if (newType === "INSTAGRAM_INFLUENCER" && !socialHandle) {
            alert("WARNING: You have just set a user without an Instagram Handle to Instagram Influencer. This can cause issues, it is advised you remove \"Instagram Influencer\" creator type from this user.");
            return;
        } else if (newType === "TIKTOK_INFLUENCER" && !tiktokUsername) {
            alert("WARNING: You have just set a user without a TikTok Username to TikTok Influencer. This can cause issues, it is advised you remove \"TikTok Influencer\" creator type from this user.");
            return;
        }
    }

    $row.find(`.${data.column}`).text(titleCase(newType));
}

const createCreatorTypeActions = () => {
  const types = ['INSTAGRAM_INFLUENCER', 'TIKTOK_INFLUENCER', 'PHOTOGRAPHER'];
  const actions = {};
  _.each(types, type => {
    actions[`social_profiles.creator_type_${type.toLowerCase()}`] = {
        ...defaultAction,
        onComplete: (resp, data, $row) => {
            updateCreatorType($row, type, data);
        },
    };
  });

  return actions;
};

/**
 * Social Profile Content Quality
 */
const updateContentQuality = ($row, newType, data) => {
  const $typeButtons = $row.find('.data-table-row-action.content-quality');
  $typeButtons.removeClass('active');
  $typeButtons.filter('.'+ newType.toLowerCase()).addClass('active');
  $row.find(`.${data.column}`).text(titleCase(newType));
}

const createContentQualityActions = () => {
  const types = ['POLISHED', 'SEMI_POLISHED', 'CANDID'];
  const actions = {};
  _.each(types, type => {
    actions[`social_profiles.content_quality_${type.toLowerCase()}`] = {
      ...defaultAction,
      onComplete: (resp, data, $row) => {
        updateContentQuality($row, type, data);
      },
    };
  });

  return actions;
};

const actions = {
  example_ajax: {
    onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
    onProcessing: (data, $row) => console.log('Processing ', data.action),
    onComplete: (resp, data, $row) => {
      console.log('Complete ', data.action);
    },
    onError: (error, data, $row) => console.log('Error ', data.action),
    blockUI: true,
    allowInterrupt: true
  },

  'social_profiles.pause_user': {
    onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
    onProcessing: (data, $row) => console.log('Processing ', data.action),
    onComplete: (resp, data, $row) => {
      var $btn = $row.find('.data-table-row-action.pause-user');

      if (resp.paused) {
        alert("User successfully paused for this collab!");
        $btn.addClass('paused').data('paused', true).text('Unpause');
      } else {
        alert("User successfully unpaused for this collab!");
        $btn.removeClass('paused').data('paused', false).text('Pause');
      }
    },
    onError: (error, data, $row) => console.log('Error ', data.action),
    blockUI: true,
    allowInterrupt: true
  },

    'social_profiles.post_analytics': {
        onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
        onProcessing: (data, $row) => console.log('Processing ', data.action),
        onComplete: (resp, data, $row) => {
            var $btn = $row.find('.data-table-row-action.post-analytics');
            if (resp.PROCESSING) {
                alert("Post analytics successfully requested for this creator!");
                $btn.addClass('requested').text('Get Analytics');
            } else {
                if(resp.COMPLETED) {
                    alert("Getting Post Analytic Report!");
                    window.location.reload();
                } else {
                    alert("Post analytics failed!");
                    $btn.removeClass('requested').text('Request Analytics');
                }
            }
        },
        onError: (error, data, $row) => console.log('Error ', data.action),
        blockUI: true,
        allowInterrupt: true
    },

    'social_profiles.skip_analytics': {
        onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
        onProcessing: (data, $row) => console.log('Processing ', data.action),
        onComplete: (resp, data, $row) => {
            var $btn = $row.find('.data-table-row-action.get-analytics');
            if (resp.get_analytics) {
                alert("Post analytics will not be skipped for this creator!");
                $btn.addClass('not-skipped').text('Skip Analytics');
            } else {
                alert("Post Analytics will be skipped for this creator.");
                $btn.removeClass('not-skipped').data('revet', false).text('Activate Analytics');
            }
        },
        onError: (error, data, $row) => console.log('Error ', data.action),
        blockUI: true,
        allowInterrupt: true
    },

    'social_profiles.revet_user': {
        onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
        onProcessing: (data, $row) => console.log('Processing ', data.action),
        onComplete: (resp, data, $row) => {
            var $btn = $row.find('.data-table-row-action.revet');
            if (resp.revet) {
                alert("User successfully marked for Re-Vet");
                $btn.addClass('re-vet-yes').data('revet', true).text('Re-Vet');
            } else {
                alert("User successfully removed from Re-Vet");
                $btn.removeClass('re-vet-yes').data('revet', false).text('Re-Vet');
            }
        },
        onError: (error, data, $row) => console.log('Error ', data.action),
        blockUI: true,
        allowInterrupt: true
    },
    
     'social_profiles.move_to_pending_approval': {
         onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
         onProcessing: (data, $row) => console.log('Processing ', data.action),
         onComplete: (resp, data, $row) => {
             var $btn = $row.find('.data-table-row-action.move-user-pending');
             if (resp.approval) {
                 alert("User successfully moved to pending approval");
                 $btn.addClass('re-vet-yes').data('approval', true).text('Remove From Pending Approval');
             } else {
                 alert("User successfully removed from pending approval");
                 $btn.removeClass('re-vet-yes').data('approval', false).text('Move To Pending Approval');
             }
         },
         onError: (error, data, $row) => console.log('Error ', data.action),
         blockUI: true,
         allowInterrupt: true
     },

  ...createReviewStatusActions(),
  ...createApprovalStatusActions(),
  ...createCreatorTypeActions(),
  ...createContentQualityActions(),
};

export const getActionHandlers = (action) => {
  if (actions[action]) {
    return actions[action];
  }

  return defaultAction;
};

export default actions;
