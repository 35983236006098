import uuid from 'uuid/v4';
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export default {
  ajax_url: 'http://app.test/ajax/jobs',
  recent_imports: [
    {
      id: uuid(),
      import_type: 'test',
      name: 'Example Processing Import',
      status: 'PROCESSING',
      percent_complete: 50,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      errors: []
    },
    {
      id: uuid(),
      import_type: 'test',
      name: 'Example Completed Import',
      status: 'COMPLETE',
      percent_complete: 100,
      created_at: '2020-06-26 18:12:39',
      updated_at: moment().subtract(5, 'seconds').format(),//'2020-06-26 18:12:40',
      errors: []
    },
    {
      id: uuid(),
      import_type: 'test',
      name: 'Example Cancelled Import',
      status: 'CANCELLED',
      percent_complete: 100,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      errors: []
    },
    {
      id: uuid(),
      import_type: 'test',
      name: 'Example Failed Import',
      status: 'FAILED',
      percent_complete: 0,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      errors: [
        {
          message: 'Failed Validation',
          type: 'GENERAL'
        }
      ]
    }
  ],
  recent_exports: [
    {
      id: uuid(),
      export_type: 'test',
      name: 'Example Processing Export',
      status: 'PROCESSING',
      percent_complete: 50,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      download_url: '#',
      errors: []
    },
    {
      id: uuid(),
      export_type: 'test',
      name: 'Example Completed Export',
      status: 'COMPLETE',
      percent_complete: 100,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      download_url: '#',
      errors: []
    },
    {
      id: uuid(),
      export_type: 'test',
      name: 'Example Cancelled Export',
      status: 'CANCELLED',
      percent_complete: 80,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      download_url: '#',
      errors: []
    },
    {
      id: uuid(),
      export_type: 'test',
      name: 'Example Failed Export',
      status: 'FAILED',
      percent_complete: 0,
      created_at: '2020-06-26 18:12:39',
      updated_at: '2020-06-26 18:12:40',
      download_url: '#',
      errors: [
        {
          message: 'Something bad happened in file blah blah blah...',
          type: 'EXCEPTION',
          stacktrace: '...sdkljfhjlkhfasdf...'
        }
      ]
    }
  ],
};
