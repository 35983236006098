import React, {useState} from 'react';
import _ from 'lodash';
import { numberFormatter } from '../../utils';
import moment from 'moment-timezone';

// Icons
import externalLinkIconDark from '../../images/icon-external-link-dark.svg';
import xIcon from '../../images/icon-x-light.svg';
import hourglassIcon from '../../images/icon-hourglass-green.svg';
import imageIcon from '../../images/icon-image-light.svg';
import reelIcon from '../../images/icon-reel-light.svg';
import storyIcon from '../../images/icon-story-light.svg';
import videoIcon from '../../images/icon-video-light.svg';
import videoIconTransparent from '../../images/icon-video-transparent.svg';
import prevIcon from '../../images/icon-modal-arrow-left-light.svg';
import nextIcon from '../../images/icon-modal-arrow-right-light.svg';
import prevIconDark from '../../images/icon-modal-arrow-left-dark.svg';
import nextIconDark from '../../images/icon-modal-arrow-right-dark.svg';
import likesIcon from '../../images/icon-likes-green.svg';
import commentsIcon from '../../images/icon-comments-green.svg';
import downloadIcon from '../../images/icon-download.svg';

function InstagramPostCard({
                               postData = null,
                               userData = null,
                               onPrev = () => {},
                               onNext = () => {},
                               onClose = () => {}
                           }) {
    // State
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    
    if (!postData || !userData) {
        return null;
    }

    // Other Data
    const { dataName, dataSocialLink, dataSocialHandle, dataFollowerCount, overallEngagement } = userData;
    const { link, stat, created, text, hashtags, mentions, engagement, imageUrl, carouselUrls } = postData;
    const hoursSincePost = moment.duration(moment().diff(moment(created))).asHours();
    const isCarousel = (!_.isEmpty(carouselUrls));
    
    // Initialize current image index if post is a carousel
    if (isCarousel && currentImageIndex === null) {
        setCurrentImageIndex(0);
    }

    // Methods
    const onCarouselPrev = () => {
        if (isCarousel) {
            setCurrentImageIndex((currentImageIndex === 0) ? (postData?.carousel_media_count - 1) : (currentImageIndex - 1));
        }
    }
    
    const onCarouselNext = () => {
        if (isCarousel) {
            setCurrentImageIndex((currentImageIndex === postData?.carousel_media_count - 1) ? 0 : (currentImageIndex + 1));
        }
    }
    
    const caclulateText = () => {
        let newText = text || '';

        // Filter Hashtags
        _.forEach(hashtags, (hashtag) => {
            let hashtagLink = "<a href='https://www.instagram.com/explore/tags/"+ hashtag +"/' target='_blank' className='ipc-post-hashtags'>#" + hashtag + "</a>";
            let newHashtag = '#' + hashtag;
            let regHashtag = new RegExp("(?<=\\s|^)(" + newHashtag + ")(?=\\s|\\.|\\,|$)", "gm");
            newText = newText.replace(regHashtag, hashtagLink);
        });

        // Filter Mentions
        _.forEach(mentions, (mention) => {
            let mentionLink = "<a href='https://www.instagram.com/"+ mention +"/' target='_blank' className='ipc-post-mentions'>@" + mention + "</a>";
            let newMention = '@' + mention;
            let regMention = new RegExp("(?<=\\s|^)(" + newMention + ")(?=\\s|\\.|\\,|$)", "gm");
            newText = newText.replace(regMention, mentionLink);
        });

        return newText;
    };

    const postTypeImage = (() => {switch(postData.type.toUpperCase()) {
        case "PHOTO":
            return (<img src={imageIcon} className="ipc-post-type-img photo-type" title="Image" />);
        case "VIDEO":
            return (<img src={videoIcon} className="ipc-post-type-img video-type" title="Video" />);
        case "REEL":
            return (<img src={reelIcon} className="ipc-post-type-img reel-type" title="Reel" />);
        case "STORY":
            return (<img src={storyIcon} className="ipc-post-type-img story-type" title="Story" />);
        default:
            return (<img src={imageIcon} className="ipc-post-type-img photo-type" title="Image" />);
    }})();
    
    return (
        <div className="ipc-wrapper">
            <div
                className="ipc-close-modal"
                onClick={(e) => {
                    e.preventDefault();
                    onClose();
                }}
                title="Close Post Modal"
            ></div>
            <button
                className="ipg-post-card-btn prev"
                onClick={(e) => {
                    e.preventDefault();
                    onPrev();
                }}
                title="Pevious Post"
            ><img src={prevIcon} /></button>
            <div className="instagram-post-card">
                <div className="ipc-left">
                    {postTypeImage}
                    <a href={(!!isCarousel) ? (carouselUrls[currentImageIndex]) : imageUrl} download className="ipc-download-btn">
                        <img src={downloadIcon} className="ipc-download-img" title={"Download Image"} />
                    </a>
                    <img src={(!!isCarousel) ? (carouselUrls[currentImageIndex]) : imageUrl} className="ipc-post-img" title="Post Image" />
                    {(!!isCarousel) && (currentImageIndex !== null) && (
                        <div className="ipc-carousel-controls">
                            <button
                                className="ipg-post-card-btn prev"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onCarouselPrev();
                                }}
                                title="Pevious Image"
                            ><img src={prevIconDark} /></button>
                            <button
                                className="ipg-post-card-btn next"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onCarouselNext();
                                }}
                                title="Next Image"
                            ><img src={nextIconDark} /></button>
                        </div>
                    )}
                    {postData.type.toUpperCase() === "VIDEO" && (
                        <a className="ipc-video-overlay" href={link} target="_blank">
                            <img src={videoIconTransparent} title="Video" />
                        </a>
                    )}
                </div>

                <div className="ipc-right">
                    <div className="dp-main-header">
                        <div className="dp-main-header-left">
                            <div className="dp-main-header-name">{dataName}</div>
                            <a href={dataSocialLink} target="_blank" className="dp-main-header-handle" title="View User's Instagram">{dataSocialHandle}</a>
                        </div>
                        <div className="dp-main-header-right">
                            <div className="dp-main-header-right-data">
                                <div className="dp-main-header-right-value">{dataFollowerCount}</div>
                                <div className="dp-main-header-right-label">Followers</div>
                            </div>
                            <div className="dp-main-header-right-data">
                                <div className="dp-main-header-right-value" title={String(overallEngagement?.toFixed(5)) + '%'}>{(overallEngagement !== null) ? (String(overallEngagement?.toFixed(1)) + '%') : 'N/A'}</div>
                                <div className="dp-main-header-right-label">OVR. ENG.</div>
                            </div>
                        </div>
                    </div>

                    <div className="ipc-post-stats">
                        {(/*hoursSincePost < 72 ||*/ (!engagement)) && (
                            <div className="ipc-gathering-stats">
                                <img src={hourglassIcon} title="Hourglass" />Gathering Post Statistics…
                            </div>
                        )}
                        {(/*hoursSincePost < 72 && */ (!!engagement)) && (
                            <>
                                <div className="ipc-likes" title="Likes"><img src={likesIcon} />{numberFormatter(stat.likes, 1)}</div>
                                <div className="ipc-comments" title="Comments"><img src={commentsIcon} />{numberFormatter(stat.comments, 1)}</div>
                                <div className="ipc-engagement" title="Engagement">
                                    {/* TODO Post Engagement Label & Percentage Goes Here */}
                                    <div className="ipc-engagement-label">Engagement: </div>
                                    <div className="ipc-engagement-value">{engagement}%</div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="ipc-post-caption">
                        <div className="ipc-post-text" dangerouslySetInnerHTML={{ __html: caclulateText() }} />
                    </div>

                    <div className="ipc-post-bottom-bar">
                        <div>Posted: {moment(created).format("MM/D/YYYY")}</div>
                        <a
                            href={link}
                            target="_blank"
                            className="external-btn"
                        ><img src={externalLinkIconDark} title='Open Post In Instagram' /></a>
                    </div>
                </div>
            </div>
            <button
                className="ipg-post-card-btn next"
                onClick={(e) => {
                    e.preventDefault();
                    onNext();
                }}
                title="Next Post"
            ><img src={nextIcon} /></button>
            <button
                className="ipg-post-card-exit-btn"
                onClick={(e) => {
                    e.preventDefault();
                    onClose();
                }}
                title="Close Post Modal"
            ><img src={xIcon} /></button>
        </div>
    );
}

export default InstagramPostCard;
