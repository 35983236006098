import $ from "jquery";
import {handleAction} from "../row-actions/init";


const saveRejectionReason = async (rejectionReason, socialProfileId, ajaxUrl) => {
    if (!socialProfileId || !rejectionReason) return;

    $.post(ajaxUrl, {
        'rejection_reason': rejectionReason,
        'social_profile_id': socialProfileId,
    })
        .done(function(data) {
        })
        .fail(function(error) {
            alert("There was an error setting rejection reason.");
            throw error;
        });
};

export const handleRowActionClick = async ($object, socialId, onSubmit) => {
    let $a = $object;
    const data = $a.data();

    if (!data.ajax) {
        if (data.href) window.open(data.href);
        return;
    };

    if ($a.hasClass('approval-status') && $a.hasClass('rejected')) {
        const $formWrapper = $('.rejection-reason-row-action-form-wrapper');
        const $form = $('.rejection-reason-row-action-form-wrapper').find('form');
        const $input = $form.find('#rejection_reason');

        $formWrapper.show();

        $form.submit(async (event) => {
            event.preventDefault();
            
            if ($a !== null) {
                var reason = $input.val();
                var ajaxUrl = $input.data('ajax_url');
                await saveRejectionReason(reason, socialId, ajaxUrl);
                $formWrapper.hide();
                await onSubmit($a);
                $a = null;
            }
        });

        // Handle Closing and cancelling 
        $('.rejection-reason-row-action-form-wrapper .exit-btn').click(() => {
            $a = null;
            $formWrapper.hide();
        });
    } else {
        await onSubmit($a);
    }
}
