import _ from 'lodash';
import { makeOption, makeVariant } from './utils';

let _dispatch = null;
export const setDispatcher = (dispatch) => {
  _dispatch = dispatch;
};

export const initialState = {
  ready: false,
  activeTab: 'product-variants',
  options: [],
  variants: [],
  selectedVariants: [],
  optionSelectionActive: false,
  variantSortOption: null, // clientId
  variantSortDirection: 'asc',
  bulkSetInventoryModalOpen: false,
  bulkSetPriceModalOpen: false,
  renameOptionValueModalOpen: false,
  renameOptionValue: null
};

let newState, index;

export const reducer = (state, { type, payload = null }) => {
  switch(type) {
    case 'HYDRATE':
      return {
        ...state,
        ...payload
      };

    case 'SET_OPTION_NAME':
      newState = { ...state };
      newState.options = [ ...newState.options ];
      index = _.findIndex(newState.options, { clientId: payload.clientId });
      newState.options[index] = {
        ...newState.options[index],
        name: payload.name,
      };

      return newState;

    case 'SET_OPTION_VALUES':
      newState = { ...state };
      newState.options = [ ...newState.options ];

      if (payload.removedValueClientId) {
        newState.variants = newState.variants.filter(v => {
          return !v.options[payload.clientId] || v.options[payload.clientId] !== payload.removedValueClientId;
        });
      }

      index = _.findIndex(newState.options, { clientId: payload.clientId });
      newState.options[index] = {
        ...newState.options[index],
        values: payload.values,
      };

      return newState;

    case 'RENAME_OPTION_VALUE':
      newState = { ...state };
      newState.options = [ ...newState.options ];

      const optionIndex = _.findIndex(newState.options, { clientId: payload.clientId });
      const option = newState.options[optionIndex];
      const optionValueIndex = _.findIndex(option.values, { clientId: payload.optionValueClientId });
      newState.options[optionIndex] = { ...newState.options[optionIndex] };
      newState.options[optionIndex].values = [ ...newState.options[optionIndex].values ];
      newState.options[optionIndex].values[optionValueIndex].name = payload.name;

      return newState;

    case 'ADD_OPTION':
      newState = { ...state };
      newState.options = [ ...newState.options ];
      newState.options.push(makeOption());

      return newState;

    case 'REMOVE_OPTION':
      newState = { ...state };
      newState.options = [ ...newState.options ];
      newState.variants = [ ...newState.variants ];
      newState.variants = newState.variants.map(v => {
        delete v.options[payload.clientId];
        return v;
      });
      index = _.findIndex(newState.options, { clientId: payload.clientId });
      newState.options.splice(index, 1);

      return newState;

    case 'SET_VARIANT_OPTION':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      index = _.findIndex(newState.variants, { clientId: payload.clientId });
      newState.variants[index] = { ...newState.variants[index] };
      newState.variants[index].options = { ...newState.variants[index].options };
      newState.variants[index].options[payload.optionClientId] = payload.selectedValueClientId;

      return newState;

    case 'ADD_VARIANT':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      newState.variants.push(makeVariant());

      return newState;

    case 'REMOVE_VARIANT':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      index = _.findIndex(newState.variants, { clientId: payload.clientId });
      newState.variants.splice(index, 1);

      return newState;

    case 'SET_VARIANT_IMAGE':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      index = _.findIndex(newState.variants, { clientId: payload.clientId });
      newState.variants[index] = { ...newState.variants[index] };
      newState.variants[index].image = payload.image;

      return newState;

    case 'SET_VARIANT_INVENTORY':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      index = _.findIndex(newState.variants, { clientId: payload.clientId });
      newState.variants[index] = { ...newState.variants[index] };
      newState.variants[index].inventory = payload.inventory;

      return newState;

    case 'SET_VARIANT_PRICE':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      index = _.findIndex(newState.variants, { clientId: payload.clientId });
      newState.variants[index] = { ...newState.variants[index] };
      newState.variants[index].price = payload.price;

      return newState;

    case 'SET_VARIANT_SKU':
      newState = { ...state };
      newState.variants = [ ...newState.variants ];
      index = _.findIndex(newState.variants, { clientId: payload.clientId });
      newState.variants[index] = { ...newState.variants[index] };
      newState.variants[index].sku = payload.sku;

      return newState;

    case 'SET_SELECTED_VARIANTS':
      return {
        ...state,
        optionSelectionActive: false,
        selectedVariants: payload
      };

    case 'TOGGLE_SELECT_ALL_VARIANTS':
      const allSelected = payload.selected !== undefined ?
        !payload.selected :
        state.selectedVariants.length === state.variants.length;

      return {
        ...state,
        optionSelectionActive: false,
        selectedVariants: allSelected ? [] : state.variants.map(v => v.clientId)
      };

    case 'DELETE_SELECTED_VARIANTS':
      return {
        ...state,
        variants: state.variants.filter(v => state.selectedVariants.indexOf(v.clientId) === -1),
        selectedVariants: []
      };

    case 'BULK_SET_VARIANT_INVENTORY':
      return {
        ...state,
        variants: state.variants.map(v => {
          if (state.selectedVariants.indexOf(v.clientId) !== -1) {
            v.inventory = payload.inventory;
          }

          return v;
        }),
        selectedVariants: []
      };

    case 'BULK_SET_VARIANT_PRICE':
      return {
        ...state,
        variants: state.variants.map(v => {
          if (state.selectedVariants.indexOf(v.clientId) !== -1) {
            v.price = payload.price;
          }

          return v;
        }),
        selectedVariants: []
      };

    case 'SELECT_ALL_WITH_OPTION_VALUES':
      const selectedByOption = _.keys(payload.values).length > 0 ?
        _.filter(
            state.variants.map((v) => {
            const selected = _.filter(payload.values, (optionValueClientId, optionClientId) => {
              return v.options[optionClientId] === optionValueClientId;
            }).length === _.keys(payload.values).length;

            return selected ? v.clientId: false;
          })
        ) :
        [];

      return {
        ...state,
        optionSelectionActive: true,
        selectedVariants: selectedByOption
      };

    case 'SET_VARIANT_SORT':
      const variantSortDirection = state.variantSortOption === payload.optionClientId ?
        state.variantSortDirection === 'asc' ? 'desc' : 'asc' :
        'asc';

      return {
        ...state,
        variantSortOption: payload.optionClientId,
        variantSortDirection,
        variants: !!payload.optionClientId ?
          selectors.sortedVariants(state, payload.optionClientId, variantSortDirection) :
          state.variants
      };

    default:
      throw new Error();
  }
}


//
// Utils / Selectors
//
export const selectors = {
  variantsWithOptionValue: (state, optionClientId, optionValueClientId) => {
    return state.variants.filter(v => {
      return (!!v.options[optionClientId] && v.options[optionClientId] === optionValueClientId);
    });
  },

  sortedVariants: (state, optionClientId, direction) => {
    const valuesMap = _.find(state.options, { clientId: optionClientId }).values
      .reduce((valuesMap, value) => {
        valuesMap[value.clientId] = value.name;
        return valuesMap;
      }, {});

    const sorted = _.sortBy(state.variants, [v => valuesMap[v.options[optionClientId]]]);

    return direction === 'asc' ? sorted : sorted.reverse();
  }
};


//
// Action Creators
//
export const actions = {
  hydrate: (valuesObj) => {
    _dispatch({
      type: 'HYDRATE',
      payload: valuesObj
    });
  },

  changeTab: (activeTab) => {
    _dispatch({
      type: 'HYDRATE',
      payload: {
        activeTab
      }
    });
  },

  setOptionName: (clientId, name) => {
    _dispatch({
      type: 'SET_OPTION_NAME',
      payload: {
        clientId,
        name
      }
    });
  },

  setOptionValues: (clientId, values, removedValueClientId = null) => {
    _dispatch({
      type: 'SET_OPTION_VALUES',
      payload: {
        clientId,
        values,
        removedValueClientId
      }
    });
  },

  renameOptionValue: (clientId, optionValueClientId, name) => {
    _dispatch({
      type: 'RENAME_OPTION_VALUE',
      payload: {
        clientId,
        optionValueClientId,
        name
      }
    });
  },

  addOption: () => {
    _dispatch({
      type: 'ADD_OPTION',
      payload: {}
    });
  },

  removeOption: (clientId) => {
    _dispatch({
      type: 'REMOVE_OPTION',
      payload: {
        clientId
      }
    });
  },

  setVariantOption: (clientId, optionClientId, selectedValueClientId) => {
    _dispatch({
      type: 'SET_VARIANT_OPTION',
      payload: {
        clientId,
        optionClientId,
        selectedValueClientId
      }
    });
  },

  addVariant: () => {
    _dispatch({
      type: 'ADD_VARIANT',
      payload: {}
    });
  },

  removeVariant: (clientId) => {
    _dispatch({
      type: 'REMOVE_VARIANT',
      payload: {
        clientId
      }
    });
  },

  setVariantImage: (clientId, image = null) => {
    _dispatch({
      type: 'SET_VARIANT_IMAGE',
      payload: {
        clientId,
        image
      }
    });
  },

  setVariantInventory: (clientId, inventory) => {
    let casted = '';
    if (inventory.trim() !== '') {
      casted = inventory === '0' ? 0 : parseInt(inventory);

      if (!_.isFinite(casted)) return;
    }

    _dispatch({
      type: 'SET_VARIANT_INVENTORY',
      payload: {
        clientId,
        inventory: casted
      }
    });
  },

  setVariantPrice: (clientId, price) => {
    // TODO Consolidate price format validation logic in a lib
    const valid = price.replace(/[^\d.]/g, '');
    const numDecimals = String(price).split('.').length - 1;

    if (!!price && (numDecimals > 1 || !_.isFinite(parseFloat(valid)))) return;

    _dispatch({
      type: 'SET_VARIANT_PRICE',
      payload: {
        clientId,
        price: valid
      }
    });
  },

  setVariantSku: (clientId, sku) => {
    _dispatch({
      type: 'SET_VARIANT_SKU',
      payload: {
        clientId,
        sku
      }
    });
  },

  setSelectedVariants: (selectedIndexes = []) => {
    _dispatch({
      type: 'SET_SELECTED_VARIANTS',
      payload: selectedIndexes
    });
  },

  toggleSelectAllVariants: (selected) => {
    _dispatch({
      type: 'TOGGLE_SELECT_ALL_VARIANTS',
      payload: {
        selected
      }
    });
  },

  deleteSelectedVariants: () => {
    _dispatch({
      type: 'DELETE_SELECTED_VARIANTS',
      payload: {}
    });
  },

  bulkSetVariantInventory: (inventory) => {
    _dispatch({
      type: 'BULK_SET_VARIANT_INVENTORY',
      payload: {
        inventory
      }
    });
  },

  bulkSetVariantPrice: (price) => {
    _dispatch({
      type: 'BULK_SET_VARIANT_PRICE',
      payload: {
        price
      }
    });
  },

  selectAllWithOptionValues: (values) => {
    _dispatch({
      type: 'SELECT_ALL_WITH_OPTION_VALUES',
      payload: {
        values
      }
    });
  },

  setVariantSort: (optionClientId = null) => {
    _dispatch({
      type: 'SET_VARIANT_SORT',
      payload: {
        optionClientId
      }
    });
  },
};
