import React from 'react';

function UIBlocker({ block, text = null }) {
  if (!block) return null;

  if (text === null) {
      return (
          <div className="ui-blocker" />
      );
  } else {
      return (
          <div className="ui-blocker">
              <div className="ui-blocker-text"><div className="ui-blocker-text-inner">{text}</div></div>
          </div>
      );
  }
}

export default UIBlocker;
