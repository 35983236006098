import uuid from 'uuid/v4';
import _ from 'lodash';

export const makeOption = (optionAtts = {}) => {
  const { server_id: serverId = null, values = [], ...rest } = optionAtts;

  return {
    clientId: optionAtts.client_id || uuid(),
    serverId,
    name: '',
    ...rest,
    values: values.map(ov => makeOptionValue(ov)),
  };
};

export const makeOptionValue = (optionValueAtts = {}) => {
  const { server_id: serverId = null, ...rest } = optionValueAtts;

  return {
    clientId: optionValueAtts.client_id || uuid(),
    serverId,
    name: '',
    ...rest
  };
};

export const makeVariant = (variantAtts = {}, options = null) => {
  const { server_id: serverId = null, options: variantOptions = {}, ...rest } = variantAtts;

  // If options came from the server, and they only have server_ids,
  // transform them into <optionClientId>: <optionValueClientId> key/value pairs
  let optionsTransformed = variantOptions;
  if (_.isArray(variantOptions) && !!options) {
    optionsTransformed = variantOptions.reduce((optionsTransformed, ov) => {
      const optionServerId = ov.option_server_id;
      const optionValueServerId = ov.server_id;
      const option = _.find(options, { serverId: optionServerId });
      const optionClientId = option.clientId;
      const optionValueClientId = _.find(option.values, { serverId: optionValueServerId }).clientId;

      optionsTransformed[optionClientId] = optionValueClientId;

      return optionsTransformed;
    }, {});
  }

  // console.log(variantOptions, optionsTransformed)

  return {
    clientId: uuid(),
    serverId,
    image: null,
    // Track inventory number on page load, don't overwrite existing
    // inventory value unless the admin changed
    initialInventory: rest.inventory !== undefined ? rest.inventory : null,
    inventory: null,
    price: null,
    sku: null,
    ...rest,
    options: optionsTransformed,
  };
};

export const formatMoney = (number, decPlaces = 2, decSep = '.', thouSep = '') => {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign +
  	(j ? i.substr(0, j) + thouSep : "") +
  	i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
  	(decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}
