import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { isDev } from './utils';
import SegmentationFilter from './components/segmentation-filter/SegmentationFilter';
import DetailPane from './components/detail-pane/DetailPane';
import BulkActions from './components/bulk-actions/BulkActions';
import Imports from './components/imports/Imports';
import Exports from './components/exports/Exports';
import JobsIndicator from './components/jobs-indicator/JobsIndicator';
import ImagesUploader from './components/images-uploader/ImagesUploader';
import ContextMenu from './components/context-menu/ContextMenu';
import { handleAction } from './row-actions/init'; // For Row Action Presses
import ProductVariants from './components/product-variants/ProductVariants';
import { handleRowActionClick } from "./Lib/rowActionHelpers";

const defaultDevTab = 'bulk-actions';

const devTabs = [
    { name: 'Segmenation Filter', className: 'segmentation-filter' },
    { name: 'BulkActions',        className: 'bulk-actions'        },
    { name: 'Imports',            className: 'imports'             },
    { name: 'Exports',            className: 'exports'             },
    { name: 'Jobs Indicator',     className: 'jobs-indicator'      },
    { name: 'Images Uploader',    className: 'images-uploader'     },
    { name: 'Product Variants',   className: 'product-variants'    },
];

const renderImagesUploaders = () => {
    $('.images-uploader:empty').each(function() {
        ReactDOM.render(<ImagesUploader mountPoint={this} />, this);
    });
};

const renderUserRowActionContextMenus = () => {
    $('.mount-context-menu').each(function() {
        // Get Parent Row and Social If for keying
        let $row = $(this).closest('tr')
        let socialId = $row.find('.bulk-select input').val();

        // Get Row Action Buttons
        let $rowActions = $row.find('.original-row-actions .row-action-buttons');
        let $currentRowActions = $rowActions.clone(false); // Clone row actions

        // Mount Context Meny to DOM
        ReactDOM.render(<ContextMenu key={socialId} mountPoint={this} alignLeft title="User Actions"><div className="row-action-buttons-wrapper"></div></ContextMenu>, this);

        // Clone the Row Action Buttons into the Context Menu
        let $contextMenuActions = $row.find('.mount-context-menu .row-action-buttons-wrapper');
        $contextMenuActions.html($currentRowActions);
        
        // Handle click event for newly cloned buttons
        $contextMenuActions.on('click', 'a.data-table-row-action', async function(e) {
            e.preventDefault();

            const submitAction = async ($object) => {
                $object.addClass('processing');

                await handleAction($object.attr('href'), $object.data(), $row);

                // Clone Row Action Buttons again to get updated visuals
                $currentRowActions = $rowActions.clone(false);
                $contextMenuActions.html($currentRowActions);

                $object.removeClass('processing');
            };

            handleRowActionClick($(this), socialId, submitAction);
        });
        
    });
};

function App(props) {
    const [activeDevTab, setActiveDevTab] = useState(null);
    const [lastImportId, setLastImportId] = useState(null);
    const [lastExportId, setLastExportId] = useState(null);

    useEffect(() => {
        window.renderImagesUploaders = renderImagesUploaders;
        renderImagesUploaders();

        window.renderUserRowActionContextMenus = renderUserRowActionContextMenus;
        // renderUserRowActionContextMenus();

        setActiveDevTab(defaultDevTab);
    }, []);

    useEffect(() => {
        $(`.dev-ui`).removeClass('active');
        $(`.dev-ui.${activeDevTab}`).addClass('active');
    }, [activeDevTab]);

    return (
        <>
            {isDev() && (
                <ul className="raleway-semi-bold raleway-12 dev-tabs">
                    {devTabs.map(({ name, className }) => (
                        <li
                            className={`${className} ${activeDevTab === className ? 'active':''}`}
                            onClick={() => setActiveDevTab(className)}
                            key={className}
                        >
                            {name}
                        </li>
                    ))}
                </ul>
            )}
            <SegmentationFilter />
            <BulkActions />
            <DetailPane />
            <Imports onCreate={lastImportId => {
                setLastImportId(lastImportId);
                console.log('App lastImportId', lastImportId)
            }} />
            <Exports onCreate={lastExportId => {
                setLastExportId(lastExportId);
                console.log('App lastExportId', lastExportId)
            }} />
            <JobsIndicator lastImportId={lastImportId} lastExportId={lastExportId} />
            <ProductVariants />
        </>
    );
}

export default App;
